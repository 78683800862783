import { ChevronRightIcon } from '@chakra-ui/icons';
import { ListItem, MenuItem, MenuItemProps } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';

import NavLink from '@/components/UI/Links/NavLink';

import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';
import { EnvironmentService } from '@/services/EnvironmentService';

export interface ExpandableCategory extends ProductCategoryProps {
    expanded?: boolean;
}

export const CategoriesListItem = (
    props: {
        categoryItem: ExpandableCategory;
        depth: number;
        isActive: boolean;
        onSubCategorySelect: (data: ExpandableCategory) => void;
    } & MenuItemProps
) => {
    const { categoryItem, depth, isActive, onSubCategorySelect, ...rest } = props;

    const obj = useMemo(
        () => ({
            depth,
            isActive,
            categoryItem,
            onSubCategorySelect
        }),
        [depth, isActive, categoryItem, onSubCategorySelect]
    );

    useEffect(() => {
        if (!obj.isActive) {
            obj.onSubCategorySelect({
                ...obj.categoryItem,
                expanded: true
            });
        }
    }, [obj]);

    return (
        <MenuItem
            as={ListItem}
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            p={0}
            w="100%"
            closeOnSelect={true}
            borderRadius="18px"
            {...rest}
        >
            <NavLink
                href={`${EnvironmentService.getCanonicalURL()}/${categoryItem.slug}`}
                flex="1"
                display="block"
                position="relative"
                px={4}
                py={2}
                wordBreak="break-word"
                _hover={{ textDecoration: 'none' }}
                onMouseOver={() => {
                    if (categoryItem.subs) {
                        onSubCategorySelect({
                            ...categoryItem,
                            expanded: true
                        });
                    }
                }}
            >
                {categoryItem.name}
                {categoryItem.subs && (
                    <ChevronRightIcon
                        pointerEvents="none"
                        position="absolute"
                        right={1}
                        top="50%"
                        transform="translateY(-50%)"
                    />
                )}
            </NavLink>
        </MenuItem>
    );
};
