import {
    Box,
    Button,
    ButtonGroup,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Switch,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { H3, H4 } from '@/components/Heading';
import { CookieIcon } from '@/components/Icons';
import NavLink from '@/components/UI/Links/NavLink';

import { useCookieConsent } from '@/../context/cookieConsentContext';
import RoutePath from '@/constants/route-path';

export type CookieConsent = {
    essential: boolean;
    marketing: boolean;
    analytic: boolean;
};

export const CookieConsentSettingsPanel = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const {
        getCookieConsent,
        isCookieConsentSettingsPanelOpened,
        setCookieConsentPanelOpenState,
        setAllCookieConsent,
        setCustomCookieConsent,
        setEssentialCookieConsent
    } = useCookieConsent();
    const closeButtonSize = '42px';
    const [cookieConsent, setCookieConsent]: [
        CookieConsent,
        Dispatch<SetStateAction<CookieConsent>>
    ] = useState(getCookieConsent());
    const obj = useMemo(
        () => ({
            isOpen,
            onClose,
            onOpen,
            setCookieConsentPanelOpenState,
            setAllCookieConsent,
            setCustomCookieConsent,
            setEssentialCookieConsent
        }),
        [
            isOpen,
            onClose,
            onOpen,
            setCookieConsentPanelOpenState,
            setAllCookieConsent,
            setCustomCookieConsent,
            setEssentialCookieConsent
        ]
    );
    const selectedConsentChangeHandler = useCallback(
        (key: keyof Omit<CookieConsent, 'essential'>, checked: boolean) => {
            setCookieConsent((prevState) => {
                return {
                    ...prevState,
                    [key]: checked
                };
            });
        },
        []
    );

    const setAllCookies = useCallback(() => {
        obj.setAllCookieConsent();
        onClose();
    }, [onClose, obj]);

    const acceptAllCookiesClickHandler = useCallback(() => {
        setAllCookies();
    }, [setAllCookies]);

    const setOnlyEssentialCookies = useCallback(() => {
        obj.setEssentialCookieConsent();
        onClose();
    }, [onClose, obj]);

    const acceptOnlyEssentialCookiesClickHandler = useCallback(() => {
        setOnlyEssentialCookies();
    }, [setOnlyEssentialCookies]);

    const saveCookieClickHandler = useCallback(() => {
        obj.setCustomCookieConsent(cookieConsent);
        onClose();
    }, [cookieConsent, onClose, obj]);

    useEffect(() => {
        setCookieConsent(() => getCookieConsent());
    }, [getCookieConsent]);

    useEffect(() => {
        if (isCookieConsentSettingsPanelOpened) {
            onOpen();
        }
    }, [isCookieConsentSettingsPanelOpened, onOpen]);

    useEffect(() => {
        obj.setCookieConsentPanelOpenState(isOpen);
    }, [isOpen, obj]);

    return (
        <>
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                placement="left"
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent
                    bgColor="grey.100"
                    borderTopRightRadius={20}
                    overflow="hidden"
                    pl="3.5rem"
                    pr="2rem"
                    pt="2rem"
                >
                    <DrawerCloseButton
                        bgColor="blue.main"
                        color="white"
                        w={closeButtonSize}
                        h={closeButtonSize}
                        top={0}
                        right={0}
                        borderRadius={0}
                        borderBottomLeftRadius={20}
                    />
                    <DrawerHeader>
                        <Box pos="relative">
                            <CookieIcon pos="absolute" top={1} left="-2.625rem" fontSize="30px" />
                            <Text fontSize="h3">
                                <FormattedMessage id="cookies.title-text" />
                            </Text>
                        </Box>
                    </DrawerHeader>
                    <DrawerBody>
                        <Box mb={5}>
                            <Text whiteSpace="pre-wrap" mb={4}>
                                <FormattedMessage
                                    id="cookies-panel.cookie-information.description"
                                    values={{
                                        'data-privacy-policy-link': (content) => {
                                            return (
                                                <NavLink
                                                    href={RoutePath.PrivacyPolicy}
                                                    isExternal
                                                    variant="boldedBlue"
                                                >
                                                    {content}
                                                </NavLink>
                                            );
                                        }
                                    }}
                                />
                            </Text>
                            <Button
                                colorScheme="blue"
                                borderRadius="26px"
                                mx={0}
                                onClick={acceptAllCookiesClickHandler}
                            >
                                <FormattedMessage id="cookies.accept-all" />
                            </Button>
                        </Box>
                        <Box>
                            <H3 fontWeight="semibold">
                                <FormattedMessage id="cookies-panel.manage-agreements" />
                            </H3>
                            <Box mb={5}>
                                <Flex justifyContent="space-between" alignItems="baseline">
                                    <H4 fontSize="h5" fontWeight="semibold">
                                        <FormattedMessage id="cookies-panel.essential-cookies.heading" />
                                    </H4>
                                    <Text
                                        color="blue.main"
                                        textAlign="right"
                                        textTransform="lowercase"
                                    >
                                        <FormattedMessage id="cookies-panel.always-active" />
                                    </Text>
                                </Flex>
                                <Text>
                                    <FormattedMessage id="cookies-panel.essential-cookies.description" />
                                </Text>
                            </Box>
                            <Box mb={5}>
                                <Flex justifyContent="space-between">
                                    <H4 fontSize="h5" fontWeight="semibold">
                                        <FormattedMessage id="cookies-panel.analytic-cookies.heading" />
                                    </H4>
                                    <Switch
                                        isChecked={cookieConsent.analytic}
                                        onChange={(event) =>
                                            selectedConsentChangeHandler(
                                                'analytic',
                                                event.target.checked
                                            )
                                        }
                                    />
                                </Flex>
                                <Text>
                                    <FormattedMessage id="cookies-panel.analytic-cookies.description" />
                                </Text>
                            </Box>
                            <Box mb={5}>
                                <Flex justifyContent="space-between">
                                    <H4 fontSize="h5" fontWeight="semibold">
                                        <FormattedMessage id="cookies-panel.marketing-cookies.heading" />
                                    </H4>
                                    <Switch
                                        isChecked={cookieConsent.marketing}
                                        onChange={(event) =>
                                            selectedConsentChangeHandler(
                                                'marketing',
                                                event.target.checked
                                            )
                                        }
                                    />
                                </Flex>
                                <Text>
                                    <FormattedMessage id="cookies-panel.marketing-cookies.description" />
                                </Text>
                            </Box>
                        </Box>
                        <ButtonGroup
                            display="flex"
                            flexDirection="column"
                            rowGap={4}
                            flex="1"
                            spacing={0}
                            mb={5}
                        >
                            <Button
                                colorScheme="blue"
                                borderRadius="26px"
                                mx={0}
                                onClick={saveCookieClickHandler}
                            >
                                <FormattedMessage id="cookies-panel.save-my-agreements" />
                            </Button>
                            <Button
                                colorScheme="blue"
                                borderRadius="26px"
                                mx={0}
                                onClick={acceptOnlyEssentialCookiesClickHandler}
                            >
                                <FormattedMessage id="cookies.accept-essential" />
                            </Button>
                        </ButtonGroup>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};
