import axios, { AxiosResponse } from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { LoginResponse } from '@/models/api/Login';

async function login(
    username: string,
    password: string
): Promise<AxiosResponse<LoginResponse, unknown>> {
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/login_post.htm?j_json=true`;
    const params = new URLSearchParams();

    params.set('j_username', username);
    params.set('j_password', password);

    const data: Promise<AxiosResponse<LoginResponse, unknown>> = axios
        .post<LoginResponse>(url, params)
        .then((data) => {
            return data;
        });

    return data;
}

function logout(opts?: OptionsWithServerSideRequest): Promise<AxiosResponse<unknown>> {
    const url = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}/account/logout`);
    const headers = getAuthHeaders(opts);

    const data = axios.get(url.toString(), {
        headers
    });

    return data;
}

export const AuthService = {
    login,
    logout
};
