const breakpoints = {
    xs: '20rem',
    sm: '35rem',
    md: '48rem',
    lg: '60rem',
    xl: '75rem',
    '2xl': '85rem',
    '3xl': '100rem'
};
export default breakpoints;
