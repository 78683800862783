import { useEffect, useRef, useState } from 'react';

const useIdleTimeout = (inactiveDuration: number) => {
    const [isInactive, setIsInactive] = useState(false);
    const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const resetTimer = () => {
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
            inactivityTimerRef.current = setTimeout(() => {
                setIsInactive(true);
            }, inactiveDuration);
        };

        const handleActivity = () => {
            setIsInactive(false);
            resetTimer();
        };

        // Set up event listeners for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('scroll', handleActivity);

        // Initial setup
        resetTimer();

        // Clean up event listeners on component unmount
        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            window.removeEventListener('scroll', handleActivity);

            // Clear the timeout on unmount to avoid memory leaks
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
        };
    }, [inactiveDuration]);

    return isInactive;
};

export default useIdleTimeout;
