import { Box } from '@chakra-ui/react';
import { MouseEventHandler, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { DesktopProps } from '@/components/Header/HeaderTop';

import { UserIcon } from '../Icons/UserIcon';
import NavLink from '../UI/Links/NavLink';
import RoutePath from '../../constants/route-path';
import { useApp } from '../../../context/appContext';

export const SignIn = (props: { desktop: DesktopProps }) => {
    const { desktop } = props;

    const { loginAllowed, showDisabledLoginPopup, updateLocks } = useApp();

    const handleSignInClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        async (event) => {
            if (!loginAllowed) {
                const locks = await updateLocks();
                if (locks.loginAllowed) {
                    return;
                }
            }
            if (loginAllowed) {
                return;
            }
            event.preventDefault();
            showDisabledLoginPopup();
        },
        [updateLocks, loginAllowed, showDisabledLoginPopup]
    );

    return (
        <NavLink
            href={RoutePath.SignIn}
            display="flex"
            alignItems="center"
            onClick={handleSignInClick}
        >
            <UserIcon
                marginRight={desktop ? 3 : 0}
                fontSize="1.5rem"
                w={desktop ? 6 : 5}
                h={desktop ? 6 : 5}
            />
            {desktop && (
                <Box>
                    <FormattedMessage id="sign-in" /> / <FormattedMessage id="registration" />
                </Box>
            )}
        </NavLink>
    );
};

export default SignIn;
