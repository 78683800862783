import { BoxProps } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'underscore';

import { ProductSearchResultsList } from '@/components/Search/ProductSearchResultsList';

import RoutePath from '@/constants/route-path';
import { Product } from '@/models/api';
import { SearchProps } from '@/models/props/SearchProps';
import { ProductService } from '@/services';

import { Search } from './Search';

export const ProductSearch = (props: PropsWithChildren<SearchProps & BoxProps>) => {
    const { destination } = props;
    const router = useRouter();
    const [results, setResults] = useState<Product[]>([]);
    const { searchedProduct, setSearchProduct, cartQuantity, cartQuantityUnlogged } =
        useShoppingCart();
    const [searchValue, setSearchValue] = useState('');
    const [isInProgress, setIsInProgress] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [exactProduct, setExactProduct] = useState<Product | undefined>();
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [listOpen, setListOpen] = useState(false);

    const obj = useMemo(
        () => ({
            searchedProduct,
            setSearchProduct,
            router,
            cartQuantity
        }),
        [searchedProduct, setSearchProduct, router, cartQuantity]
    );

    const onSelectCallback = useCallback((product: Product) => {
        setSelectedTitle(product.title);
        setExactProduct(product);
    }, []);

    const onFocusCallback = useCallback((focused: boolean) => {
        if (focused) {
            setListOpen(true);
            setSelectedProduct(null);
        }
    }, []);

    const onSearchValueUpdate = useMemo(
        () =>
            throttle((value: string) => {
                setSearchValue(() => value);
            }, 2000),
        []
    );

    const searchProducts = useCallback((value: string) => {
        setIsInProgress(() => true);

        ProductService.getProductsList({
            text: value,
            pageSize: 5
        })
            .then((response) => response.items)
            .then((products) => {
                setResults(() => products);

                const findProductId = products.find((p) => p.code === value);

                if (findProductId) {
                    setExactProduct(findProductId);
                } else {
                    setExactProduct(undefined);
                }
            })
            .finally(() => setIsInProgress(() => false));
    }, []);

    const onSearchSubmit = useCallback(
        async (value: string) => {
            await router.push({
                pathname: RoutePath.Search,
                query: {
                    q: value
                }
            });
        },
        [router]
    );

    useEffect(() => {
        if (!searchValue) {
            setResults(() => []);
            return;
        }

        if (!selectedTitle) searchProducts(searchValue);
    }, [searchValue, selectedTitle, searchProducts]);

    useEffect(() => {
        if (destination === 'checkout') {
            setListOpen(false);
            setExactProduct(undefined);
            setSelectedTitle('');
            setSelectedProduct(null);
            setSearchProduct(null);
        }
    }, [cartQuantity, cartQuantityUnlogged, destination]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (obj.searchedProduct) {
            setSelectedProduct(obj.searchedProduct);
        } else {
            setSelectedProduct(null);
            setSelectedTitle('');
        }
    }, [obj.searchedProduct]);

    useEffect(() => {
        setListOpen(false);
    }, [router.asPath]);

    return (
        <Search
            searchSubmit={onSearchSubmit}
            minLength={4}
            valueUpdate={onSearchValueUpdate}
            selectedTitle={selectedTitle}
            onFocusCallback={onFocusCallback}
            productItem={exactProduct}
            destination={props.destination || 'header'}
            loading={isInProgress}
            {...props}
        >
            {((searchValue && !selectedProduct && listOpen) ||
                (isInProgress && !selectedProduct && listOpen)) && (
                <ProductSearchResultsList
                    products={results}
                    isLoading={isInProgress}
                    destination={props.destination || 'header'}
                    selectProductCallback={onSelectCallback}
                />
            )}
        </Search>
    );
};
