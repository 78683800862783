import { ComponentStyleConfig } from '@chakra-ui/react';

export const Card: ComponentStyleConfig = {
    variants: {
        frame: {
            container: {
                borderWidth: 1,
                borderRadius: 18,
                borderColor: 'blue.100'
            }
        }
    }
};
