import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    stroke?: string;
};

export const EnvelopeIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 18 13" {...props}>
            <path
                d="m2.15,0l13.941,0a2.128,2.128 0 0 1 1.509,0.625a0.461,0.461 0 0 1 0.164,0.193a2.111,2.111 0 0 1 0.474,1.33l0,8.581a2.111,2.111 0 0 1 -0.47,1.325a0.454,0.454 0 0 1 -0.183,0.214a2.131,2.131 0 0 1 -1.5,0.609l-13.935,0a2.124,2.124 0 0 1 -1.229,-0.393a0.455,0.455 0 0 1 -0.184,-0.149a2.14,2.14 0 0 1 -0.735,-1.605l0,-8.58a2.14,2.14 0 0 1 0.739,-1.607a0.459,0.459 0 0 1 0.169,-0.137a2.123,2.123 0 0 1 1.24,-0.406zm14.538,1.069a1.2,1.2 0 0 0 -0.594,-0.156l-13.944,0a1.176,1.176 0 0 0 -0.327,0.046l4.388,5.225a0.454,0.454 0 0 1 0.072,0.084l0.875,1.042a2.632,2.632 0 0 0 4,0.046l0.968,-1.1a0.454,0.454 0 0 1 0.07,-0.08l4.492,-5.107zm-11.39,5.449l-4.207,-5.009a1.226,1.226 0 0 0 -0.178,0.639l0,8.581a1.225,1.225 0 0 0 0.184,0.649l4.201,-4.86zm11.968,4.592a1.237,1.237 0 0 0 0.06,-0.382l0,-8.578a1.232,1.232 0 0 0 -0.055,-0.364l-4.162,4.73l4.157,4.594zm-0.593,0.7l-4.171,-4.61l-0.665,0.756a3.552,3.552 0 0 1 -5.38,-0.062l-0.565,-0.672l-4.058,4.698a1.171,1.171 0 0 0 0.314,0.043l13.943,0a1.2,1.2 0 0 0 0.581,-0.15l0.001,-0.003z"
                fill={(props.color as string) || '#000000'}
            />
        </Icon>
    );
};
