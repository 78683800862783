import { forwardRef, Heading, HeadingProps } from '@chakra-ui/react';

export const H1 = forwardRef<HeadingProps, 'h1'>((props, ref) => {
    return (
        <Heading
            as="h1"
            fontWeight="semibold"
            fontSize={{ base: '32px', lg: '38px' }}
            lineHeight={{ base: 1, lg: 1.33 }}
            ref={ref}
            {...props}
        />
    );
});
