import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/UI/Buttons/Button';

import { useApp } from '../../../context/appContext';

export const LockModal = () => {
    const { isDisabledLoginPopupOpen, hideDisabledLoginPopup, lockType } = useApp();

    return (
        <Modal isOpen={isDisabledLoginPopupOpen} onClose={hideDisabledLoginPopup} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={6}>
                    <Flex direction="column" alignItems="center">
                        <InfoOutlineIcon color="blue.600" fontSize={100} />
                        <Text fontSize={20} my={4} textAlign="center">
                            <FormattedMessage id={`locks.${lockType}`} />
                        </Text>
                        <Button onClick={() => hideDisabledLoginPopup()}>
                            <FormattedMessage id="close" />
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
