import { ComponentStyleConfig } from '@chakra-ui/react';

export const Radio: ComponentStyleConfig = {
    baseStyle: {
        control: {
            borderColor: 'grey.900',
            borderWidth: '.0625rem',
            color: 'grey.900',
            width: '1.375rem',
            height: '1.375rem',
            _checked: {
                bgColor: 'transparent',
                borderColor: 'grey.900',
                _before: {
                    bgColor: 'grey.900',
                    borderColor: 'grey.900'
                },
                _hover: {
                    bgColor: 'transparent',
                    _before: {
                        bgColor: 'brand'
                    }
                }
            },
            _invalid: {
                borderColor: 'red.400'
            }
        }
    }
};
