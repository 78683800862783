import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { DisabledReason } from '@/models/props/ProductCardProps';

type AlertCartDisableProps = {
    disableReason?: DisabledReason;
};

export function AlertCartDisable({ disableReason }: AlertCartDisableProps) {
    return (
        <>
            {disableReason && (
                <Alert status="warning" mb={8}>
                    <AlertIcon />
                    <AlertDescription fontWeight="normal" lineHeight="short">
                        <FormattedMessage id={`disabled-reason.${disableReason}`} />
                    </AlertDescription>
                </Alert>
            )}
        </>
    );
}
