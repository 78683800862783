import axios from 'axios';

import { LocaleProps } from '@/models/props/LocalizationProps';
import { NewsArticleProps, NewsArticlesProps } from '@/models/props/NewsArticleProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';

const NEWSLIST_URL = EnvironmentService.getCmsInternalApiUrl() + '/newslist';

async function getLatestNews(
    locale: LocaleProps,
    pageSize?: number,
    page?: number,
    sort?: 'orderNumber' | 'id' | 'slug' | 'title' | 'createdAt' | 'updatedAt',
    order?: 'asc' | 'desc'
): Promise<NewsArticlesProps> {
    const url = new URL(NEWSLIST_URL);
    const urlSearchParams = url.searchParams;

    urlSearchParams.set('locale', locale);
    urlSearchParams.append('populate', 'image');
    urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());

    if (page) urlSearchParams.set('pagination[page]', page?.toString() || '1');

    if (pageSize) urlSearchParams.set('pagination[pageSize]', pageSize?.toString() || '1');

    if (sort) urlSearchParams.set('sort[0]', sort + encodeURIComponent(':') + (order || 'desc'));

    return axios
        .get<NewsArticlesProps>(decodeURIComponent(url.toString()))
        .then((response) => response.data)
        .catch((error) => {
            throw new Error(error || 'Articles: something went wrong!');
        });
}

async function getNewsBySlug(slug: string, locale: LocaleProps): Promise<NewsArticleProps | null> {
    const url = new URL(NEWSLIST_URL);
    const urlSearchParams = url.searchParams;

    urlSearchParams.set('filters[slug][$eq]', slug);
    urlSearchParams.set('locale', locale);
    urlSearchParams.append('populate', 'image');

    return axios
        .get<NewsArticlesProps>(url.toString())
        .then((response) => response.data)
        .then((data) => data?.data[0])
        .catch((error) => {
            throw new Error(error || 'Articles: something went wrong!');
        });
}

export const NewsService = {
    getLatestNews,
    getNewsBySlug
};
