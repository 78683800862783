import { Box, chakra, FormControl, FormLabel, Select, Text, Textarea } from '@chakra-ui/react';
import { useCurrentUser } from 'context/currentUserContext';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useHookForm } from '@/hooks/useHookForm';
import { useToast } from '@/hooks/useToast';

import { ContactAgreements } from '@/components/Contact/ContactAgreements/ContactAgreements';
import { FormControlError } from '@/components/FormControlError/FormControlError';
import { Button } from '@/components/UI/Buttons/Button';

import { ValidationKey } from '@/constants/validation-key';
import { ContactForm } from '@/models/api/Contact';
import { ContactService } from '@/services';

export const ContactFormWidget = () => {
    const contactFormWidget = useHookForm<ContactForm>();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { errorToast, successToast } = useToast();
    const intl = useIntl();
    const subjectField = useRef<HTMLSelectElement>(null);
    const [userEmail, setUserEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { formState, register, handleSubmit } = contactFormWidget;
    const { currentUser } = useCurrentUser();

    const prepareContactFormData = (data: ContactForm) => {
        const sendData: ContactForm = {
            subject:
                intl.formatMessage({
                    id: options[subjectField.current?.selectedIndex as number]
                }) || '',
            content: data.content,
            email: userEmail || ''
        };

        return sendData;
    };

    const onSubmit: SubmitHandler<ContactForm> = async (data) => {
        if (!executeRecaptcha) return;

        setIsSubmitting(() => true);

        const sendData = prepareContactFormData(data);
        const recaptchaData = await executeRecaptcha('enquiryFormSubmit');
        const reCaptchaRes = await ContactService.verifyRecaptcha(recaptchaData);

        if (reCaptchaRes.success) {
            try {
                await ContactService.sendContactForm(sendData);

                successToast({
                    title: intl.formatMessage({ id: 'contact.message-sent' })
                });

                contactFormWidget.reset();
            } catch (error) {
                errorToast({
                    title: intl.formatMessage({ id: 'contact.message-sent.error' })
                });
            } finally {
                setIsSubmitting(false);
            }
        }
    };
    const messageContentControl = register('content', {
        required: {
            value: true,
            message: ValidationKey.FieldIsRequired
        },
        minLength: {
            value: 2,
            message: ValidationKey.FieldToShort
        }
    });
    const options = useMemo(() => {
        return [
            'contact.subject.option.one',
            'contact.subject.option.two',
            'contact.subject.option.three',
            'contact.subject.option.four',
            'contact.subject.option.five'
        ];
    }, []);

    useEffect(() => {
        if (currentUser?.address.email) setUserEmail(currentUser.address.email);
    }, [currentUser]);

    return (
        <chakra.form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box marginBottom={3}>
                <FormLabel textAlign="left" fontWeight="bold" marginBottom="2">
                    <FormattedMessage id="contact.subject" />
                </FormLabel>
                <Select ref={subjectField} borderColor="grey.400" minH={12}>
                    {options.map((item, index) => (
                        <option key={index} value={`option${index + 1}`}>
                            <FormattedMessage id={item} />
                        </option>
                    ))}
                </Select>
            </Box>
            <Box marginBottom={3}>
                <FormControl isInvalid={!!formState.errors[messageContentControl.name]} isRequired>
                    <FormLabel textAlign="left" fontWeight="bold" marginBottom="2">
                        <FormattedMessage id="contact.message" />
                    </FormLabel>
                    <FormControlError formState={formState} control={messageContentControl}>
                        <Textarea
                            borderRadius="21px"
                            minHeight="100px"
                            {...messageContentControl}
                        />
                    </FormControlError>
                </FormControl>
            </Box>
            <ContactAgreements formInstance={contactFormWidget} accordion={true} />
            <Box textAlign="center">
                <Button
                    type="submit"
                    colorScheme="blue"
                    size="lg"
                    width="100%"
                    rounded="full"
                    isLoading={isSubmitting}
                >
                    <FormattedMessage id="send" />
                </Button>
                <Text
                    fontSize="2xs"
                    marginTop={2}
                    dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'contact.required-fields-info' })
                    }}
                />
            </Box>
        </chakra.form>
    );
};

export default ContactFormWidget;
