import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const FacebookIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 16 16" {...props}>
            <path d="M 12.402,5.886 H 9.62 V 4.309 c 0,-0.813 0.066,-1.326 1.232,-1.326 h 1.472 V 0.477 a 20.546,20.546 0 0 0 -2.158,-0.109 3.448,3.448 0 0 0 -3.7,3.7 V 5.886 H 4.102 v 3.153 h 2.365 v 7.1 H 9.62 v -7.1 h 2.417 z" />
        </Icon>
    );
};
