import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const UserIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 19 24" {...props}>
            <g id="user">
                <path
                    d="M 9.5,0 C 6.470136,0 4,2.470136 4,5.5 4,8.529864 6.470136,11 9.5,11 12.529864,11 15,8.529864 15,5.5 15,2.470136 12.529864,0 9.5,0 Z m 0,1.3007812 c 2.327293,10e-8 4.199219,1.8719262 4.199219,4.1992188 0,2.3272926 -1.871926,4.1992188 -4.199219,4.1992188 C 7.1727074,9.6992188 5.3007813,7.8272926 5.3007812,5.5 5.3007812,3.1727074 7.1727074,1.3007812 9.5,1.3007812 Z"
                    id="user-head"
                />
                <path
                    d="m 7,13 c -3.8582904,0 -7,3.14171 -7,7 v 2 c 0,1.096869 0.90313062,2 2,2 h 15 c 1.096869,0 2,-0.903131 2,-2 v -2 c 0,-3.85829 -3.14171,-7 -7,-7 z m 0,1.300781 h 5 c 3.155719,0 5.699219,2.5435 5.699219,5.699219 v 2 c 0,0.394298 -0.304921,0.699219 -0.699219,0.699219 H 2 C 1.605702,22.699219 1.3007812,22.394298 1.3007812,22 V 20 C 1.3007813,16.844281 3.8442811,14.300781 7,14.300781 Z"
                    id="user-body"
                />
            </g>
        </Icon>
    );
};
