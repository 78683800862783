import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const InfoIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 16 16" {...props}>
            <path
                fill="none"
                d="M 16,8 A 8,8 0 0 1 8,16 8,8 0 0 1 0,8 8,8 0 0 1 8,0 8,8 0 0 1 16,8 Z"
                id="path986"
            />
            <path
                d="M 8,0 C 3.5876487,0 0,3.5876487 0,8 c 0,4.412351 3.5876487,8 8,8 4.412351,0 8,-3.587649 8,-8 C 16,3.5876487 12.412351,0 8,0 Z m 0,1 c 3.871912,0 7,3.1280883 7,7 0,3.871912 -3.128088,7 -7,7 C 4.1280883,15 1,11.871912 1,8 1,4.1280883 4.1280883,1 8,1 Z"
                id="path988"
            />
            <path
                d="M 8.1210938,6.9707031 A 0.89999998,0.89999998 0 0 0 7.2207031,7.8710937 V 11.5 A 0.89999998,0.89999998 0 0 0 8.1210938,12.400391 0.89999998,0.89999998 0 0 0 9.0214844,11.5 V 7.8710937 A 0.89999998,0.89999998 0 0 0 8.1210938,6.9707031 Z"
                id="path990"
            />
            <path
                d="m 8.1210938,3.1230469 a 1,1 0 0 0 -1.0000001,1 1,1 0 0 0 1.0000001,1 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
                id="path992"
            />
        </Icon>
    );
};
