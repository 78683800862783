import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { useUnreadNotificationsUpdater } from '@/components/Notifications/hooks/useUnreadNotificationsUpdater';

import { MoneySummary } from '@/models/api/MoneySummary';
import { CompanyData, ProfileInfo } from '@/models/api/ProfileInfo';
import { MoneyService } from '@/services/MoneyService';
import { representativeService } from '@/services/RepresentativeService';

interface CurrentUserContextInterface {
    currentUser: ProfileInfo | null;
    moneySummary: MoneySummary | null;
    unreadNotificationsCounter: number;
    fetchUser(): void;
    updateMoneySummary(): void;
    updateUnreadNotificationsCounter(): void;
    setUserCompanyAddress(address: CompanyData): void;
    startUnreadNotificationsUpdating(): void;
}

const CurrentUserContext = createContext<CurrentUserContextInterface>(
    {} as CurrentUserContextInterface
);

export function useCurrentUser() {
    return useContext(CurrentUserContext);
}

export function CurrentUserProvider({ children }: PropsWithChildren) {
    const [currentUser, setCurrentUser] = useState<ProfileInfo | null>(null);
    const [moneySummary, setMoneySummary] = useState<MoneySummary | null>(null);
    const {
        unreadNotificationsCounter,
        updateUnreadNotificationsCounter,
        startUnreadNotificationsUpdating
    } = useUnreadNotificationsUpdater();

    const fetchUser = useCallback(async () => {
        const data = await representativeService.getProfileData();
        setCurrentUser(() => data);
    }, []);

    const setUserCompanyAddress = useCallback((address: CompanyData) => {
        setCurrentUser((prevState) => {
            if (!prevState) {
                return null;
            }
            return {
                ...prevState,
                company: address
            };
        });
    }, []);

    const updateMoneySummary = useCallback(async () => {
        const moneySummary = await MoneyService.getUserMoney();
        setMoneySummary(moneySummary);
    }, []);

    return (
        <CurrentUserContext.Provider
            value={{
                currentUser,
                fetchUser,
                moneySummary,
                unreadNotificationsCounter,
                setUserCompanyAddress,
                updateMoneySummary,
                updateUnreadNotificationsCounter,
                startUnreadNotificationsUpdating
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
}
