export class TimeFormatterUtil {
    public static convertToHMS(timeInSeconds: number | undefined): string {
        if (!timeInSeconds || timeInSeconds === 0) {
            return '--:--:--';
        }

        const fill = (value: number) => `0${value}`.slice(-2);
        const hours = fill(Math.trunc(timeInSeconds / 3600) % 24);
        const minutes = fill(Math.trunc(timeInSeconds / 60) % 60);
        const seconds = fill(Math.trunc(timeInSeconds) % 60);
        return `${hours}:${minutes}:${seconds}`;
    }
}
