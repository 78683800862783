import { ComponentStyleConfig } from '@chakra-ui/react';

const variantOutline = {
    field: {
        borderRadius: 21,
        borderColor: 'grey.400'
    },
    addon: {
        borderRadius: 21
    },
    errorBorderColor: 'red.400'
};

const variantUnderline = {
    addon: {
        baseStyle: {
            borderRadius: 21
        }
    },
    field: {
        borderRadius: 0,
        borderBottom: '1px solid black',
        height: 9,
        pb: 2,
        _dark: {
            color: 'whiteAlpha'
        },
        _focusVisible: {
            borderColor: 'blue.main',
            _dark: {
                bg: 'whiteAlpha.100'
            }
        }
    }
};

export const Input: ComponentStyleConfig = {
    defaultProps: {
        errorBorderColor: 'red.400',
        borderRadius: 21
    },
    variants: {
        outline: variantOutline,
        underline: variantUnderline
    }
};
