import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const TrashIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 14.161 14.907" {...props}>
            <path d="M7.081 0A2.617 2.617 0 0 0 4.5 2.236H1.952a.5.5 0 0 0-.179 0H.559a.559.559 0 1 0 0 1.118h.8l.938 9.7a2.057 2.057 0 0 0 2.04 1.852h5.492a2.057 2.057 0 0 0 2.04-1.852l.939-9.7h.8a.559.559 0 1 0 0-1.118h-1.219a.559.559 0 0 0-.179 0H9.66A2.617 2.617 0 0 0 7.081 0zm0 1.118A1.477 1.477 0 0 1 8.52 2.236H5.642a1.477 1.477 0 0 1 1.439-1.118zm-4.6 2.236h9.2l-.929 9.592a.925.925 0 0 1-.927.842H4.334a.926.926 0 0 1-.927-.842zM5.77 5.209a.559.559 0 0 0-.55.567v5.59a.559.559 0 1 0 1.118 0v-5.59a.559.559 0 0 0-.568-.567zm2.609 0a.559.559 0 0 0-.55.567v5.59a.559.559 0 1 0 1.118 0v-5.59a.559.559 0 0 0-.568-.567z" />
        </Icon>
    );
};
