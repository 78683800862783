import { ValidationKey } from '@/constants/validation-key';
import validateIbanChecksum from '@/validators/checksum';
import {
    CountryInstanceValidators,
    ValidationReturn
} from '@/validators/countryInstanceValidators/CountryInstanceValidators';

export class GermanyInstanceValidators implements CountryInstanceValidators {
    validateBankAccountNumber(accountNumber: string): ValidationReturn {
        let isValid = false;
        const pattern = new RegExp(/^([0-9]{2})\s?([0-9]{4}\s?){4}([0-9]{2})$/, 'g');
        isValid = pattern.test(accountNumber);

        if (!isValid) {
            return ValidationKey.BankAccountNumber;
        }

        isValid = validateIbanChecksum(`DE${accountNumber}`);

        return !isValid ? ValidationKey.BankAccountNumber : undefined;
    }
    validatePhoneNumber(phoneNumber: string): ValidationReturn {
        const pattern = new RegExp(/^\d{9,11}$/, 'g');
        const isValid = pattern.test(phoneNumber);
        return !isValid ? ValidationKey.InvalidPhone : undefined;
    }
    validatePhoneNumberWithPrefix(phoneNumber: string): ValidationReturn {
        const pattern = new RegExp(/^(\+49) \d{3} \d{3} \d{3}$/, 'g');
        const isValid = pattern.test(phoneNumber);
        return !isValid ? ValidationKey.InvalidPhone : undefined;
    }
    validateTaxIdentificationNumber(taxNumber: string): ValidationReturn {
        const taxNumberLength: number = taxNumber.length;
        if (taxNumberLength < 10 || taxNumberLength > 13) {
            return ValidationKey.InvalidTaxNumber;
        }
        let pattern = null;
        switch (taxNumberLength) {
            case 10:
                pattern = new RegExp(/^[0-9]{10}$/, 'g');
                break;
            case 11:
                pattern = new RegExp(/^[0-9]{5}[0-9/]{1}[0-9]{5}$/, 'g');
                break;
            case 12:
                pattern = new RegExp(/^[0-9]{2}\/[0-9]{3}\/[0-9]{5}$/, 'g');
                break;
            case 13:
                pattern = new RegExp(
                    /^([0-9]{3}\/[0-9]{3}\/[0-9]{5})|([0-9]{3}\/[0-9]{4}\/[0-9]{4})$/,
                    'g'
                );
                break;
        }
        let isValid = true;
        if (pattern) {
            isValid = pattern.test(taxNumber);
        }
        return !isValid ? ValidationKey.InvalidTaxNumber : undefined;
    }
    validateInternationalTaxIdentificationNumber(taxNumber?: string): ValidationReturn {
        if (!taxNumber) {
            return undefined;
        }
        const pattern = new RegExp(/^([dD][eE])\d{9}$/, 'g');
        const isValid = pattern.test(taxNumber);
        return !isValid ? ValidationKey.InvalidInternationalTaxNumber : undefined;
    }

    validateZipCode(zipCode: string): ValidationReturn {
        const pattern = new RegExp(/^\d{5}$/, 'g');
        const isValid = pattern.test(zipCode);
        return !isValid ? ValidationKey.InvalidZipCode : undefined;
    }
}
