import { Alert } from './Alert';
import { Button } from './Button';
import { Card } from './Card';
import { Checkbox } from './Checkbox';
import { Container } from './Container';
import { Form } from './form';
import { Heading } from './Heading';
import { Input } from './Input';
import { Link } from './Link';
import { List } from './List';
import { Modal } from './Modal';
import { Progress } from './Progress';
import { Radio } from './Radio';
import { Select } from './Select';
import { Skeleton } from './Skeleton';
import { Table } from './Table';
import { Tabs } from './Tabs';
import { Text } from './Text';

const components = {
    Alert,
    Button,
    Card,
    Container,
    Form,
    Heading,
    Input,
    Link,
    List,
    Modal,
    Progress,
    Radio,
    Select,
    Tabs,
    Table,
    Text,
    Skeleton,
    Checkbox
};

export default components;
