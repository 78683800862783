import { Button, ButtonProps, Text, useMediaQuery } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import BasketTime from '@/components/BasketTime';
import { BasketIcon } from '@/components/Icons';
import CartQuantity from '@/components/ShoppingCart/CartQuantity';

import breakpoints from '@/../themes/betterstyle-theme/breakpoints';

function ButtonCart(props: ButtonProps) {
    const { ...rest } = props;
    const { cartQuantity, basketLocked } = useShoppingCart();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <Button
            display="flex"
            alignItems="center"
            columnGap={3}
            variant="ghost"
            borderColor="grey.400"
            borderWidth={1}
            borderRadius={26}
            lineHeight={1}
            maxW="fit-content"
            px={desktop ? 4 : 2}
            h={desktop ? 12 : 10}
            opacity={basketLocked === false ? 1 : 0.3}
            cursor={basketLocked === false ? 'default' : 'not-allowed'}
            {...rest}
        >
            <CartQuantity quantity={cartQuantity} />
            {desktop ? (
                <Text>
                    <FormattedMessage id="basket" />
                </Text>
            ) : (
                cartQuantity === 0 && <BasketIcon height={5} width={5} pointerEvents="none" />
            )}
            {cartQuantity > 0 && (
                <Text fontSize=".875rem" color="grey" pointerEvents="none">
                    <BasketTime />
                </Text>
            )}
        </Button>
    );
}

export default ButtonCart;
