import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const AlertIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 16 16" {...props}>
            <path
                d="m8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zm0-15c-3.9 0-7 3.1-7 7s3.1 7 7 7 7-3.1 7-7c0-3.8-3.1-7-7-7 0.1 0 0 0 0 0zm0.1 6c0.5 0 0.9 0.4 0.9 0.9v3.6c0 0.5-0.4 0.9-0.9 0.9s-0.9-0.4-0.9-0.9v-3.6c0-0.5 0.4-0.9 0.9-0.9zm1-2.9c0 0.6-0.4 1-1 1s-1-0.4-1-1 0.4-1 1-1 1 0.4 1 1z"
                id="alert-icon"
            />
        </Icon>
    );
};
