import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react';

const style = defineStyle((props) => {
    const { status, variant } = props;
    let bgColor!: string;
    let titleColor!: string;
    switch (status) {
        case 'info':
            bgColor = 'blue.main';
            titleColor = 'white';
            break;
        default:
            break;
    }
    switch (variant) {
        case 'subtle':
            bgColor = 'gray.100';
            titleColor = 'blue.main';
            break;
        default:
            break;
    }
    return {
        container: {
            bgColor,
            border: 'none',
            borderRadius: 8
        },
        title: {
            color: titleColor,
            fontWeight: '700'
        }
    };
});

export const Alert: ComponentStyleConfig = {
    baseStyle: style
};
