import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    stroke?: string;
};

export const UpIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 8.537 4.393" {...props}>
            <path
                d="M.704 3.688L4.36.657l3.471 3.031"
                stroke={props.stroke || '#181E20'}
                strokeLinecap="round"
                strokeWidth="1"
                fill="none"
            />
        </Icon>
    );
};
