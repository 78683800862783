import { extendTheme, Theme } from '@chakra-ui/react';
import radii from 'themes/betterstyle-theme/border-radius';

import breakpoints from './breakpoints';
import colors from './colors';
import components from './components';
import fontSizes from './font-sizes';
import sizes from './sizes';

const fontFamily = `'myriad-pro', -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`;

const defaultTheme: Partial<Theme> = extendTheme({
    styles: {
        global: {
            main: {
                flexGrow: 1
            },
            h1: {
                fontSize: fontSizes.h1,
                fontWeight: 600,
                marginBottom: 4
            },
            h2: {
                fontSize: fontSizes.h2,
                fontWeight: 600,
                marginBottom: 4
            },
            h3: {
                fontSize: fontSizes.h3,
                marginBottom: 4
            },
            h4: {
                fontSize: fontSizes.h4,
                fontWeight: 400,
                marginBottom: 4
            },
            h5: {
                fontSize: fontSizes.h5,
                fontWeight: 400,
                marginBottom: 4
            },
            h6: {
                fontSize: fontSizes.h6,
                fontWeight: 400,
                marginBottom: 4
            }
        }
    },
    breakpoints,
    colors,
    fonts: {
        body: fontFamily,
        heading: fontFamily
    },
    fontSizes,
    sizes,
    components,
    radii
});

export default defaultTheme;
