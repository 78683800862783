import { Product } from '@/models/api';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { AdditionalInformationProps } from '@/models/props/SingleProductProps';

export class ProductUtil {
    static getProductUrl(product: Pick<Product, 'code' | 'slug' | 'categories'>): string {
        const { categories, code, slug } = product;

        if (!categories?.length) {
            throw new Error('Product has no category');
        }

        return `/${categories[0].slug}/${code}_${slug}`;
    }

    static extractTitle(html: string): string {
        if (!html) {
            return '';
        }
        const node = document.createElement('div');
        node.innerHTML = html;
        const firstChild = node.firstElementChild as HTMLElement;
        if (!firstChild) {
            return html;
        }
        return firstChild.innerText;
    }

    static isShippingProduct(code: ProductIdProps): boolean {
        return code.toString().startsWith('PW');
    }

    static parseProductAdditionalInformationHTML(html = ''): AdditionalInformationProps[] {
        if (!html) {
            return [];
        }
        const domNode = document.createElement('div');
        domNode.innerHTML = html;
        const nodes = Array.from(domNode.children);

        const groupedNodes: Element[][] = [];
        let tempList: Element[] = [];
        nodes.forEach((node) => {
            if (node.classList.contains('page-break')) {
                groupedNodes.push([...tempList]);
                tempList = [];
                return;
            }
            tempList.push(node);
        });

        return groupedNodes.map((group) => {
            const [titleNode, ...contentNodes] = group;
            const contentNodesWrapper = document.createElement('div');
            contentNodes.forEach((node) => contentNodesWrapper.appendChild(node));
            return {
                title: (titleNode as HTMLHeadElement).innerText.replaceAll('\n', '').trim(),
                content: contentNodesWrapper.innerHTML
            };
        });
    }
}
