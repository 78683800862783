import axios, { RawAxiosRequestHeaders } from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import {
    AddressData,
    AgreementData,
    CompanyData,
    InternationalSponsor,
    PersonalData,
    ProfileInfo
} from '@/models/api/ProfileInfo';
import { AddressBookData } from '@/models/props/ShippingProps';
import { EnvironmentService } from '@/services/EnvironmentService';

export interface RepresentativeService {
    addBookAddress(
        address: AddressBookData,
        opts?: OptionsWithServerSideRequest
    ): Promise<AddressBookData>;
    deleteAddressBookRecord(id: number, opts?: OptionsWithServerSideRequest): Promise<void>;
    getRepresentativeAddressBook(opts?: OptionsWithServerSideRequest): Promise<AddressBookData[]>;
    getProfileData(opts?: OptionsWithServerSideRequest): Promise<ProfileInfo>;
    updateAddress(data: AddressData): Promise<AddressBookData>;
    updateBookAddress(
        address: AddressBookData,
        opts?: OptionsWithServerSideRequest
    ): Promise<AddressBookData>;
    updateAgreements(data: AgreementData): Promise<void>;
    updateCorrespondenceAddress(data: AddressData): Promise<void>;
    updateCompany(data: CompanyData): Promise<void>;
    updatePersonals(data: PersonalData): Promise<void>;
    updateInternationalSponsor(data: Partial<InternationalSponsor>): Promise<InternationalSponsor>;
}

const BASE_ENDPOINT = `${EnvironmentService.getPublicApiURL()}/representative`;

const ADDRESS_BOOK_ENDPOINT = BASE_ENDPOINT.concat('/book-address');

function addBookAddress(
    address: AddressBookData,
    opts?: OptionsWithServerSideRequest
): Promise<AddressBookData> {
    const headers: RawAxiosRequestHeaders = getAuthHeaders(opts);
    const url = new URL(ADDRESS_BOOK_ENDPOINT);
    return axios
        .post<AddressBookData>(url.toString(), address, {
            headers
        })
        .then((response) => response.data);
}

function updateBookAddress(address: AddressBookData, opts?: OptionsWithServerSideRequest) {
    const headers: RawAxiosRequestHeaders = getAuthHeaders(opts);
    const url = new URL(ADDRESS_BOOK_ENDPOINT.concat('/', address.id.toString()));
    return axios
        .put<AddressBookData>(url.toString(), address, {
            headers
        })
        .then((response) => response.data);
}

function deleteAddressBookRecord(id: number, opts?: OptionsWithServerSideRequest): Promise<void> {
    const headers: RawAxiosRequestHeaders = getAuthHeaders(opts);
    const url = new URL(ADDRESS_BOOK_ENDPOINT);
    url.pathname = url.pathname.concat('/', id.toString());
    return axios.delete(url.toString(), {
        headers
    });
}

function getRepresentativeAddressBook(
    opts?: OptionsWithServerSideRequest
): Promise<AddressBookData[]> {
    const headers: RawAxiosRequestHeaders = getAuthHeaders(opts);
    const url = new URL(BASE_ENDPOINT.concat('/book-addresses'));
    return axios.get(url.toString(), { headers }).then((response) => response.data);
}

function getProfileData(opts?: OptionsWithServerSideRequest): Promise<ProfileInfo> {
    const headers: RawAxiosRequestHeaders = getAuthHeaders(opts);
    return axios
        .get<ProfileInfo>(BASE_ENDPOINT, {
            headers
        })
        .then((response) => response.data);
}

function updateAddress(data: AddressData) {
    return axios
        .put<AddressBookData>(`${BASE_ENDPOINT}/address`, data)
        .then((response) => response.data);
}

function updateAgreements(data: AgreementData): Promise<void> {
    return axios.put(`${BASE_ENDPOINT}/agreements`, data);
}

function updateCompany(data: CompanyData): Promise<void> {
    return axios.put(`${BASE_ENDPOINT}/company`, data);
}

function updateCorrespondenceAddress(data: AddressData): Promise<void> {
    return axios.put(`${BASE_ENDPOINT}/correspondence`, data);
}

function updatePersonals(data: PersonalData): Promise<void> {
    return axios.put(`${BASE_ENDPOINT}/personals`, data);
}

function updateInternationalSponsor(data: InternationalSponsor): Promise<InternationalSponsor> {
    return axios.put(`${BASE_ENDPOINT}/international-sponsor`, data);
}

export const representativeService: RepresentativeService = {
    addBookAddress,
    deleteAddressBookRecord,
    getRepresentativeAddressBook,
    getProfileData,
    updateAddress,
    updateBookAddress,
    updateAgreements,
    updateCorrespondenceAddress,
    updateCompany,
    updatePersonals,
    updateInternationalSponsor
};
