import { ComponentStyleConfig } from '@chakra-ui/react';

export const Progress: ComponentStyleConfig = {
    variants: {
        animated: {
            filledTrack: {
                transition: 'all .2s linear'
            }
        }
    }
};
