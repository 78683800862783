import { BoxProps, Flex, Input, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ProductQuantityButton from '@/components/ShoppingCart/ProductQuantityButton';

import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';

export type ProductQuantitySelectorProps = {
    maxQuantity?: number;
    quantity: number;
    minQuantity?: number;
    variant: 'sm' | 'xl';
    code: ProductIdProps;
    stock?: number;
    onDecrease?(value: number): void;
    onIncrease?(value: number): void;
    onInputChange?(value: number | string): void;
    onMaxQuantityExceeded?(quantity: number): void;
    onMinQuantityExceeded?(quantity: number): void;
};

export const PresentQuantitySelector = (props: ProductQuantitySelectorProps & BoxProps) => {
    const {
        maxQuantity,
        quantity,
        variant,
        code,
        stock,
        onIncrease,
        onDecrease,
        onInputChange,
        onMaxQuantityExceeded,
        onMinQuantityExceeded,
        minQuantity,
        ...rest
    } = props;
    const { currentBasket } = useShoppingCart();
    const [currQuantity, setCurrQuantity] = useState<number | string>(quantity);
    const [hideTextQuantity, setHideTextQuantity] = useState<boolean>(false);
    const [detectedKeyDownHandler, setDetectedKeyDownHandler] = useState<boolean>(false);
    const [updateInput, setUpdateInput] = useState<boolean>(false);
    const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
    const hideTextRef: React.RefObject<HTMLParagraphElement> = useRef<HTMLParagraphElement>(null);
    const timeOutRef = useRef<NodeJS.Timeout>();
    const minQ = minQuantity ?? 1;
    const { gifts } = currentBasket || {};
    const currentStock = stock || 0;
    const obj = useMemo(
        () => ({
            maxQuantity,
            quantity,
            variant,
            code,
            onIncrease,
            onDecrease,
            onInputChange,
            onMaxQuantityExceeded,
            onMinQuantityExceeded,
            minQuantity,
            minQ
        }),
        [
            maxQuantity,
            quantity,
            variant,
            code,
            onIncrease,
            onDecrease,
            onInputChange,
            onMaxQuantityExceeded,
            onMinQuantityExceeded,
            minQuantity,
            minQ
        ]
    );
    const detectKeyDownHandler = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const value = (event.target as HTMLInputElement).value;

            if (value === '') return;

            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                setDetectedKeyDownHandler(true);
                setCurrQuantity(+value);

                clearTimeout(timeOutRef.current);

                timeOutRef.current = setTimeout(() => {
                    setUpdateInput(true);
                }, 1500);
            }

            if (event.key === 'Enter' || event.key === 'Tab') {
                onInputChange?.(+value);
            }
        },
        [onInputChange]
    );
    const increaseQuantityHandle = () => {
        if (maxQuantity === undefined) {
            return;
        }

        if (currQuantity !== '' && +currQuantity < maxQuantity) {
            const newValue = +currQuantity + 1;

            onIncrease?.(newValue);
            onInputChange?.(newValue);
            setCurrQuantity(() => newValue);
        }
    };
    const decreaseQuantityHandle = () => {
        if (currQuantity !== '' && +currQuantity > minQ) {
            const newValue = +currQuantity - 1;

            onDecrease?.(newValue);
            onInputChange?.(newValue);
            setCurrQuantity(() => newValue);
        }
    };
    const changeInputQuantityHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>, keydown: boolean) => {
            const newValue = event.target.value;
            const handleEmptyValue = () => {
                setCurrQuantity('');
            };

            if (newValue === '') {
                handleEmptyValue();
                return;
            }

            let inputValue = Number(newValue);

            if (!isNaN(inputValue)) {
                if (minQuantity !== undefined && inputValue < minQuantity) {
                    inputValue = minQuantity;
                } else if (maxQuantity !== undefined && inputValue > maxQuantity) {
                    inputValue = maxQuantity;
                }

                if (!keydown) onInputChange?.(inputValue);

                setCurrQuantity(inputValue);
            } else {
                handleEmptyValue();
            }
        },
        [maxQuantity, minQuantity, onInputChange]
    );
    const updateQuantityHandler = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            let newQuantity = event.target.valueAsNumber;

            if (newQuantity < obj.minQ) {
                newQuantity = obj.minQ;
                obj.onMinQuantityExceeded?.(obj.minQ);
            }

            if (obj.maxQuantity !== undefined && newQuantity > obj.maxQuantity) {
                newQuantity = obj.maxQuantity;
                obj.onMaxQuantityExceeded?.(obj.maxQuantity);
            }

            if (isNaN(newQuantity)) {
                newQuantity = 0;
            }

            setCurrQuantity(() => newQuantity);
            onInputChange?.(newQuantity);

            setDetectedKeyDownHandler(false);
            setHideTextQuantity(false);
        },
        [onInputChange, obj]
    );

    useEffect(() => {
        const gift = gifts?.find((gift) => gift.productCode === obj.code);

        if (!gift) return;

        const { productDetails } = gift;

        if (!productDetails) return;

        const newValue = productDetails.quantity <= 0 ? gift.quantity - 1 : gift.quantity;

        setCurrQuantity(newValue);
        onInputChange?.(newValue);
        // eslint-disable-next-line
    }, [gifts, obj.code]);

    useEffect(() => {
        if (updateInput) {
            onInputChange?.(currQuantity);
            setUpdateInput(false);
        }
    }, [updateInput, onInputChange, currQuantity]);

    return (
        <Flex
            alignItems="center"
            textAlign="center"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="blue.100"
            borderRadius="full"
            overflow="hidden"
            p={variant === 'xl' ? '10px' : '7px'}
            w="100%"
            h={variant === 'xl' ? '52px' : '42px'}
            mr={1}
            columnGap={2}
            {...rest}
        >
            <ProductQuantityButton
                onClick={() => decreaseQuantityHandle()}
                variant="down"
                size={variant}
                disabled={+currQuantity <= minQ}
            />
            <Flex
                alignItems="center"
                position="relative"
                justifyContent="center"
                width="100%"
                height={8}
            >
                <Text
                    pointerEvents="none"
                    opacity={hideTextQuantity ? 0 : 1}
                    visibility={hideTextQuantity ? 'hidden' : 'visible'}
                    position="absolute"
                    left="50%"
                    top="50%"
                    transform="translateX(-50%) translateY(-50%)"
                    width="100%"
                    height="100%"
                    bgColor="white"
                    lineHeight="30px"
                    zIndex={10}
                    ref={hideTextRef}
                >{`${currQuantity}/${maxQuantity}`}</Text>
                <Input
                    onChange={(event) => {
                        changeInputQuantityHandler(event, detectedKeyDownHandler);
                    }}
                    onBlur={updateQuantityHandler}
                    onKeyDown={detectKeyDownHandler}
                    onFocus={() => setHideTextQuantity(true)}
                    value={currQuantity ?? ''}
                    variant="unstyled"
                    height={8}
                    textAlign="center"
                    type="number"
                    min={0}
                    fontWeight="semibold"
                    fontSize="lg"
                    max={maxQuantity}
                    ref={inputRef}
                />
            </Flex>
            <ProductQuantityButton
                onClick={() => increaseQuantityHandle()}
                variant="up"
                size={variant}
                disabled={
                    (maxQuantity !== undefined && +currQuantity >= maxQuantity) || currentStock <= 1
                }
            />
        </Flex>
    );
};
