import { IconProps } from '@chakra-ui/react';

import { UserLevelHighIcon } from '@/components/Icons/UserLevelHighIcon';
import { UserLevelStandardIcon } from '@/components/Icons/UserLevelStandardIcon';

type UserLevelIconProps = {
    level: number;
    order: number;
    iconProps?: IconProps;
    userLevel?: number;
    lastLevelIndicator?: boolean;
};

export const UserLevelIcon = (props: UserLevelIconProps) => {
    const ICON_CHANGE_LEVEL = 6;

    if (props.level === 1) {
        return (
            <UserLevelStandardIcon
                level={props.order}
                iconProps={{
                    width: 20,
                    height: 'auto',
                    mb: 4,
                    ...props.iconProps
                }}
                userLevel={props.userLevel}
                lastLevelIndicator={props.lastLevelIndicator}
            />
        );
    }

    const userLevel =
        props.userLevel !== undefined ? props.userLevel - ICON_CHANGE_LEVEL : props.userLevel;

    return (
        <UserLevelHighIcon
            level={props.order - ICON_CHANGE_LEVEL}
            iconProps={{
                width: 20,
                height: 'auto',
                mb: 4,
                ...props.iconProps
            }}
            userLevel={userLevel}
        />
    );
};
