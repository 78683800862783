import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    List,
    ListItem,
    Text,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import breakpoints from '@/../themes/betterstyle-theme/breakpoints';
import RoutePath from '@/constants/route-path';
import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';

export type ProductRootDrawerProps = {
    categories: ProductCategoryProps[];
    drawerClose: () => void;
    selectCategory: (category: ProductCategoryProps) => void;
    open: boolean;
};

export const ProductRootDrawer = (props: ProductRootDrawerProps) => {
    const { drawerClose, open, selectCategory } = props;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });

    const categoryClickHandler = useCallback(
        (category: ProductCategoryProps) => {
            selectCategory?.(category);
        },
        [selectCategory]
    );

    useEffect(() => {
        if (open) {
            onOpen();
        }
    }, [open, onOpen]);

    useEffect(() => {
        if (!isOpen) {
            drawerClose();
        }
    }, [isOpen, drawerClose]);
    return (
        <Drawer isOpen={isOpen && !isDesktop} onClose={onClose} size="full">
            <DrawerContent>
                <DrawerBody py={6} px={2}>
                    <Box>
                        <Button variant="link" onClick={onClose} color="inherit">
                            <ChevronLeftIcon />
                            <FormattedMessage id="back" />
                        </Button>
                        <Text fontSize="h3" fontWeight="semibold" textAlign="center" mb={4}>
                            <FormattedMessage id="products" />
                        </Text>
                    </Box>
                    <List display="grid">
                        {props.categories?.map((item, index) => (
                            <ListItem
                                key={index}
                                py="6px"
                                borderTopWidth={1}
                                borderTopColor="gray.200"
                            >
                                {item.subs?.length ? (
                                    <Button
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        w="100%"
                                        h="auto"
                                        py={3}
                                        px={4}
                                        lineHeight="base"
                                        fontWeight="normal"
                                        textAlign="left"
                                        variant="ghost"
                                        color="inherit"
                                        onClick={() => categoryClickHandler(item)}
                                    >
                                        {item.name}
                                        <ChevronRightIcon ml="auto" />
                                    </Button>
                                ) : (
                                    <NavLink
                                        bgColor="transparent"
                                        href={`${RoutePath.Products}/${item.slug}`}
                                        display="block"
                                        variant="buttonFade"
                                        _hover={{
                                            bgColor: 'gray.100'
                                        }}
                                    >
                                        {item.name}
                                    </NavLink>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
