import { IconProps } from '@chakra-ui/icons';
import { Box, Icon, useToken } from '@chakra-ui/react';
import { useMemo } from 'react';

type UserProgressLevelIconProps = {
    iconProps?: IconProps;
    level?: number;
    userLevel?: number;
};

export const UserLevelHighIcon = (props: UserProgressLevelIconProps) => {
    const [orange, greyLight, greyDark] = useToken('colors', [
        'orange.main',
        'myProgress.grey.light',
        'myProgress.grey.dark'
    ]);

    const { iconProps } = props;
    const level = props.level || 0;

    const userLevel = (props.userLevel ?? props.level) as number;

    const levelActive = level <= userLevel;

    const dots = useMemo(() => {
        const points = [
            'M8.30499 45.41C9.96185 45.41 11.305 44.0669 11.305 42.41C11.305 40.7532 9.96185 39.41 8.30499 39.41C6.64814 39.41 5.30499 40.7532 5.30499 42.41C5.30499 44.0669 6.64814 45.41 8.30499 45.41Z',
            'M10.891 58.418C12.5478 58.418 13.891 57.0748 13.891 55.418C13.891 53.7611 12.5478 52.418 10.891 52.418C9.23414 52.418 7.89099 53.7611 7.89099 55.418C7.89099 57.0748 9.23414 58.418 10.891 58.418Z',
            'M18.26 69.4449C19.9169 69.4449 21.26 68.1018 21.26 66.4449C21.26 64.7881 19.9169 63.4449 18.26 63.4449C16.6032 63.4449 15.26 64.7881 15.26 66.4449C15.26 68.1018 16.6032 69.4449 18.26 69.4449Z',
            'M29.287 76.812C30.9438 76.812 32.287 75.4689 32.287 73.812C32.287 72.1552 30.9438 70.812 29.287 70.812C27.6301 70.812 26.287 72.1552 26.287 73.812C26.287 75.4689 27.6301 76.812 29.287 76.812Z',
            'M42.294 79.4C43.9509 79.4 45.294 78.0569 45.294 76.4C45.294 74.7432 43.9509 73.4 42.294 73.4C40.6372 73.4 39.294 74.7432 39.294 76.4C39.294 78.0569 40.6372 79.4 42.294 79.4Z',
            'M55.301 76.812C56.9579 76.812 58.301 75.4689 58.301 73.812C58.301 72.1552 56.9579 70.812 55.301 70.812C53.6442 70.812 52.301 72.1552 52.301 73.812C52.301 75.4689 53.6442 76.812 55.301 76.812Z',
            'M66.328 69.444C67.9849 69.444 69.328 68.1008 69.328 66.444C69.328 64.7871 67.9849 63.444 66.328 63.444C64.6711 63.444 63.328 64.7871 63.328 66.444C63.328 68.1008 64.6711 69.444 66.328 69.444Z',
            'M73.696 58.417C75.3528 58.417 76.696 57.0738 76.696 55.417C76.696 53.7601 75.3528 52.417 73.696 52.417C72.0391 52.417 70.696 53.7601 70.696 55.417C70.696 57.0738 72.0391 58.417 73.696 58.417Z',
            'M76.283 45.41C77.9399 45.41 79.283 44.0669 79.283 42.41C79.283 40.7532 77.9399 39.41 76.283 39.41C74.6262 39.41 73.283 40.7532 73.283 42.41C73.283 44.0669 74.6262 45.41 76.283 45.41Z'
        ];
        return points.map((item, index) => (
            <Box
                key={index}
                as="path"
                d={item}
                fill={level > index ? (levelActive ? orange : greyDark) : greyLight}
            />
        ));
    }, [greyDark, greyLight, level, levelActive, orange]);
    return (
        <Icon viewBox="0 0 84.611 99.892" h="auto" {...iconProps}>
            <path
                d="M56.57 48.285L37.7866 99.8919L28.8602 82.2149L10.2902 89.8841L29.0736 38.2771L56.57 48.285Z"
                fill={levelActive ? orange : greyLight}
            />
            <path
                d="M28.0377 48.2852L46.8211 99.8922L55.7485 82.2147L74.3184 89.884L55.535 38.277L28.0377 48.2852Z"
                fill={levelActive ? orange : greyLight}
            />
            <path
                d="M42.305 84.61C65.6694 84.61 84.61 65.6694 84.61 42.305C84.61 18.9406 65.6694 0 42.305 0C18.9406 0 0 18.9406 0 42.305C0 65.6694 18.9406 84.61 42.305 84.61Z"
                fill="#ffffff"
            />
            <path
                d="M42.305 69.085C57.3851 69.085 69.61 56.8601 69.61 41.78C69.61 26.6998 57.3851 14.475 42.305 14.475C27.2249 14.475 15 26.6998 15 41.78C15 56.8601 27.2249 69.085 42.305 69.085Z"
                fill={levelActive ? orange : greyLight}
            />
            <path
                d="M44.624 50.41C44.162 48.61 43.229 42.724 41.818 41.31C40.407 39.896 34.518 38.964 32.718 38.504C34.518 38.043 40.406 37.109 41.818 35.698C43.23 34.287 44.163 28.403 44.624 26.603C45.084 28.403 46.019 34.291 47.43 35.703C48.841 37.115 54.73 38.048 56.53 38.509C54.73 38.969 48.844 39.904 47.43 41.315C46.016 42.726 45.084 48.615 44.624 50.415"
                fill="#ffffff"
            />
            <path
                d="M34.884 58.008C34.59 56.856 33.994 53.102 33.095 52.201C32.196 51.3 28.438 50.701 27.287 50.409C28.438 50.115 32.193 49.519 33.095 48.62C33.997 47.721 34.595 43.962 34.884 42.813C35.178 43.962 35.774 47.713 36.674 48.62C37.574 49.527 41.331 50.12 42.482 50.409C41.331 50.703 37.576 51.299 36.674 52.201C35.772 53.103 35.174 56.856 34.884 58.008Z"
                fill="#ffffff"
            />
            <path
                d="M52.198 50.456C52.031 49.798 51.689 47.648 51.174 47.132C50.659 46.616 48.507 46.274 47.847 46.104C48.507 45.938 50.657 45.595 51.174 45.08C51.691 44.565 52.031 42.413 52.198 41.754C52.366 42.413 52.709 44.565 53.224 45.08C53.739 45.595 55.89 45.938 56.55 46.104C55.89 46.274 53.741 46.615 53.224 47.132C52.707 47.649 52.367 49.799 52.198 50.456Z"
                fill="#ffffff"
            />
            {...dots}
        </Icon>
    );
};
