import { IconButton } from '@chakra-ui/react';

import { DownIcon, UpIcon } from '@/components/Icons';

type ProductQuantityButtonProps = {
    onClick: () => void;
    variant: 'up' | 'down';
    size: 'sm' | 'xl';
    disabled: boolean;
};

function ProductQuantityButton(props: ProductQuantityButtonProps) {
    const icon =
        props.variant === 'up' ? (
            <UpIcon pointerEvents="none" width={3} height={3} />
        ) : (
            <DownIcon pointerEvents="none" width={3} height={3} />
        );

    return (
        <IconButton
            onClick={props.onClick}
            bgColor="blue.100"
            p={0}
            rounded="full"
            h={props.size === 'xl' ? 38 : 7}
            w={props.size === 'xl' ? 38 : 7}
            minW={props.size === 'xl' ? 38 : 7}
            maxH={props.size === 'xl' ? 38 : 7}
            icon={icon}
            isDisabled={props.disabled}
            _hover={{ bg: 'blue.200' }}
            _active={{
                bgColor: 'blue.200'
            }}
            aria-label={props.variant}
        />
    );
}

export default ProductQuantityButton;
