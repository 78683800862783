import { ComponentStyleConfig } from '@chakra-ui/react';
import fontSizes from '../font-sizes';

export const Heading: ComponentStyleConfig = {
    baseStyle: {
        marginBottom: '1.75rem',
        lineHeight: 1.125
    },
    sizes: {
        h1: {
            fontSize: fontSizes.h1,
            fontWeight: 600,
            marginBottom: 7
        },
        h2: {
            fontSize: fontSizes.h2,
            fontWeight: 600,
            marginBottom: 6
        },
        h3: {
            fontSize: fontSizes.h3,
            marginBottom: 6
        },
        h4: {
            fontSize: fontSizes.h4,
            marginBottom: 5
        },
        h5: {
            fontSize: fontSizes.h5,
            marginBottom: 4
        },
        h6: {
            fontSize: fontSizes.h6,
            marginBottom: 3
        }
    }
};
