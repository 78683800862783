import { useCallback, useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { UnreadNotifications } from '@/models/api/Notifications';
import { NotificationsService } from '@/services/NotificationsService';

import { useApp } from '../../../../context/appContext';

type UseUnreadNotificationsUpdateProps = {
    interval?: number;
};

export type UseNotificationsUpdater = {
    isUpdating: boolean;
    startUnreadNotificationsUpdating: () => void;
    stopUnreadNotificationsUpdating: () => void;
    unreadNotificationsCounter: number;
    updateUnreadNotificationsCounter: () => void;
};

export function useUnreadNotificationsUpdater(
    props?: UseUnreadNotificationsUpdateProps
): UseNotificationsUpdater {
    const updatingInterval = props?.interval || 30000;
    const { showPopupMessage } = useApp();
    const [isUpdating, setIsUpdating] = useState(false);
    const [unreadNotificationsCounter, setUnreadNotificationsCounter] = useState(0);
    const [unreadNotificationsObject, setUnreadNotificationsObject] = useState<UnreadNotifications>(
        {} as UnreadNotifications
    );

    const startUnreadNotificationsUpdating = useCallback(() => {
        setIsUpdating(true);
    }, []);

    const stopUnreadNotificationsUpdating = useCallback(() => {
        setIsUpdating(false);
    }, []);

    const updateUnreadNotificationsCounter = useCallback(async () => {
        const unreadNotificationsObject = await NotificationsService.getUnreadNotificationsCount();
        setUnreadNotificationsObject(unreadNotificationsObject);
        const unreadNotificationsCount = unreadNotificationsObject?.unreadNotifications || 0;
        setUnreadNotificationsCounter(unreadNotificationsCount);
    }, []);

    const hidePopupCallback = useCallback(
        async (id: number) => {
            await NotificationsService.markNotificationAsRead(id);
            await updateUnreadNotificationsCounter();
        },
        [updateUnreadNotificationsCounter]
    );

    const showPopupNotification = useCallback(async () => {
        const notification = await NotificationsService.getNotifications({
            popup: unreadNotificationsObject.popup,
            read: false
        });
        const { items } = notification;
        if (!items.length) {
            return;
        }
        const item = items[0];
        showPopupMessage({
            message: item.message,
            title: item.title,
            onClose: () => hidePopupCallback(item.id)
        });
    }, [hidePopupCallback, showPopupMessage, unreadNotificationsObject?.popup]);

    useEffect(() => {
        if (unreadNotificationsObject.popup) {
            showPopupNotification();
        }
    }, [showPopupNotification, unreadNotificationsObject]);

    useInterval(
        async () => {
            await updateUnreadNotificationsCounter();
        },
        isUpdating ? updatingInterval : null
    );

    return {
        unreadNotificationsCounter,
        isUpdating,
        startUnreadNotificationsUpdating,
        stopUnreadNotificationsUpdating,
        updateUnreadNotificationsCounter
    };
}
