import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export type LogoIconProps = IconProps;

export const LogoIcon = (props: LogoIconProps) => {
    return (
        <Icon viewBox="0 0 198.152 57.513" {...props}>
            <path
                d="m 108.877,23.952 c -0.464,-1.812 -1.4,-7.732 -2.823,-9.153 -1.423,-1.421 -7.34,-2.36 -9.154,-2.823 1.814,-0.464 7.735,-1.4 9.154,-2.823 1.419,-1.423 2.362,-7.339 2.823,-9.153 0.462,1.814 1.4,7.734 2.823,9.153 1.423,1.419 7.34,2.359 9.152,2.823 -1.812,0.463 -7.732,1.4 -9.152,2.823 -1.42,1.423 -2.36,7.341 -2.823,9.153"
                fill="currentColor"
                id="path7"
            />
            <path
                d="m 99.079,31.596 c -0.3,-1.159 -0.9,-4.936 -1.8,-5.843 -0.9,-0.907 -4.685,-1.507 -5.843,-1.8 1.158,-0.3 4.936,-0.895 5.843,-1.8 0.907,-0.905 1.5,-4.686 1.8,-5.842 0.3,1.156 0.9,4.935 1.8,5.842 0.9,0.907 4.685,1.5 5.843,1.8 -1.158,0.3 -4.936,0.9 -5.843,1.8 -0.907,0.9 -1.506,4.684 -1.8,5.843"
                fill="currentColor"
                id="path9"
            />
            <path
                d="m 116.495,23.998 c -0.168,-0.662 -0.512,-2.825 -1.03,-3.345 -0.518,-0.52 -2.684,-0.863 -3.347,-1.034 0.664,-0.167 2.827,-0.512 3.347,-1.031 0.52,-0.519 0.862,-2.684 1.03,-3.346 0.169,0.663 0.514,2.828 1.032,3.346 0.518,0.518 2.682,0.863 3.346,1.031 -0.664,0.171 -2.826,0.514 -3.346,1.034 -0.52,0.52 -0.863,2.683 -1.032,3.345"
                fill="currentColor"
                id="path11"
            />
            <path
                d="m 21.534,38.799 a 6.516,6.516 0 0 1 1.329,4.2 6.008,6.008 0 0 1 -2.6,5.228 q -2.6,1.831 -7.577,1.831 H 0 V 23.916 h 11.926 q 4.557,0 7.045,1.793 a 5.783,5.783 0 0 1 2.488,4.967 6.377,6.377 0 0 1 -0.968,3.548 6.279,6.279 0 0 1 -2.6,2.278 6.825,6.825 0 0 1 3.646,2.3 M 3.8,26.941 v 8.366 h 7.786 a 8.011,8.011 0 0 0 4.5,-1.065 3.874,3.874 0 0 0 0,-6.237 8.015,8.015 0 0 0 -4.5,-1.064 z m 13.6,19.047 a 3.62,3.62 0 0 0 1.633,-3.287 q 0,-4.369 -6.495,-4.37 H 3.8 v 8.7 h 8.735 a 9.118,9.118 0 0 0 4.861,-1.045"
                fill="currentColor"
                id="path13"
            />
            <path
                d="M 45.654,41.36 H 29.693 a 6.289,6.289 0 0 0 2.283,4.235 7.376,7.376 0 0 0 4.844,1.6 7.248,7.248 0 0 0 5.679,-2.41 l 1.967,2.334 a 8.618,8.618 0 0 1 -3.322,2.409 12.364,12.364 0 0 1 -9.966,-0.471 9.182,9.182 0 0 1 -3.731,-3.633 10.532,10.532 0 0 1 -1.318,-5.271 10.7,10.7 0 0 1 1.281,-5.234 9.238,9.238 0 0 1 3.526,-3.633 9.932,9.932 0 0 1 5.067,-1.3 9.733,9.733 0 0 1 5.03,1.3 9.07,9.07 0 0 1 3.452,3.633 11.2,11.2 0 0 1 1.244,5.346 10.6,10.6 0 0 1 -0.074,1.092 m -13.938,-6.74 a 6.409,6.409 0 0 0 -2.023,4.142 h 12.62 a 6.452,6.452 0 0 0 -2.023,-4.123 6.573,6.573 0 0 0 -8.574,-0.019"
                fill="currentColor"
                id="path15"
            />
            <path
                d="M 99.003,41.36 H 83.042 a 6.289,6.289 0 0 0 2.283,4.235 7.377,7.377 0 0 0 4.844,1.6 7.248,7.248 0 0 0 5.679,-2.41 l 1.967,2.334 a 8.618,8.618 0 0 1 -3.322,2.409 12.364,12.364 0 0 1 -9.966,-0.471 9.182,9.182 0 0 1 -3.731,-3.633 10.532,10.532 0 0 1 -1.318,-5.271 10.7,10.7 0 0 1 1.281,-5.234 9.238,9.238 0 0 1 3.526,-3.633 9.932,9.932 0 0 1 5.067,-1.3 9.733,9.733 0 0 1 5.03,1.3 9.07,9.07 0 0 1 3.452,3.633 11.2,11.2 0 0 1 1.244,5.346 10.584,10.584 0 0 1 -0.074,1.092 m -13.938,-6.74 a 6.408,6.408 0 0 0 -2.023,4.142 h 12.62 A 6.452,6.452 0 0 0 93.64,34.636 6.573,6.573 0 0 0 85.066,34.617"
                fill="currentColor"
                id="path17"
            />
            <path
                d="m 62.236,48.892 a 5.391,5.391 0 0 1 -1.958,1.035 8.19,8.19 0 0 1 -2.41,0.358 6.4,6.4 0 0 1 -4.669,-1.619 6.18,6.18 0 0 1 -1.657,-4.631 L 51.534,33.751 h -3.63 v -3.266 h 3.63 l 0.008,-4.957 h 3.614 l -0.007,4.957 h 5.82 v 3.266 h -5.82 l 0.007,10.133 a 3.5,3.5 0 0 0 0.81,2.485 3.013,3.013 0 0 0 2.316,0.866 4.355,4.355 0 0 0 2.824,-0.942 z"
                fill="currentColor"
                id="path19"
            />
            <path
                d="m 77.485,48.892 a 5.391,5.391 0 0 1 -1.958,1.035 8.19,8.19 0 0 1 -2.41,0.358 6.4,6.4 0 0 1 -4.669,-1.619 6.18,6.18 0 0 1 -1.657,-4.631 L 66.783,33.751 h -3.638 v -3.266 h 3.638 l 0.008,-4.957 h 3.614 l -0.007,4.957 h 5.812 v 3.266 h -5.812 l 0.007,10.133 a 3.5,3.5 0 0 0 0.81,2.485 3.013,3.013 0 0 0 2.316,0.866 4.355,4.355 0 0 0 2.824,-0.942 z"
                fill="currentColor"
                id="path21"
            />
            <path
                d="m 108.592,30.82 a 10.113,10.113 0 0 1 4.481,-0.9 v 3.5 a 7.073,7.073 0 0 0 -0.828,-0.038 6.08,6.08 0 0 0 -4.612,1.751 6.928,6.928 0 0 0 -1.676,4.989 v 9.94 h -3.614 V 30.485 h 3.463 v 2.97 a 6.169,6.169 0 0 1 2.786,-2.635"
                fill="currentColor"
                id="path23"
            />
            <path
                d="m 119.863,49.249 a 10.626,10.626 0 0 1 -4.085,-2.579 l 0.866,-1.43 a 10.2,10.2 0 0 0 3.746,2.391 13.435,13.435 0 0 0 4.95,0.921 9.446,9.446 0 0 0 5.685,-1.431 4.441,4.441 0 0 0 1.92,-3.728 3.9,3.9 0 0 0 -1.016,-2.824 6.736,6.736 0 0 0 -2.523,-1.619 34.829,34.829 0 0 0 -4.066,-1.166 39.587,39.587 0 0 1 -4.65,-1.393 7.421,7.421 0 0 1 -2.974,-2.089 5.575,5.575 0 0 1 -1.224,-3.784 6.432,6.432 0 0 1 1.017,-3.52 6.9,6.9 0 0 1 3.106,-2.542 12.707,12.707 0 0 1 5.214,-0.941 14.7,14.7 0 0 1 4.292,0.64 12.649,12.649 0 0 1 3.652,1.732 l -0.716,1.544 a 12.2,12.2 0 0 0 -3.482,-1.657 13.121,13.121 0 0 0 -3.746,-0.565 8.875,8.875 0 0 0 -5.534,1.468 4.6,4.6 0 0 0 -1.882,3.8 3.894,3.894 0 0 0 1.016,2.824 6.722,6.722 0 0 0 2.522,1.619 38.132,38.132 0 0 0 4.1,1.2 38.552,38.552 0 0 1 4.631,1.393 7.63,7.63 0 0 1 2.956,2.052 5.374,5.374 0 0 1 1.223,3.708 6.259,6.259 0 0 1 -1.035,3.5 7.039,7.039 0 0 1 -3.181,2.522 13.109,13.109 0 0 1 -5.271,0.942 15.347,15.347 0 0 1 -5.516,-1"
                fill="currentColor"
                id="path25"
            />
            <path
                d="m 150.53,48.816 a 4.73,4.73 0 0 1 -1.788,1.035 7.05,7.05 0 0 1 -2.24,0.358 5.3,5.3 0 0 1 -3.953,-1.412 5.357,5.357 0 0 1 -1.393,-3.934 V 32.027 h -3.613 v -1.622 h 3.614 v -4.292 h 1.882 v 4.292 h 6.25 v 1.622 h -6.25 v 12.648 a 4.148,4.148 0 0 0 0.923,2.918 3.534,3.534 0 0 0 2.729,1 4.366,4.366 0 0 0 3.05,-1.092 z"
                fill="currentColor"
                id="path27"
            />
            <path
                d="m 171.162,30.405 -10.09,22.327 a 8.835,8.835 0 0 1 -2.617,3.69 5.787,5.787 0 0 1 -3.52,1.092 7.069,7.069 0 0 1 -2.541,-0.452 5.4,5.4 0 0 1 -2.014,-1.317 l 0.941,-1.431 a 4.913,4.913 0 0 0 3.652,1.544 3.867,3.867 0 0 0 2.447,-0.791 7.084,7.084 0 0 0 1.882,-2.711 l 1.053,-2.373 -8.848,-19.578 h 2 l 7.869,17.582 7.869,-17.582 z"
                fill="currentColor"
                id="path29"
            />
            <rect
                width="1.882"
                height="27.937"
                fill="currentColor"
                id="rect31"
                x="174.03101"
                y="22.121"
            />
            <path
                d="M 198.114,40.685 H 181.46 a 8.3,8.3 0 0 0 1.15,4.085 7.4,7.4 0 0 0 2.912,2.767 8.648,8.648 0 0 0 4.136,0.979 8.737,8.737 0 0 0 3.505,-0.7 6.842,6.842 0 0 0 2.689,-2.052 l 1.075,1.244 a 8.267,8.267 0 0 1 -3.171,2.372 10.895,10.895 0 0 1 -9.291,-0.452 9.207,9.207 0 0 1 -3.538,-3.543 10.278,10.278 0 0 1 -1.279,-5.158 10.815,10.815 0 0 1 1.205,-5.139 9.078,9.078 0 0 1 16.1,-0.057 10.71,10.71 0 0 1 1.206,5.121 z m -12.871,-7.85 a 6.974,6.974 0 0 0 -2.615,2.6 8.552,8.552 0 0 0 -1.132,3.784 h 14.837 a 7.969,7.969 0 0 0 -1.113,-3.765 7.312,7.312 0 0 0 -2.633,-2.6 7.223,7.223 0 0 0 -3.673,-0.941 7.33,7.33 0 0 0 -3.672,0.922"
                fill="currentColor"
                id="path33"
            />
        </Icon>
    );
};
