import {
    Button,
    Circle,
    DrawerBody,
    DrawerCloseButton,
    Flex,
    List,
    ListItem,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { UserIcon } from '@/components/Icons';
import { UnreadNotificationsCounter } from '@/components/Notifications/UnreadNotificationsCounter';
import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';
import { EnvironmentService } from '@/services/EnvironmentService';

import { useCurrentUser } from '../../../context/currentUserContext';

export interface UserPanelProps {
    onRouteChange?: () => void;
}

export const UserPanel = (props: UserPanelProps) => {
    const { onRouteChange } = props;
    const { currentUser, unreadNotificationsCounter } = useCurrentUser();
    const router = useRouter();
    const { onClose } = useDisclosure();
    const appStaging = useMemo(() => parseInt(EnvironmentService.getAppStaging()), []);

    const fullName = useMemo(() => {
        if (!currentUser) {
            return '';
        }
        const { name, surname } = currentUser.personalData;
        return `${name} ${surname}`;
    }, [currentUser]);

    const obj = useMemo(
        () => ({
            onClose,
            onRouteChange,
            router
        }),
        [onClose, onRouteChange, router]
    );

    useEffect(() => {
        const handleRouteChange = () => {
            obj.onRouteChange?.();
            obj.onClose();
        };

        obj.router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            obj.router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [obj]);

    const signOutClickHandler = useCallback(() => {
        signOut({
            callbackUrl: RoutePath.Home
        }).then();
    }, []);

    return (
        <DrawerBody py={6} px={9}>
            <Flex mb={6} justifyContent="space-between" alignItems="center" position="relative">
                <Text fontSize="2xl" fontWeight="semibold">
                    <FormattedMessage id="account" />
                </Text>
                <DrawerCloseButton
                    top={0}
                    right={0}
                    borderRadius="50%"
                    borderWidth={1}
                    borderColor="blue.100"
                />
            </Flex>
            <Flex alignItems="center" my={4}>
                <Circle p={4} bgColor="grey.100" borderRadius="50%" lineHeight={1}>
                    <UserIcon fontSize={19} />
                </Circle>
                <Text fontWeight="semibold" ml={3}>
                    {fullName}
                </Text>
            </Flex>
            <List>
                {appStaging > 2 && (
                    <ListItem
                        py={4}
                        borderBottomWidth="1px"
                        borderColor="gray.200"
                        display="flex"
                        alignItems="center"
                    >
                        <NavLink href={RoutePath.Notifications}>
                            <FormattedMessage id="notifications" />
                        </NavLink>
                        {!!unreadNotificationsCounter && <UnreadNotificationsCounter ml={2} />}
                    </ListItem>
                )}
                <ListItem py={4} borderBottomWidth="1px" borderColor="gray.200">
                    <NavLink href={RoutePath.Profile}>
                        <FormattedMessage id="profile" />
                    </NavLink>
                </ListItem>
                {appStaging > 1 && (
                    <ListItem py={4} borderBottomWidth="1px" borderColor="gray.200">
                        <NavLink href={RoutePath.OrdersHistory}>
                            <FormattedMessage id="orders-history" />
                        </NavLink>
                    </ListItem>
                )}
                {appStaging > 1 && (
                    <ListItem py={4} borderBottomWidth="1px" borderColor="gray.200">
                        <NavLink href={RoutePath.Complaints}>
                            <FormattedMessage id="page.complaints.title" />
                        </NavLink>
                    </ListItem>
                )}
                <ListItem py={4} borderBottomWidth="1px" borderColor="gray.200">
                    <NavLink href={RoutePath.ChangePassword}>
                        <FormattedMessage id="change-password" />
                    </NavLink>
                </ListItem>
                <ListItem py={4}>
                    <Button
                        color="red.400"
                        variant="link"
                        fontWeight="normal"
                        onClick={signOutClickHandler}
                    >
                        <FormattedMessage id="log-out" />
                    </Button>
                </ListItem>
            </List>
        </DrawerBody>
    );
};
