import RoutePath from '../constants/route-path';

export const unLoggedUserShoppingMenu = [
    {
        href: RoutePath.Catalog,
        translationKey: 'page.catalog.title'
    },
    {
        href: RoutePath.NewProducts,
        translationKey: 'page.new-products.title'
    },
    {
        href: RoutePath.SuperOffers,
        translationKey: 'page.super-offers.title'
    },
    {
        href: RoutePath.Sale,
        translationKey: 'page.sale.title'
    }
];

export const loggedUserShoppingMenu = [
    {
        href: RoutePath.Catalog,
        translationKey: 'page.catalog.title'
    },
    {
        href: RoutePath.NewProducts,
        translationKey: 'page.new-products.title'
    },
    {
        href: RoutePath.SuperOffers,
        translationKey: 'page.super-offers.title'
    },
    {
        href: RoutePath.MonthActions,
        translationKey: 'page.month-actions.title'
    },
    {
        href: RoutePath.UnavailableProducts,
        translationKey: 'unavailable-products'
    },
    {
        href: RoutePath.Sale,
        translationKey: 'page.sale.title'
    }
];

export const topBarUnLoggedUserLinks = [
    {
        href: RoutePath.AboutUs,
        translationKey: 'about-us'
    },
    {
        href: RoutePath.Opportunities,
        translationKey: 'page.opportunities.title'
    }
];

export const topBarLoggedUserLinks = [
    {
        href: RoutePath.MyCareer,
        translationKey: 'page.my-career.title'
    },
    {
        href: RoutePath.KnowledgeBase,
        translationKey: 'page.knowledge-base.title'
    }
];
