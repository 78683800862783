import { FlexProps, GridProps, ImageProps } from '@chakra-ui/react';

import { OptionsWithServerSideRequest, Product } from '@/models/api';
import { AddressData } from '@/models/api/ProfileInfo';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { PaymentMethod } from '@/models/props/PaymentDetailsProps';
import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';

export type Sort = 'ID' | 'POINTS' | 'PRICE' | 'NAME';

export type SortDir = 'ASC' | 'DESC';

export type ProductList = {
    text?: string;
    categoryId?: string[];
    sort?: Sort;
    sortDir?: SortDir;
    pageNo?: number;
    pageSize?: number;
    productCodes?: ProductIdProps[];
    additionalFiles?: boolean;
    locale?: LocaleProps;
    opts?: OptionsWithServerSideRequest;
};

export type ProductFileFormats = {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path?: string | null;
    size: number;
    width: number;
    height: number;
};

export type ProductFiles = {
    id: number;
    attributes: {
        name: string;
        alternativeText?: string | null;
        caption?: string | null;
        width: number;
        height: number;
        formats: {
            small: ProductFileFormats;
            medium: ProductFileFormats;
            thumbnail: ProductFileFormats;
        };
        hash: string;
        ext: string;
        mime: string;
        size: number;
        url: string;
        previewUrl?: string | null;
        provider: string;
        provider_metadata?: string | null;
        createdAt: string;
        updatedAt: string;
    };
};

export interface ProductCategory {
    id: number;
    name: string;
    slug: string;
    level: number;
    subs: ProductCategory[];
}

export interface ProductDescription {
    sectionDescription?: string;
    title?: string;
}

// export type ProductCardProps = Product;

export type ProductCardProps = {
    addToCart?: boolean;
    categories?: ProductCategoryProps[];
    code: ProductIdProps;
    currency: string;
    discount?: number;
    grossPrice: string;
    grossPriceBeforeDiscount?: string;
    images?: ProductFiles[];
    isNew?: boolean;
    mainImage: ProductFiles;
    maximalProductCountInOrder?: number;
    netPrice: string;
    netPromoPrice: number | null;
    netUnitPrice?: string;
    notAvailableMessage?: string | null;
    points: number;
    productAvailableForSale?: boolean;
    sale: boolean;
    slug: string;
    title: string;
    unit?: string;
    imageWrapperProps?: FlexProps;
    imageProps?: {
        width?: number;
        height?: number;
    } & ImageProps;
    detailsProps?: GridProps;
    linkToProductPage?: boolean;
    subscribed?: boolean;
    favourite: boolean;
    showFavourite?: boolean;
    lowestPriceIn30Days?: number;
    removeFromFavourite?: (code: ProductIdProps) => void;
};

export interface StrapiProductAttributes {
    title: string;
    createdAt: string;
    description: string;
    locale: LocaleProps;
    productCode: string;
    region: string[];
    slug: string;
    updatedAt: string;
    images: {
        data: ProductFiles[];
    };
}

export interface StrapiProductPage {
    id: number;
    attributes: StrapiProductAttributes;
}

export interface ItemsProps {
    code: ProductIdProps;
    quantity: number;
}

export interface ItemsPropsUnlogged extends ItemsProps {
    grossPrice: number;
    catalogue?: boolean;
}

export interface CardProps {
    product: Product;
    quantity: number;
}
export interface CategoryProductListProps {
    items: Product[];
    currentPage: number;
    totalPageCount: number;
    totalCount: number;
}

export interface UnavailableProducts extends Product {
    avalaibleDate: string | 'withdrawn' | 'unavailable';
}

export type PresentProps = {
    id: ProductIdProps;
    points: number;
    quantity: number;
};

export interface ShipmentProps {
    id: string;
    title: string;
    method_description: string;
    enabled: boolean;
    cost: number;
    currency: string;
}

export interface GlobalProductProps {
    lang: LocaleProps;
    settings: {
        vat: number;
        shipment: ShipmentProps[];
        presents?: PresentProps[];
    };
}

export type CheckoutProps = {
    items: Product[];
};

export type PresentsProps = {
    id: number;
};

export enum DisabledReason {
    CreatedByAdmin = 'CREATED_BY_ADMIN',
    OrderObsolete = 'ORDER_OBSOLETE',
    OrderingDisabledGlobal = 'ORDERING_DISABLED_GLOBAL',
    PendingTransferPayment = 'PENDING_TRANSFER_PAYMENT',
    WrongOrderStatus = 'WRONG_ORDER_STATUS'
}

export type BasketControlsProps = {
    creationDate: string;
    deliveryCountry: string;
    discountAvailable: boolean;
    disabledReason: DisabledReason;
    discountIncludeEnabled: boolean;
    editable: boolean;
    expiresAt: string;
    orderingEnabled: boolean;
    progressStep: string;
    refundAvailable: boolean;
    refundIncludeEnabled: boolean;
    rulesAccepted: boolean;
    secondsToExpire: number;
};

export type CartSummary = {
    currency: string;
    grossCost: string;
    netVolume: string;
    orderNo: string;
    points: number;
    presentsCost: string;
    representativeNo: string;
    shippingCost: string;
    discountUsed: string;
    refundMoneyUsed: string;
    totalGrossCost: string;
    shippingProductCost: string;
};

export type BasketShippingProps = {
    address: AddressData;
    shippingCost: number;
};

export type CartGift = {
    autoAdded: boolean;
    description: never;
    maxQuantity: number;
    priceCutPercent: number;
    priceGross: string;
    priceGrossSingle: string;
    priceGrossWas: string;
    productCode: string;
    promotion: CartPromotionDescription;
    promotionCode: string;
    quantity: number;
    productDetails?: {
        code: string;
        grossPrice: string;
        grossPriceBeforeDiscount: string;
        currency: string;
        title: string;
        mainImage: ProductFiles;
        images: ProductFiles;
        productAvailableForSale: boolean;
        quantity: number;
        visibleForGuest: boolean;
        new: boolean;
    };
};

export type CartPromotionDescription = {
    name: string;
    conditions: string;
    reasonForGet: string;
};

export type BasketNotificationsGiftProps = {
    productCode: string;
    promotionCode: string;
    promotion: CartPromotionDescription;
    autoAdded: boolean;
    lostQuantity: number;
};

export type CartShippingFeeNotification = {
    neededPoints: number;
    neededMarketingProductsCost: number;
    promoShippingFee: number;
};

export type CartGiftNeedNotification = {
    productCode: string;
    promotionCode: string;
    promotion: CartPromotionDescription;
    neededPoints: number;
};

export type CartProductNotification = {
    code: string;
    productId: number;
    quantity: number;
    quantityNotReserved: number;
};

export type BasketNotificationsProps = {
    shippingFeeDiscount: CartShippingFeeNotification;
    neededEasyStartPoints: number;
    promotionNeeds: CartGiftNeedNotification[];
    giftsGained: Pick<BasketNotificationsGiftProps, 'productCode' | 'promotionCode'> &
        {
            gainedQuantity: number;
        }[];
    giftsLost: BasketNotificationsGiftProps[];
    productAchievedMaxQuantity: CartProductNotification;
};

export type PaymentProps = {
    einvoice: boolean;
    paymentMethod: PaymentMethod;
    paymentMethodFeeGrossCost: number;
};

export type BasketProps = {
    controls: BasketControlsProps;
    gifts: CartGift[];
    notifications: BasketNotificationsProps;
    payment: PaymentProps;
    products: Product[];
    shipping: BasketShippingProps;
    summary: CartSummary;
};

export type BasketUnloggedProps = {
    total: number;
};

export type DebugProps = {
    reservationsDiffs: unknown;
    reservationsBefore: unknown;
    reservationsAfter: unknown;
};

export interface ProductProps {
    product: Product;
    basketItem: Product;
}

export interface ProductActionsDisabled {
    code: ProductIdProps;
    promotionCode?: string;
}

export interface UpdatePresentProps {
    debug: DebugProps;
    gift: CartGift;
    summary: CartSummary;
}
