function validateIbanChecksum(iban: string) {
    iban = iban.replace(/\s+/g, '').toUpperCase();

    iban = iban.slice(4) + iban.slice(0, 4);

    iban = iban.replace(/[A-Z]/g, (letter) => String(letter.charCodeAt(0) - 55));

    const checksum = iban.split('').reduce((sum, digit) => {
        const num = parseInt(digit, 10);
        if (isNaN(num)) {
            throw new Error('Invalid character in IBAN');
        }
        return (sum * 10 + num) % 97;
    }, 0);

    return checksum === 1;
}

export default validateIbanChecksum;
