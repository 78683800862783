import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { UpdatePresentProps } from '@/models/props/ProductCardProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_ENDPOINT = EnvironmentService.getPublicApiURL();

async function addPresent(
    promotionCode: string,
    productCode: ProductIdProps,
    quantity: number,
    opts?: OptionsWithServerSideRequest
) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/carts/gifts`);

    return axios
        .post<UpdatePresentProps>(
            url.toString(),
            { promotionCode, productCode, quantity },
            { headers }
        )
        .then((response) => response.data);
}

async function updatePresent(
    promotionCode: string,
    productCode: ProductIdProps,
    quantity: number,
    opts?: OptionsWithServerSideRequest
) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/carts/gifts`);

    return axios
        .put<UpdatePresentProps>(
            url.toString(),
            { promotionCode, productCode, quantity },
            { headers }
        )
        .then((response) => response.data);
}

async function deletePresent(
    promotionCode: string,
    productCode: ProductIdProps,
    opts?: OptionsWithServerSideRequest
) {
    const headers = getAuthHeaders(opts);
    const url = new URL(`${BASE_ENDPOINT}/carts/gifts/${promotionCode}/${productCode}`);

    return axios.delete(url.toString(), { headers }).then((res) => res.data);
}

export const PresentService = {
    addPresent,
    updatePresent,
    deletePresent
};
