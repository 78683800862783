import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '@/components/UI/Buttons/Button';

export const InactivekModal = () => {
    const { onClose, onOpen, isOpen } = useDisclosure();
    const closeModal = useCallback(() => {
        onClose();
        sessionStorage.removeItem('show-inactive-modal');
    }, [onClose]);

    useEffect(() => {
        const storage = sessionStorage.getItem('show-inactive-modal');

        if (storage === 'true') {
            onOpen();
        }
    }, [onOpen]);

    return (
        <Modal isOpen={isOpen} onClose={closeModal} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalBody p={6}>
                    <Flex direction="column" alignItems="center">
                        <InfoOutlineIcon color="blue.600" fontSize={100} />
                        <Text fontSize={20} my={4} textAlign="center">
                            <FormattedMessage id="inactive.modal.text" />
                        </Text>
                        <Button onClick={closeModal}>
                            <FormattedMessage id="close" />
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
