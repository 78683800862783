import axios from 'axios';

import { BasicProductCategory, ProductCategoryProps } from '@/models/props/ProductCategoryProps';
import { EnvironmentService } from '@/services/EnvironmentService';

const CATEGORIES_URL = EnvironmentService.getPublicApiURL() + '/product/categories';

async function getAllCategories(): Promise<ProductCategoryProps[]> {
    const url = new URL(CATEGORIES_URL);

    const { data } = await axios.get(url.toString());

    return data;
}

export async function getCategoryBySlug(slug: string) {
    const url = new URL(CATEGORIES_URL);

    url.pathname = url.pathname.concat('/', slug);

    return await axios.get<BasicProductCategory[]>(url.toString());
}

export const CategoryService = {
    getAllCategories,
    getCategoryBySlug
};
