import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    stroke?: string;
};

export const DownIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 8.537 4.393" {...props}>
            <path
                d="M.704.706L4.36 3.737 7.831.706"
                stroke={props.stroke || '#181E20'}
                strokeLinecap="round"
                strokeWidth="1"
                fill="none"
            />
        </Icon>
    );
};

export default DownIcon;
