import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';
import { CategoryService } from '@/services';

import { CategoriesList } from './CategoriesList';

export const CategoriesMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [closeMenu, setCloseMenu] = useState(false);
    const [categories, setCategories] = useState<ProductCategoryProps[]>([]);
    const [mouseOver, setMouseOver] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const getCategories = useCallback(async () => {
        const categoriesList = await CategoryService.getAllCategories().catch((error) =>
            // eslint-disable-next-line no-console
            console.error('Cannot fetch categories', error)
        );

        setCategories(categoriesList || []);
    }, []);

    const menuOverHandle = useCallback(() => {
        setMouseOver(true);
    }, []);

    const menuLeaveHandle = useCallback(() => {
        setMouseOver(false);
    }, []);

    const onWheelHandles = useCallback(
        (close: () => void) => {
            if (menuOpen) {
                if (!mouseOver) {
                    close();
                }

                return;
            }
        },
        [menuOpen, mouseOver]
    );

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    return (
        <Menu
            closeOnSelect={false}
            onClose={() => {
                setMenuOpen(false);
                setCloseMenu(false);
                menuRef.current?.removeEventListener('mouseover', menuOverHandle);
                menuRef.current?.removeEventListener('mouseleave', menuLeaveHandle);
            }}
            onOpen={() => {
                setMenuOpen(true);
                menuRef.current?.addEventListener('mouseover', menuOverHandle);
                menuRef.current?.addEventListener('mouseleave', menuLeaveHandle);
            }}
            gutter={0}
        >
            {({ isOpen, onClose }) => (
                <>
                    <MenuButton
                        as={Button}
                        variant="outline"
                        rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        fontWeight="normal"
                        width="115px"
                        borderRadius="18px"
                        borderColor="blue.100"
                        isActive={isOpen}
                    >
                        <FormattedMessage id="products" />
                    </MenuButton>
                    <Box
                        minWidth="100%"
                        pointerEvents="none"
                        position="fixed"
                        width="100vw"
                        height="100vh"
                        top="0"
                        left="0"
                    >
                        <MenuList
                            p={0}
                            shadow="none"
                            bg="none"
                            border="none"
                            overflow="hidden"
                            pt={6}
                            width="100%"
                            height="100%"
                            onClick={() => onClose()}
                            onWheel={() => onWheelHandles(onClose)}
                        >
                            <Flex
                                position="absolute"
                                width="100vw"
                                height="100vh"
                                justifyContent="center"
                                pointerEvents={closeMenu ? 'none' : 'auto'}
                            >
                                <CategoriesList
                                    items={categories}
                                    isActive={isOpen}
                                    ref={menuRef}
                                />
                            </Flex>
                        </MenuList>
                    </Box>
                </>
            )}
        </Menu>
    );
};
