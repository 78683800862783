import { SellZone } from '@/constants/sell-zone';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';

export class StrapiUtilsService {
    public static updateUploadsUrl(url: string): string {
        const oneSlashPattern = new RegExp(/^\/{1}[^/]{1}.*$/);
        const startsWithOneSlash = oneSlashPattern.test(url);
        if (startsWithOneSlash) {
            url = EnvironmentService.getStrapiImageURL().concat(url);
        }
        return url;
    }

    public static getPrefixedName(name: string) {
        if (!AppService.getSellZone() || AppService.getSellZone() === SellZone.BetterStyle) {
            return name;
        }
        return AppService.getSellZone().toLowerCase().concat('-', name);
    }

    public static getRegion() {
        if (!AppService.getSellZone() || AppService.getSellZone() === SellZone.BetterStyle) {
            return AppService.getInstanceRegion();
        }
        return AppService.getSellZone().concat('_', AppService.getInstanceRegion());
    }
}
