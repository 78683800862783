import { Box, Container, useMediaQuery } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';

import { useScrollDirection } from '@/hooks/useScrollDirection';
import useScrollPosition from '@/hooks/useScrollPosition';

import HeaderTop from '@/components/Header/HeaderTop';
import { InfoBar } from '@/components/InfoBar/InfoBar';
import { SystemLockBar } from '@/components/SystemLockBar/SystemLockBar';
import { BrandLink } from '@/components/UI/Links/BrandLink';

import { ShoppingNavigationBar } from '@/layouts/ShoppingNavigationBar/ShoppingNavigationBar';
import { HeaderProps } from '@/models/props/HeaderProps';

import breakpoints from '../../../themes/betterstyle-theme/breakpoints';

const Header = ({ scrolledSm }: HeaderProps) => {
    const { data: session } = useSession();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <>
            <Box
                id="page-header"
                as="header"
                pos="fixed"
                top={0}
                left={0}
                zIndex={50}
                w="100%"
                transition="transform 300ms ease-out"
            >
                <HeaderTop scrolledSm={scrolledSm} desktop={desktop} />
                {desktop && (
                    <Box
                        bgColor="white"
                        py={!scrolledSm ? 5 : 1}
                        transition="padding 300ms ease-out"
                    >
                        <Container
                            maxWidth="full"
                            display="flex"
                            alignItems="flex-start"
                            columnGap={4}
                        >
                            <BrandLink />
                            {desktop && <ShoppingNavigationBar />}
                        </Container>
                    </Box>
                )}
                {session && (
                    <Container maxW="full" px={2} pt={!desktop ? 2 : 0} pb={2} bgColor="white">
                        <InfoBar scrolledSm={scrolledSm} />
                    </Container>
                )}
                <SystemLockBar />
            </Box>
        </>
    );
};

const HeaderMemo = memo(Header);

const HeaderWrapper = () => {
    const scrollPosition = useScrollPosition();
    const scrollDirection = useScrollDirection();

    return (
        <HeaderMemo
            scrolledLg={scrollPosition > 200}
            scrolledSm={scrollPosition > 80}
            scrollDirection={scrollDirection}
        />
    );
};

export default memo(HeaderWrapper);
