import { Button, ButtonProps, Text, useMediaQuery } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { BasketIcon } from '@/components/Icons';
import CartQuantity from '@/components/ShoppingCart/CartQuantity';

import breakpoints from '@/../themes/betterstyle-theme/breakpoints';

function ButtonCartUnlogged(props: ButtonProps) {
    const { ...rest } = props;
    const { cartQuantityUnlogged } = useShoppingCart();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <Button
            display="flex"
            alignItems="center"
            columnGap={3}
            variant="ghost"
            borderColor="grey.400"
            borderWidth={1}
            borderRadius={26}
            lineHeight={1}
            maxW="fit-content"
            px={desktop ? 4 : 2}
            h={desktop ? 12 : 10}
            {...rest}
        >
            <CartQuantity quantity={cartQuantityUnlogged} />
            {desktop ? (
                <Text>
                    <FormattedMessage id="basket" />
                </Text>
            ) : (
                cartQuantityUnlogged === 0 && (
                    <BasketIcon height={5} width={5} pointerEvents="none" />
                )
            )}
        </Button>
    );
}

export default ButtonCartUnlogged;
