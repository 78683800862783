import { ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
    baseStyle: {
        th: {
            fontWeight: 400,
            textAlign: 'center',
            textTransform: 'none',
            paddingX: 4,
            paddingY: 2
        },
        td: {
            color: 'grey.900',
            paddingX: 4,
            paddingY: 2,
            textAlign: 'center'
        }
    },
    variants: {
        simple: {
            th: {
                borderBottomColor: 'blue.100',
                borderBottomWidth: '2px',
                color: 'grey.600',
                fontSize: 14
            },
            tr: {
                _last: {
                    td: {
                        borderBottomWidth: 0
                    }
                }
            }
        }
    }
};
