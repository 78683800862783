import { ValidationKey } from '@/constants/validation-key';
import {
    CountryInstanceValidators,
    ValidationReturn
} from '@/validators/countryInstanceValidators/CountryInstanceValidators';

export class DefaultInstanceValidators implements CountryInstanceValidators {
    validateBankAccountNumber(): ValidationReturn {
        return undefined;
    }

    validatePhoneNumber(phoneNumber: string): ValidationReturn {
        const pattern = new RegExp(/^\d{6,}$/, 'g');
        const isValid = pattern.test(phoneNumber);
        return !isValid ? ValidationKey.InvalidPhone : undefined;
    }

    validatePhoneNumberWithPrefix(phoneNumber: string): ValidationReturn {
        return this.validatePhoneNumber(phoneNumber);
    }

    validateTaxIdentificationNumber(): ValidationReturn {
        return undefined;
    }

    validateInternationalTaxIdentificationNumber(): ValidationReturn {
        return undefined;
    }

    validateZipCode(zipCode: string): ValidationReturn {
        const pattern = new RegExp(/^\d{4}$/, 'g');
        const isValid = pattern.test(zipCode);
        return !isValid ? ValidationKey.InvalidZipCode : undefined;
    }
}
