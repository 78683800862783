import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { Locks } from '@/models/api/Locks';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_ENDPOINT = EnvironmentService.getPublicApiURL().concat('/locks');

export class SystemLockService {
    static async getSystemLocks(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(BASE_ENDPOINT);

        return await axios
            .get<Locks>(url.toString(), { headers })
            .then((response) => response.data);
    }
}
