import axios, { RawAxiosRequestHeaders } from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { SortParams } from '@/components/Sorting/SortingProducts';

import { OptionsWithServerSideRequest, Product } from '@/models/api';
import { PaginationParams } from '@/models/api/Pagination';
import { PaginatedProducts } from '@/models/api/Product';
import { LocaleProps } from '@/models/props/LocalizationProps';
import {
    CategoryProductListProps,
    ProductList,
    Sort,
    SortDir,
    StrapiProductPage
} from '@/models/props/ProductCardProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';

const PRODUCT_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/product`;
const PRODUCTS_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/products`;
const STRAPI_ENDPOINT = `${EnvironmentService.getCmsInternalApiUrl()}/products`;
const BASKET_HANDLER_URI = '/api/directsales-site-web/basketHandler';

export class ProductService {
    static async getFilteredProductList(params: { codes: ProductIdProps[]; locale?: LocaleProps }) {
        const url = new URL(STRAPI_ENDPOINT);
        const urlSearchParams = url.searchParams;

        if (params.codes.length > 0) {
            params.codes.forEach((code, index) => {
                urlSearchParams.append(`filters[productCode][$eq][${index}]`, code.toString());
            });
        }

        if (params.locale) {
            urlSearchParams.set('locale', params.locale);
            urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
        }

        urlSearchParams.append('populate', '*');

        const { data } = await axios.get<{ data: StrapiProductPage[] }>(url.toString());

        return data.data;
    }

    static async getProductsListHandler(params: ProductList) {
        return axios
            .get(BASKET_HANDLER_URI, {
                data: {
                    productList: params.productCodes,
                    pageSize: params.pageSize,
                    locale: params.locale
                }
            })
            .then((res) => {
                return res.data;
            });
    }

    static async getNewProducts(params: ProductList): Promise<CategoryProductListProps> {
        const headers = getAuthHeaders(params.opts);
        const url = new URL(`${PRODUCT_ENDPOINT}/new`);
        const urlSearchParams = url.searchParams;

        if (params?.text) {
            urlSearchParams.set('text', params.text);
        }

        if (params?.categoryId) {
            urlSearchParams.set('categoryId', params?.categoryId.join(','));
        }

        if (params?.additionalFiles) {
            urlSearchParams.append('populate', 'additionalFiles');
        }

        if (params?.sort) {
            urlSearchParams.set('sort', params?.sort);
        }

        if (params?.productCodes) {
            urlSearchParams.set('productCodes', params?.productCodes.join(','));
        }

        if (params?.locale) {
            urlSearchParams.set('locale', params?.locale);
            urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
        }

        // avoid cache for authenticated requests
        if (headers) {
            urlSearchParams.set('authenticated', 'true');
        }
        urlSearchParams.set('sortDir', params?.sortDir ?? 'ASC');
        urlSearchParams.set('pageSize', params?.pageSize ? params?.pageSize?.toString() : '25');
        urlSearchParams.set('pageNo', params?.pageNo ? params?.pageNo.toString() : '1');

        const { data } = await axios.get<CategoryProductListProps>(url.toString(), { headers });
        return data as CategoryProductListProps;
    }

    static async getProductsList(params: ProductList): Promise<CategoryProductListProps> {
        const headers: RawAxiosRequestHeaders = getAuthHeaders(params.opts);
        const url = new URL(PRODUCT_ENDPOINT);
        const urlSearchParams = url.searchParams;

        if (params.text) {
            urlSearchParams.set('text', params.text);
        }

        if (params.categoryId) {
            urlSearchParams.set('categoryId', params.categoryId.join(','));
        }

        if (params.additionalFiles) {
            urlSearchParams.append('populate', 'additionalFiles');
        }

        if (params.sort) {
            urlSearchParams.set('sort', params.sort);
        }

        if (params.productCodes) {
            urlSearchParams.set('productCodes', params.productCodes.join(','));
        }

        if (params.locale) {
            urlSearchParams.set('locale', params.locale);
            urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
        }

        // avoid cache for authenticated requests
        if (headers) {
            urlSearchParams.set('authenticated', 'true');
        }
        urlSearchParams.set('visibility', 'VISIBLE');
        urlSearchParams.set('sortDir', params.sortDir ?? 'ASC');
        urlSearchParams.set('pageSize', params.pageSize ? params.pageSize?.toString() : '25');
        urlSearchParams.set('pageNo', params.pageNo ? params.pageNo.toString() : '1');

        const { data } = await axios.get<CategoryProductListProps>(url.toString(), {
            headers,
            timeout: 5000
        });

        if (!headers) {
            const guestProducts = (data.items as Product[]).filter(
                (product) => product?.visibleForGuest
            );

            return {
                items: guestProducts,
                currentPage: data.currentPage,
                totalPageCount: data.totalPageCount,
                totalCount: data.totalCount
            };
        }

        return data;
    }

    static async getProductByCode(params: {
        code: ProductIdProps;
        opts?: OptionsWithServerSideRequest;
    }): Promise<Product> {
        const headers: RawAxiosRequestHeaders = getAuthHeaders(params.opts);
        const url = new URL(`${PRODUCT_ENDPOINT}/${params.code}`);

        const { data } = await axios.get<Product>(url.toString(), { headers });

        return data;
    }

    static async getSaleProducts(params: {
        sort?: Sort;
        sortDir?: SortDir;
        pageNo?: number;
        pageSize?: number;
        opts?: OptionsWithServerSideRequest;
    }): Promise<CategoryProductListProps> {
        const headers = getAuthHeaders(params.opts);
        const url = new URL(`${PRODUCT_ENDPOINT}/sale`);
        const urlSearchParams = url.searchParams;
        if (params.sort) {
            urlSearchParams.set('sort', params.sort);
        }
        urlSearchParams.set('sortDir', params.sortDir ?? 'ASC');
        urlSearchParams.set('pageSize', params.pageSize ? params.pageSize?.toString() : '25');
        urlSearchParams.set('pageNo', params.pageNo ? params.pageNo.toString() : '1');
        if (headers) {
            urlSearchParams.set('authenticated', 'true');
        }
        const { data } = await axios.get<CategoryProductListProps>(url.toString(), { headers });
        return data as CategoryProductListProps;
    }

    static async getSuggestedProducts(params: {
        code: ProductIdProps;
        opts?: OptionsWithServerSideRequest;
    }) {
        const headers: RawAxiosRequestHeaders = getAuthHeaders(params.opts);
        const code = encodeURIComponent(params.code);
        const url = new URL(`${PRODUCT_ENDPOINT}/${code}/suggested`);

        return axios.get<Product[]>(url.toString(), { headers });
    }

    static async addToFavourites(params: {
        code: ProductIdProps;
        opts?: OptionsWithServerSideRequest;
    }) {
        const headers = getAuthHeaders(params.opts);
        const url = new URL(`${PRODUCT_ENDPOINT}/favourite/${params.code}`);

        const { data } = await axios.post<Product>(url.toString(), null, { headers });
        return data;
    }

    static async getFavouriteProducts(
        params: {
            opts?: OptionsWithServerSideRequest;
        } & SortParams &
            PaginationParams = {}
    ) {
        const headers = getAuthHeaders(params?.opts);
        const url = new URL(`${PRODUCT_ENDPOINT}/favourite`);
        const urlSearchParams = url.searchParams;
        if (params.sort) {
            urlSearchParams.set('sort', params.sort);
        }
        urlSearchParams.set('sortDir', params.sortDir ?? 'ASC');
        urlSearchParams.set('pageSize', params.pageSize ? params.pageSize?.toString() : '25');
        urlSearchParams.set('pageNo', params.pageNo ? params.pageNo.toString() : '1');

        const { data } = await axios.get<PaginatedProducts>(url.toString(), { headers });
        return data;
    }

    static async removeFromFavourites(params: {
        code: ProductIdProps;
        opts?: OptionsWithServerSideRequest;
    }) {
        const headers = getAuthHeaders(params.opts);
        const url = new URL(`${PRODUCT_ENDPOINT}/favourite/${params.code}`);

        const { data } = await axios.delete<Product>(url.toString(), { headers });
        return data;
    }

    static async subscribeProductAvailabilityNotification(
        code: ProductIdProps,
        opts?: OptionsWithServerSideRequest
    ) {
        const headers: RawAxiosRequestHeaders = getAuthHeaders(opts);
        const url = new URL(`${PRODUCTS_ENDPOINT}/unavailable/subscribe`);

        url.searchParams.set('productCode', code.toString());

        return axios.post<void>(url.toString(), { headers });
    }
}
