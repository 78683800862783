import {
    Box,
    BoxProps,
    Button as ChakraButton,
    ButtonProps as ChakraButtonProps
} from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

type ButtonProps = {
    innerContainerProps?: BoxProps;
} & ChakraButtonProps;

export const Button = (props: PropsWithChildren<ButtonProps>) => {
    const { children, loadingText, innerContainerProps, ...buttonProps } = props;

    return (
        <ChakraButton
            loadingText={loadingText ? <Box as="span">{loadingText}</Box> : null}
            {...buttonProps}
        >
            <Box as="span" {...innerContainerProps}>
                {children}
            </Box>
        </ChakraButton>
    );
};
