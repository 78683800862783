import { Box, Container, Flex } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';

import { loggedUserShoppingMenu, unLoggedUserShoppingMenu } from '@/data/menu';

import { CurrentOffer } from '@/components/Navigation/ShoppingNavigation/CurrentOffer';
import { ProductSearch } from '@/components/Search/ProductSearch';

export const ShoppingNavigationBar = () => {
    const { data } = useSession();
    const shoppingMenu = data?.user ? loggedUserShoppingMenu : unLoggedUserShoppingMenu;

    return (
        <Container maxWidth="full" px={0}>
            <Flex
                zIndex={5}
                justifyContent="end"
                alignItems="center"
                position="relative"
                columnGap={4}
            >
                <Box as="nav" width="max-content" mx="auto">
                    <CurrentOffer
                        menu={shoppingMenu}
                        display="flex"
                        textAlign="center"
                        flexWrap="wrap"
                        listStyleType="none"
                        marginLeft={0}
                        columnGap={10}
                        rowGap={2}
                        alignItems="center"
                        justifyContent="center"
                        flexGrow="1"
                    />
                </Box>
                <ProductSearch maxW={60} />
            </Flex>
        </Container>
    );
};
