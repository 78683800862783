import { ListItem, UnorderedList } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import NavLink from '../UI/Links/NavLink';

export interface LinkObject {
    href: string;
    translationKey: string;
}

export interface LinksNavigationProps {
    links: LinkObject[];
    color?: 'grey.main' | 'grey.600';
}

export const LinksNavigation = (props: PropsWithChildren<LinksNavigationProps>) => {
    const { links, color } = props;
    if (!links.length) {
        return null;
    }
    const linkItems = links.map((item, index) => (
        <ListItem key={index} listStyleType="none" px={2}>
            <NavLink href={item.href} color={color ?? 'grey.600'}>
                <FormattedMessage id={item.translationKey} />
            </NavLink>
        </ListItem>
    ));
    return (
        <nav>
            <UnorderedList display="flex" flexDirection="row" m={0} maxW="full">
                {linkItems}
            </UnorderedList>
        </nav>
    );
};
