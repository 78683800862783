import { Link as ChakraLink, LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import NextLink, { LinkProps } from 'next/link';
import { PropsWithChildren } from 'react';

export interface NavLinkProps extends LinkProps {
    variant?: 'boldedBlue' | 'buttonFade' | 'buttonBlue' | 'buttonOutline' | 'unstyled';
}

const NavLink = (props: PropsWithChildren<NavLinkProps & ChakraLinkProps>) => {
    const { variant, ...chakraProps } = props;

    return (
        <ChakraLink
            as={NextLink}
            {...chakraProps}
            variant={variant || 'unstyled'}
            href={props.href}
        >
            {props.children}
        </ChakraLink>
    );
};

export default NavLink;
