import inputMasks from '@/lib/input-masks';

import { CountryCode, CountryCodeValue } from '@/constants/country-codes';
import { Currency } from '@/constants/currency';
import Region from '@/constants/regions';
import RoutePath from '@/constants/route-path';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { EnvironmentService } from '@/services/EnvironmentService';
import {
    CountryInstanceValidators,
    GermanyInstanceValidators,
    PolandInstanceValidators
} from '@/validators/countryInstanceValidators';
import { DefaultInstanceValidators } from '@/validators/countryInstanceValidators/DefaultInstanceValidators';

function getInstanceCurrency() {
    const appCountryCode = getAppCountryCode().toUpperCase() as CountryCodeValue;
    const currency = Currency[appCountryCode];
    if (!currency) {
        console.warn(`Currency for ${appCountryCode} instance is not defined`); // eslint-disable-line no-console
        return '';
    }
    return currency;
}

function getInstanceInputMasks() {
    const appCountryCode = getAppCountryCode().toUpperCase() as CountryCodeValue;
    const instanceInputMasks = inputMasks[appCountryCode];
    if (!instanceInputMasks) {
        throw new Error(`Input masks for ${appCountryCode} instance are not defined`);
    }
    return instanceInputMasks;
}

function getInstanceRegion(): string {
    const instanceCountryCode = getAppCountryCode().toLowerCase() as LocaleProps;
    return Region[instanceCountryCode];
}

function isPolishInstance() {
    return getAppCountryCode() === CountryCode.PL;
}

function isGermanInstance() {
    return getAppCountryCode() === CountryCode.DE;
}

function getAdultAge(): number {
    const appCountryCode = getAppCountryCode().toUpperCase();

    switch (appCountryCode) {
        case CountryCode.DE:
            return 18;
        case CountryCode.PL:
            return 18;
        default:
            throw new Error('Adult age must be defined');
    }
}

function getAppCountryCode(): string {
    const instanceCountryCode = process.env.NEXT_PUBLIC_INSTANCE_COUNTRY_CODE;
    if (!instanceCountryCode) {
        throw new Error('Instance country code is not defined. Check environment configuration');
    }
    return instanceCountryCode;
}

function getContactEmailAddress(): string {
    const contactEmailAddress = process.env.NEXT_PUBLIC_CONTACT_EMAIL_ADDRESS;
    if (!contactEmailAddress) {
        throw new Error('Contact email address is not defined. Check environment configuration');
    }
    return contactEmailAddress;
}

function getClubRegulationsFileLink() {
    const appCountryCode = getAppCountryCode();
    switch (appCountryCode) {
        case CountryCode.PL:
            return `/dokumenty/regulamin_klubu.pdf`;
        case CountryCode.DE:
            return `/documents/betterstyle_clubordnung.pdf`;
        default:
            return '';
    }
}

function getCompanyClubRegulationsFileLink() {
    const appCountryCode = getAppCountryCode();
    switch (appCountryCode) {
        case CountryCode.PL:
            return `/dokumenty/regulamin_klubu_dla_firm.pdf`;
        case CountryCode.DE:
            return `/documents/besonderen_bedingungen_des_Betterstyle_Clubs.pdf`;
        default:
            return '';
    }
}

function getClubPrivacyPolicyFileLink() {
    const appCountryCode = getAppCountryCode();
    switch (appCountryCode) {
        case CountryCode.PL:
            return `/dokumenty/polityka_prywatnosci.pdf`;
        case CountryCode.DE:
            return `/documents/datenschutzerklarung.pdf`;
        default:
            return '';
    }
}

function getMainLanguage(): string {
    const language = process.env.NEXT_PUBLIC_APP_MAIN_LANGUAGE || '';
    if (!language) {
        throw Error(
            'Default language is not set. Check NEXT_PUBLIC_APP_MAIN_LANGUAGE key in environments configuration.'
        );
    }
    return language;
}

function getLanguages(): string[] {
    const mainLanguage = getMainLanguage();
    const languagesString = EnvironmentService.getI18nAdditionalLanguages() || '';
    const languages = languagesString.split(',').filter((language) => language);
    return [mainLanguage, ...languages];
}

function getSellZone() {
    const sellZone = process.env.NEXT_PUBLIC_SELL_ZONE;
    if (!sellZone) {
        throw Error(
            'Sell zone is not set. Check NEXT_PUBLIC_SELL_ZONE key in environments configuration.'
        );
    }

    return sellZone;
}

function getSponsorLink(sponsorNumber: string): string {
    return `${process.env.NEXT_PUBLIC_CANNONICAL_URL}${RoutePath.Join}/${sponsorNumber}`;
}

function getInformationObligationFileLink() {
    const appCountryCode = getAppCountryCode();
    switch (appCountryCode) {
        case CountryCode.PL:
            return `/dokumenty/obowiazek_informacyjny.pdf`;
        case CountryCode.DE:
            return `/documents/informationspflicht.pdf`;
        default:
            return '';
    }
}

function getInstanceCountryValidators(): CountryInstanceValidators {
    const appCountryCode = getAppCountryCode();
    switch (appCountryCode) {
        case CountryCode.PL:
            return new PolandInstanceValidators();
        case CountryCode.DE:
            return new GermanyInstanceValidators();
        default:
            return new DefaultInstanceValidators();
    }
}

function getPhonePrefix(): string {
    const instanceCountryCode = getAppCountryCode();
    switch (instanceCountryCode) {
        case CountryCode.PL:
            return '+48';
        case CountryCode.DE:
            return '+49';
        default:
            return '';
    }
}

export const AppService = {
    getAdultAge,
    getAppCountryCode,
    getCompanyClubRegulationsFileLink,
    getContactEmailAddress,
    getClubRegulationsFileLink,
    getClubPrivacyPolicyFileLink,
    getInstanceCurrency,
    getInstanceInputMasks,
    getInstanceRegion,
    getSponsorLink,
    getInformationObligationFileLink,
    getInstanceCountryValidators,
    getLanguages,
    getMainLanguage,
    getPhonePrefix,
    getSellZone,
    // getTranslations,
    isPolishInstance,
    isGermanInstance
};
