import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { AppService } from '@/services';

export interface LanguageSelectorProps {
    color?: 'grey.main' | 'grey.600';
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
    const router = useRouter();
    const { locale, pathname, asPath, basePath, query } = router;
    const { color } = props;
    const languages = useMemo(
        () => AppService.getLanguages().filter((item) => item !== locale),
        [locale]
    );

    const languageClickHandler = (language: string) => {
        router.push({ pathname, query }, asPath, { locale: language });
    };

    const menuItems = languages.map((lang) => (
        <MenuItem key={lang} fontSize={14} onClick={() => languageClickHandler(lang)}>
            <Box as="span" display="inline-flex" mr={2}>
                <Image
                    src={`${basePath}/images/icons/lang/${lang.toLowerCase()}.svg`}
                    alt={lang.toLowerCase()}
                    width={14}
                    height={14}
                />
            </Box>
            {lang.toUpperCase()}
        </MenuItem>
    ));

    const langDropdown =
        languages.length > 1 ? (
            <Menu>
                <MenuButton
                    as={Button}
                    display="flex"
                    alignItems="center"
                    px={1}
                    color={color ?? 'grey.main'}
                    fontWeight="normal"
                    rightIcon={<ChevronDownIcon />}
                    variant="link"
                >
                    <Box as="span" display="inline-flex" mr={2}>
                        <Image
                            src={`${basePath}/images/icons/lang/${locale}.svg`}
                            alt={locale as string}
                            width={17}
                            height={15}
                        />
                    </Box>
                    <Box as="span" display="inline-table">
                        {locale?.toUpperCase()}
                    </Box>
                </MenuButton>
                <MenuList minW="80px" p={0} borderRadius={21} overflow="hidden">
                    {menuItems}
                </MenuList>
            </Menu>
        ) : null;
    return <>{langDropdown}</>;
};

export default LanguageSelector;
