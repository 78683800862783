import { Box } from '@chakra-ui/react';
import cn from 'clsx';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { useState } from 'react';

import Classes from './style.module.scss';

import shimmer from '@/utils/Shimmer';

import toBase64 from './toBase64';

type NextImageProps = {
    width?: string | number;
    height?: string | number;
    zIndex?: string | number;
    left?: string | number;
    top?: string | number;
    objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
    transition?: string;
    transform?: string;
} & ImageProps;

const customLoader = ({ src }: ImageLoaderProps): string => {
    return src;
};

export const NextImageBackground = (props: NextImageProps) => {
    const [isLoading, setLoading] = useState(true);
    const { src, alt, width, quality, height, left, top, objectFit, transition, ...rest } = props;

    return (
        <Box
            pos="absolute"
            width="100%"
            height="100%"
            cursor="pointer"
            className="group"
            zIndex="-1"
            left={left ? left : 0}
            top={top ? top : 0}
            transition={transition}
            _groupHover={{ transform: 'scale(1.05)' }}
        >
            <Image
                src={`${src}?w=${width}&q=${quality || 75}`}
                alt={alt}
                fill
                loader={customLoader}
                unoptimized={true}
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                    shimmer(width ? +width : 700, height ? +height : 475)
                )}`}
                style={{ objectFit: objectFit || 'cover' }}
                className={cn(Classes.nextImage, isLoading ? Classes.loading : Classes.loaded)}
                onLoadingComplete={() => setLoading(false)}
                {...rest}
            />
        </Box>
    );
};
