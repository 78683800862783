import { ChakraProvider } from '@chakra-ui/react';
import axios from 'axios';
import { LoadingProgressProvider } from 'context/progressBarCotnext';
import { ServiceRegulationsProvider } from 'context/serviceRegulationsContext';
import { ShoppingCartProvider } from 'context/shoppingCartContext';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { Suspense, useEffect } from 'react';

import '../../styles/globals.scss';

import { httpsAgent } from '@/lib';
import { handleClientError } from '@/lib/error-handler';

import { AsyncIntlProvider } from '@/components/AsyncIntl/AsyncIntlProvider';
import { CookieConsentPopup } from '@/components/CookieConsentPopup/CookieConsentPopup';
import { CookieConsentSettingsPanel } from '@/components/CookieConsentSettingsPanel/CookieConsentSettingsPanel';

import { Locks } from '@/models/api/Locks';
import { MenuField } from '@/models/cms/Footer';

import PageLayout from '../layouts/PageLayout';
import { AppProvider } from '../../context/appContext';
import { BreadCrumbsProvider } from '../../context/breadcrumbsContext';
import { CookieConsentProvider } from '../../context/cookieConsentContext';
import { CurrentUserProvider } from '../../context/currentUserContext';
import defaultTheme from '../../themes/betterstyle-theme';

export const APP_STAGING = Number(process.env.NEXT_PUBLIC_APP_STAGING);

export type AppCommonProps = AppProps & {
    session: Session;
    footerMenu: MenuField[];
    locks: Locks;
};

httpsAgent.configureAxios();

function App({ Component, pageProps, session }: AppCommonProps) {
    const { loginAllowed, orderingAllowed } = pageProps.locks || {};

    useEffect(() => {
        if (pageProps?.session) {
            axios.interceptors.response.use(
                (response) => response,
                (error) => handleClientError(error, pageProps?.session)
            );
        }
    }, [pageProps?.session]);

    return (
        <Suspense fallback={null}>
            <AppProvider
                locks={pageProps.locks}
                loginAllowed={loginAllowed}
                orderingAllowed={orderingAllowed}
            >
                <CurrentUserProvider>
                    <ChakraProvider theme={defaultTheme}>
                        <LoadingProgressProvider>
                            <AsyncIntlProvider>
                                <CookieConsentProvider>
                                    <CookieConsentPopup />
                                    <CookieConsentSettingsPanel />
                                    <BreadCrumbsProvider>
                                        <SessionProvider session={session}>
                                            <ShoppingCartProvider>
                                                <ServiceRegulationsProvider>
                                                    <PageLayout
                                                        menu={pageProps.footerMenu}
                                                        crumbs={pageProps.crumbs}
                                                    >
                                                        <Script
                                                            src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_TAG_MANAGER}`}
                                                        />
                                                        <Component {...pageProps} />
                                                    </PageLayout>
                                                </ServiceRegulationsProvider>
                                            </ShoppingCartProvider>
                                        </SessionProvider>
                                    </BreadCrumbsProvider>
                                </CookieConsentProvider>
                            </AsyncIntlProvider>
                        </LoadingProgressProvider>
                    </ChakraProvider>
                </CurrentUserProvider>
            </AppProvider>
        </Suspense>
    );
}

export default App;
