import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import { BonusBalance } from '@/models/api/BonusBalance';
import { BonusBalanceDetails } from '@/models/api/BonusBalanceDetails';
import { BonusMonthlySummaryHistory } from '@/models/api/BonusMonthlySummary';
import { MoneySummary } from '@/models/api/MoneySummary';
import { PersonalBalance, PersonalBalanceHistory } from '@/models/api/PersonalBalance';
import { PersonalGroupRefundsAndCanceledOrders } from '@/models/api/RefundsAndCanceledOrders';
import { TransactionHistoryEntry } from '@/models/api/TransactionHistoryBalanceGross';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_URL = EnvironmentService.getPublicApiURL().concat('/money');
const BONUS_BALANCE_URL = BASE_URL.concat('/bonus-balance');
const PERSONAL_BALANCE_URL = BASE_URL.concat('/personal-balance');
const PERSONAL_GROUP_URL = BASE_URL.concat('/personal-group');

export class MoneyService {
    static async getUserMoney(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(BASE_URL);

        const { data } = await axios.get<MoneySummary>(url.toString(), {
            headers,
            signal: opts?.abortController?.signal
        });
        return data;
    }

    static async getPersonalBalance(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(PERSONAL_BALANCE_URL);

        return await axios
            .get<PersonalBalance>(url.toString(), {
                headers,
                signal: opts?.abortController?.signal
            })
            .then((response) => response.data);
    }

    static async getPersonalBalanceHistory(data: {
        dateFrom: string;
        dateTo: string;
        opts?: OptionsWithServerSideRequest;
    }) {
        const { dateFrom, dateTo, opts } = data;
        const headers = getAuthHeaders(opts);
        const url = new URL(PERSONAL_BALANCE_URL.concat('/history'));
        url.searchParams.set('fromDate', dateFrom);
        url.searchParams.set('toDate', dateTo);

        return await axios
            .get<PersonalBalanceHistory>(url.toString(), {
                headers,
                signal: opts?.abortController?.signal
            })
            .then((response) => response.data);
    }

    static async getBonusBalanceSummary(data: {
        dateFrom: string;
        dateTo: string;
        opts?: OptionsWithServerSideRequest;
    }) {
        const { dateFrom, dateTo, opts } = data;
        const headers = getAuthHeaders(opts);
        const url = new URL(BONUS_BALANCE_URL.concat('/summary'));
        url.searchParams.set('fromDate', dateFrom);
        url.searchParams.set('toDate', dateTo);

        return await axios
            .get<BonusMonthlySummaryHistory>(url.toString(), {
                headers,
                signal: opts?.abortController?.signal
            })
            .then((response) => response.data);
    }

    static async getTotalBonusBalance(data: {
        year: string;
        month: string;
        opts?: OptionsWithServerSideRequest;
    }) {
        const { year, month, opts } = data;
        const headers = getAuthHeaders(opts);
        const url = new URL(BONUS_BALANCE_URL.concat('/total'));
        url.searchParams.set('year', year);
        url.searchParams.set('month', month);

        return await axios
            .get<PersonalBalance>(url.toString(), {
                headers,
                signal: opts?.abortController?.signal
            })
            .then((response) => response.data);
    }

    static async getTransactionHistoryBalanceGross(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(PERSONAL_BALANCE_URL.concat('/transaction-history-balance-gross'));

        return await axios
            .get<
                TransactionHistoryEntry[]
            >(url.toString(), { headers, signal: opts?.abortController?.signal })
            .then((response) => response.data);
    }

    static async getTransactionHistoryRefunds(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(PERSONAL_BALANCE_URL.concat('/transaction-history-refunds'));

        return await axios
            .get<
                TransactionHistoryEntry[]
            >(url.toString(), { headers, signal: opts?.abortController?.signal })
            .then((response) => response.data);
    }

    static async getBonusBalance(
        filters?: { month: string; year: string } | null,
        opts?: OptionsWithServerSideRequest
    ) {
        const headers = getAuthHeaders(opts);
        const url = new URL(BASE_URL.concat('/bonus-balance'));
        if (filters?.month) {
            url.searchParams.set('month', filters.month);
        }
        if (filters?.year) {
            url.searchParams.set('year', filters.year);
        }

        return await axios
            .get<BonusBalance>(url.toString(), { headers, signal: opts?.abortController?.signal })
            .then((response) => response.data);
    }

    static async getBonusBalanceHistory(
        filters?: { month?: string; year?: string; bonusType: string },
        opts?: OptionsWithServerSideRequest
    ) {
        const headers = getAuthHeaders(opts);
        const url = new URL(BASE_URL.concat('/bonus-balance/history'));
        if (filters?.month) {
            url.searchParams.set('month', filters.month);
        }
        if (filters?.year) {
            url.searchParams.set('year', filters.year);
        }
        if (filters?.bonusType) {
            url.searchParams.set('bonusType', filters.bonusType);
        }

        return await axios
            .get<BonusBalanceDetails>(url.toString(), {
                headers,
                signal: opts?.abortController?.signal
            })
            .then((response) => response.data);
    }

    static async getRefundsAndCanceledOrders(data: {
        filters?: { month: string | number; year: string | number } | null;
        opts?: OptionsWithServerSideRequest;
    }) {
        const { filters, opts } = data;
        const headers = getAuthHeaders(opts);
        const url = new URL(PERSONAL_GROUP_URL.concat('/canceled-orders-refunds'));

        if (filters?.month) {
            url.searchParams.set('month', filters.month.toString());
        }
        if (filters?.year) {
            url.searchParams.set('year', filters.year.toString());
        }

        return axios.get<PersonalGroupRefundsAndCanceledOrders>(url.toString(), {
            headers,
            signal: opts?.abortController?.signal
        });
    }
}
