import { ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react';
import { Text } from '@chakra-ui/react';
import { NextRouter, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import { CrumbItem, useBreadcrumbs } from '@/../context/breadcrumbsContext';
import RoutePath from '@/constants/route-path';

export type BreadcrumbsProps = {
    crumbs?: CrumbItem[];
};

interface NextRouterComponents extends NextRouter {
    components?: { [key: string]: never };
}

export function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
    const breadcrumbs = useBreadcrumbs();
    const intl = useIntl();
    const router: NextRouterComponents = useRouter();
    const [breadcrumbItems, setBreadcrumbItems] = useState<CrumbItem[]>();

    useEffect(() => {
        let breadcrumbsList: CrumbItem[];

        if (crumbs) {
            let path = '';

            breadcrumbsList = crumbs.map((item) => {
                item = { ...item };
                path = path.concat('/', item.href);
                item.href = path;
                return item;
            });
        } else {
            const pathWithoutQuery = router.asPath.split('?')[0];
            let pathArray: string[] = pathWithoutQuery.split('/');

            pathArray.shift();

            pathArray = pathArray.filter((path) => path !== '');
            breadcrumbsList = pathArray.map((path, index) => {
                const label = path.charAt(0) + path.slice(1);
                const href = '/' + pathArray.slice(index, index + 1).join();

                return {
                    href,
                    label: `page.${label}.title` || label
                };
            });
        }

        setBreadcrumbItems(breadcrumbsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breadcrumbs, router.asPath]);

    return (
        <>
            {breadcrumbItems && !!breadcrumbItems.length && breadcrumbs.displayBreadcrumbs && (
                <Breadcrumb
                    maxW="full"
                    px={4}
                    separator={<ChevronRightIcon color="black" />}
                    sx={{ ol: { flexWrap: 'wrap' } }}
                >
                    <BreadcrumbItem>
                        <NavLink href={RoutePath.Home} fontWeight="bold" fontSize="sm">
                            <FormattedMessage id="better-style" />
                        </NavLink>
                    </BreadcrumbItem>

                    {breadcrumbItems.map((breadcrumb, index) => {
                        if (index === breadcrumbItems.length - 1) {
                            // const pageTitle = intl.formatMessage({
                            //     id: breadcrumb.label
                            // });

                            return (
                                <BreadcrumbItem key={index} color="grey.600" isCurrentPage>
                                    <Text as="span" wordBreak="break-word">
                                        {breadcrumbs.getTitle() ||
                                            intl.formatMessage({
                                                id: breadcrumb.label,
                                                defaultMessage: breadcrumb.label
                                            })}
                                    </Text>
                                </BreadcrumbItem>
                            );
                        }

                        return (
                            <BreadcrumbItem key={index} color="grey.600">
                                <NavLink href={breadcrumb.href}>
                                    <FormattedMessage
                                        id={breadcrumb.label}
                                        defaultMessage={breadcrumb.label}
                                    />
                                </NavLink>
                            </BreadcrumbItem>
                        );
                    })}
                </Breadcrumb>
            )}
        </>
    );
}
