import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const HamburgerIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 13.3 12.3" {...props}>
            <path d="M 0.65039062,0 A 0.64999998,0.64999998 0 0 0 0,0.65039062 0.64999998,0.64999998 0 0 0 0.65039062,1.3007812 H 12.650391 A 0.64999998,0.64999998 0 0 0 13.300781,0.65039062 0.64999998,0.64999998 0 0 0 12.650391,0 Z" />
            <path d="M 0.65039062,5.5 A 0.64999998,0.64999998 0 0 0 0,6.1503906 0.64999998,0.64999998 0 0 0 0.65039062,6.8007812 H 12.650391 A 0.64999998,0.64999998 0 0 0 13.300781,6.1503906 0.64999998,0.64999998 0 0 0 12.650391,5.5 Z" />
            <path d="M 0.65039062,11 A 0.64999998,0.64999998 0 0 0 0,11.650391 0.64999998,0.64999998 0 0 0 0.65039062,12.300781 H 12.650391 a 0.64999998,0.64999998 0 0 0 0.65039,-0.65039 A 0.64999998,0.64999998 0 0 0 12.650391,11 Z" />
        </Icon>
    );
};
