import { useRouter } from 'next/router';
import { PropsWithChildren, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { EnvironmentService } from '@/services/EnvironmentService';

const cachedMessages: Record<string, Record<string, string>> = {};

function getMessages(locale: string) {
    locale = locale || EnvironmentService.getI18nDefaultLanguage();
    if (cachedMessages[locale]) {
        return cachedMessages[locale];
    }
    throw loadMessages(locale);
}

async function loadMessages(locale: string) {
    let messages = {};
    try {
        messages = await import(`../../../lang/${locale}.json`);
    } catch (error) {
        console.error('Cannot load messages for locale', locale); // eslint-disable-line no-console
        console.trace(error); // eslint-disable-line no-console
    }
    cachedMessages[locale] = messages;
    return messages;
}

export function AsyncIntlProvider({ children }: PropsWithChildren) {
    const { locale } = useRouter();
    const defaultLocale = EnvironmentService.getI18nDefaultLanguage();

    const messages: Record<string, string> = useMemo(() => getMessages(locale as string), [locale]);

    return (
        <IntlProvider locale={locale as string} defaultLocale={defaultLocale} messages={messages}>
            {children}
        </IntlProvider>
    );
}
