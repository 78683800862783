import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const ArrowLeftIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 22.969 34" {...props}>
            <path
                d="M0.5,19.022a3.018,3.018,0,0,1,0-4.679L18.01,0.063a3.054,3.054,0,0,1,4.281.425A3.015,3.015,0,0,1,22.979,2.4v28.56A3.046,3.046,0,0,1,18.01,33.3Z"
                fill="#fff"
            />
            <path
                d="M0.4,16.683a2.006,2.006,0,0,0,.742,1.56l17.512,14.28a2.036,2.036,0,0,0,2.854-.281,2.01,2.01,0,0,0,.46-1.279V2.4A2.03,2.03,0,0,0,18.651.842L1.139,15.123A2.006,2.006,0,0,0,.4,16.683m-1.014,0A3,3,0,0,1,.5,14.343L18.01,0.063a3.054,3.054,0,0,1,4.281.425A3.015,3.015,0,0,1,22.979,2.4v28.56A3.046,3.046,0,0,1,18.01,33.3L0.5,19.022A3,3,0,0,1-.617,16.683Z"
                fill="#D0DCEB"
            />
        </Icon>
    );
};
