import { Box, Flex } from '@chakra-ui/react';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';

import { NextImage } from '@/components/Image';

import { Product } from '@/models/api';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

type ImageProps = {
    height?: number;
    width?: number;
};

function ProductImage({
    mainImage,
    title,
    width,
    height
}: Pick<Product, 'mainImage' | 'title'> & ImageProps) {
    const [imageurl, setImageUrl] = useState<string>();
    const [imgLoading, setImgLoading] = useState<boolean>(false);
    const [imageWidth, setImageWidth] = useState<number>(width || 120);
    const [imageHeight, setImageHeight] = useState<number>(height || 40);

    const obj = useMemo(
        () => ({ mainImage, title, width, height }),
        [mainImage, title, width, height]
    );

    const updateImageState = useCallback(() => {
        if (!obj.mainImage) {
            setImageUrl('/images/logo.svg');
            setImageWidth(120);
            setImageHeight(40);
            setImgLoading(false);
            return;
        }

        const mediumFormatUrl = obj.mainImage.attributes.formats?.medium?.url;
        const imageURL = StrapiUtilsService.updateUploadsUrl(
            mediumFormatUrl ?? obj.mainImage.attributes.url
        );

        setImageWidth(obj.width ?? 200);
        setImageHeight(obj.height ?? 200);
        setImageUrl(imageURL);
        setImgLoading(true);
    }, [obj]);

    useEffect(() => {
        updateImageState();
    }, [updateImageState]);

    const imgProps: CSSProperties = useMemo(
        () => ({
            position: 'relative',
            height: '100%',
            borderRadius: '8px'
        }),
        []
    );

    const nonImageProps: CSSProperties = useMemo(
        () => ({
            position: 'relative',
            maxWidth: 'calc(100% - 20px)',
            marginLeft: 'auto',
            marginRight: 'auto'
        }),
        []
    );

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius={8}
            borderColor="grey.100"
            borderWidth={!imgLoading ? 1 : 0}
            width="100%"
            pb="100%"
        >
            <Box
                position="absolute"
                left="50%"
                top="50%"
                transform="translate(-50%, -50%)"
                width="100%"
                height="100%"
                px={imgLoading ? 0 : '10px'}
            >
                {imageurl && (
                    <NextImage
                        src={imageurl}
                        alt={obj.title || ''}
                        width={imageWidth}
                        height={imageHeight}
                        opacity={imgLoading ? 1 : 0.2}
                        display="flex"
                        position="relative"
                        transform="none"
                        style={{
                            objectFit: 'contain',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ...(!imgLoading ? nonImageProps : imgProps)
                        }}
                    />
                )}
            </Box>
        </Flex>
    );
}

export default ProductImage;
