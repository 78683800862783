import { IconProps } from '@chakra-ui/icons';
import { forwardRef, Icon } from '@chakra-ui/react';

export const HeartIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
    return (
        <Icon
            ref={ref}
            stroke="currentColor"
            fill="transparent"
            viewBox="0 0 19.744 17.329"
            {...props}
        >
            <path d="M9.872 2.229A5.709 5.709 0 0 0 5.751.508 5.276 5.276 0 0 0 .502 5.563c.02 2.873 1.6 5.191 4.345 7.565a32.239 32.239 0 0 0 5.025 3.619s2.887-1.87 4.728-3.344 4.8-4.4 4.641-7.84A5.036 5.036 0 0 0 14.148.508a6.127 6.127 0 0 0-4.276 1.721Z" />
        </Icon>
    );
});
