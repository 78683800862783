import axios from 'axios';

import { getAuthHeaders } from '@/lib/httpsAgent';

import { OptionsWithServerSideRequest } from '@/models/api';
import {
    Notification,
    PaginatedNotifications,
    UnreadNotifications
} from '@/models/api/Notifications';
import { EnvironmentService } from '@/services/EnvironmentService';

const BASE_URL = EnvironmentService.getPublicApiURL().concat('/notification');

export class NotificationsService {
    static async getNotifications(opts?: {
        page?: number | string;
        pageSize?: number | string;
        paginate?: boolean;
        language?: string;
        read?: boolean;
        popup?: boolean;
        opts?: OptionsWithServerSideRequest;
    }) {
        const headers = getAuthHeaders(opts?.opts);
        if (opts?.language) {
            headers['Accept-Language'] = opts.language;
        }
        const paginate = opts?.paginate ?? true;
        const url = new URL(BASE_URL);
        if (paginate) {
            const page = opts?.page || 1;
            const pageSize = opts?.pageSize || 10;
            url.searchParams.set('pageNo', page.toString());
            url.searchParams.set('pageSize', pageSize.toString());
        }
        if (opts?.read === false) {
            url.searchParams.set('read', `${opts.read}`);
        }
        if (opts?.popup) {
            url.searchParams.set('popup', `${opts.popup}`);
        }
        const { data } = await axios.get<PaginatedNotifications>(url.toString(), { headers });
        return data;
    }

    static async markNotificationAsRead(id: number, opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(`${BASE_URL}/read/${id}`);
        const { data } = await axios.put<Notification>(url.toString(), null, {
            headers
        });
        return data;
    }

    static async getUnreadNotificationsCount(opts?: OptionsWithServerSideRequest) {
        const headers = getAuthHeaders(opts);
        const url = new URL(`${BASE_URL}/unread`);
        const { data } = await axios.get<UnreadNotifications>(url.toString(), { headers }).catch();
        return data;
    }
}
