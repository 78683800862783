import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react';

export const Skeleton: ComponentStyleConfig = defineStyle({
    defaultProps: {
        startColor: 'blue.200',
        endColor: 'blue.100',
        variant: 'blue',
        borderRadius: 'md'
    }
});
