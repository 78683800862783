import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { ProductService } from '@/services';

type UseFavouriteButtonProps = {
    checked?: boolean;
};

export function useFavouriteButton(props: UseFavouriteButtonProps = {}) {
    const { checked } = props;

    const intl = useIntl();
    const { infoToast, errorToast } = useToast();

    const [isChecked, setIsChecked] = useState(!!checked);
    const [inProgress, setInProgress] = useState(false);

    const addProductToFavourites = useCallback(
        async (code: ProductIdProps) => {
            setInProgress(true);
            try {
                await ProductService.addToFavourites({ code: code.toString() });
                infoToast({
                    description: intl.formatMessage({ id: 'favorites.add-to-favourites.success' })
                });
                setIsChecked(true);
            } catch {
                errorToast({
                    description: intl.formatMessage({ id: 'favorites.add-to-favourites.fail' })
                });
            }
            setInProgress(false);
        },
        [errorToast, infoToast, intl]
    );

    const removeProductFromFavourites = useCallback(
        async (code: ProductIdProps) => {
            setInProgress(true);
            try {
                await ProductService.removeFromFavourites({ code: code.toString() });
                infoToast({
                    description: intl.formatMessage({
                        id: 'favorites.remove-from-favourites.success'
                    })
                });
                setIsChecked(false);
            } catch {
                errorToast({
                    description: intl.formatMessage({ id: 'favorites.remove-from-favourites.fail' })
                });
            }
            setInProgress(false);
        },
        [errorToast, infoToast, intl]
    );

    return {
        isChecked,
        setIsChecked,
        inProgress,
        setInProgress,
        addProductToFavourites,
        removeProductFromFavourites
    };
}
