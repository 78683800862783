import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    List,
    ListItem,
    Text,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';
import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';

import breakpoints from '../../../themes/betterstyle-theme/breakpoints';

export type ProductCategoryDrawerProps = {
    category: ProductCategoryProps;
    categoryClose: () => void;
};

export const ProductCategoryDrawer = (props: ProductCategoryDrawerProps) => {
    const { category, categoryClose } = props;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop] = useState(false);

    useEffect(() => {
        if (category) {
            onOpen();
        }
    }, [category, onOpen]);

    useEffect(() => {
        setDesktop(() => isDesktop);
    }, [isDesktop]);

    const categoryCloseHandler = useCallback(() => {
        categoryClose();
    }, [categoryClose]);

    const buildCategoryTree = useCallback((category: ProductCategoryProps) => {
        const categoryListItems = category?.subs?.map((entry, index) => {
            const subCategories = entry.subs?.length ? buildCategoryTree(entry) : null;
            return (
                <ListItem
                    key={index}
                    data-depth={entry.level}
                    py="6px"
                    borderTopWidth={1}
                    borderTopColor="gray.200"
                >
                    <NavLink
                        bgColor="transparent"
                        href={`${RoutePath.Products}/${entry.slug}`}
                        display="block"
                        variant="buttonFade"
                        _hover={{
                            bgColor: 'gray.100'
                        }}
                    >
                        {entry.name}
                    </NavLink>
                    {subCategories}
                </ListItem>
            );
        });
        return (
            <List data-depth={category.level} pl={category.level * 4}>
                {categoryListItems}
            </List>
        );
    }, []);

    const categoryItems = buildCategoryTree(category);

    return (
        <Drawer
            isOpen={isOpen && !desktop}
            onClose={onClose}
            size="full"
            onCloseComplete={categoryCloseHandler}
        >
            <DrawerContent>
                <DrawerBody py={6} px={2}>
                    <Box mb={4}>
                        <Button variant="link" onClick={onClose} color="inherit">
                            <ChevronLeftIcon />
                            <FormattedMessage id="back" />
                        </Button>
                        <Text fontSize="h3" fontWeight="semibold" textAlign="center" mb={4}>
                            {category?.name}
                        </Text>
                        <Box>
                            <NavLink
                                href={category?.slug}
                                display="block"
                                textAlign="center"
                                fontWeight="semibold"
                                py={1}
                                variant="buttonFade"
                                mx="auto"
                                borderRadius={28}
                                fontSize={14}
                            >
                                <FormattedMessage id="show-all-in" /> {category?.name}
                            </NavLink>
                        </Box>
                    </Box>
                    {categoryItems}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
