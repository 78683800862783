import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export type PlusIconProps = IconProps;

export const PlusIcon = (props: PlusIconProps) => {
    return (
        <Icon viewBox="0 0 9.3 9.3" {...props}>
            <path d="M4.001 8.65V5.3H.651A.65.65 0 0 1 0 4.649a.65.65 0 0 1 .651-.65h3.35V.649A.65.65 0 0 1 4.65 0a.649.649 0 0 1 .649.649v3.35H8.65a.65.65 0 0 1 .649.65.65.65 0 0 1-.649.651H5.299v3.35a.649.649 0 0 1-.649.649.65.65 0 0 1-.649-.649Z" />
        </Icon>
    );
};
