const sizes = {
    container: {
        sm: '40rem',
        md: '48rem',
        lg: '64rem',
        xl: '75rem',
        '2xl': '90rem',
        '3xl': '112.5rem'
    }
};

export default sizes;
