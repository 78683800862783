import { ComponentStyleConfig } from '@chakra-ui/react';

export const Link: ComponentStyleConfig = {
    variants: {
        boldedBlue: {
            color: 'blue.main',
            fontWeight: 'semibold',
            width: 'max-content'
        },
        buttonFade: {
            bg: 'gray.200',
            px: '4',
            py: '3',
            borderRadius: 10,
            _hover: {
                bg: 'gray.300',
                textDecoration: 'none'
            }
        },
        buttonBlue: {
            bg: 'blue.500',
            color: 'white',
            rounded: 'full',
            px: '6',
            py: '3',
            _hover: {
                bg: 'blue.700',
                textDecoration: 'none'
            }
        },
        buttonOutline: {
            fontWeight: 'semibold',
            bg: 'white',
            color: 'blue.main',
            rounded: 'full',
            px: '6',
            py: '3',
            borderWidth: '1px',
            borderColor: 'blue.main',
            _hover: {
                bg: 'blue.100',
                textDecoration: 'none'
            }
        }
    }
};
