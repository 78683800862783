import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const BasketIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 26.313 24.556" {...props}>
            <path
                d="M 0.65039062,0 A 0.64999998,0.64999998 0 0 0 0,0.65039062 0.64999998,0.64999998 0 0 0 0.65039062,1.3007812 H 4.53125 L 7.8378906,17.632812 a 0.65006498,0.65006498 0 0 0 0.6367188,0.521485 H 22.652344 a 0.64999998,0.64999998 0 0 0 0.65039,-0.650391 0.64999998,0.64999998 0 0 0 -0.65039,-0.65039 H 9.0058594 L 5.6992187,0.52148437 A 0.65006498,0.65006498 0 0 0 5.0625,0 Z"
                id="basket-part"
            />
            <path
                d="M 5.7324219,3.0097656 A 0.64999998,0.64999998 0 0 0 5.0820312,3.6601563 0.64999998,0.64999998 0 0 0 5.7324219,4.3105469 H 24.859375 L 23.253906,11.826172 7.8027344,13.515625 a 0.64999998,0.64999998 0 0 0 -0.5761719,0.716797 0.64999998,0.64999998 0 0 0 0.7167969,0.574219 L 23.861328,13.068359 a 0.65006498,0.65006498 0 0 0 0.564453,-0.509765 L 26.298828,3.7949219 A 0.65006498,0.65006498 0 0 0 25.662109,3.0097656 Z"
                id="basket-box"
            />
            <path
                d="m 11.193359,19.556641 c -1.3730111,0 -2.4999996,1.126988 -2.4999996,2.5 0,1.373011 1.1269885,2.5 2.4999996,2.5 1.373012,0 2.5,-1.126989 2.5,-2.5 0,-1.373012 -1.126988,-2.5 -2.5,-2.5 z m 0,1.298828 c 0.67044,0 1.199219,0.530732 1.199219,1.201172 0,0.67044 -0.528779,1.199218 -1.199219,1.199218 -0.67044,0 -1.2011715,-0.528778 -1.2011715,-1.199218 0,-0.67044 0.5307315,-1.201172 1.2011715,-1.201172 z"
                id="circle7"
            />
            <path
                d="m 20.193359,19.556641 c -1.373011,0 -2.5,1.126988 -2.5,2.5 0,1.373011 1.126989,2.5 2.5,2.5 1.373012,0 2.5,-1.126989 2.5,-2.5 0,-1.373012 -1.126988,-2.5 -2.5,-2.5 z m 0,1.298828 c 0.67044,0 1.199219,0.530732 1.199219,1.201172 0,0.67044 -0.528779,1.199218 -1.199219,1.199218 -0.67044,0 -1.201172,-0.528778 -1.201172,-1.199218 0,-0.67044 0.530732,-1.201172 1.201172,-1.201172 z"
                id="basket-wheel-right"
            />
        </Icon>
    );
};
