import { Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCountdown } from 'usehooks-ts';

import { TimeFormatterUtil } from '@/utils/TimeFormatter/TimeFormatterUtil';

function BasketTime() {
    const { expireTime, cartItems, clearCart, cartQuantity, fetchingData } = useShoppingCart();
    const [willExpire, setWillExpire] = useState<boolean>(false);
    const [timerFire, setTimerFire] = useState<boolean>(false);
    const [timeSeconds, setTimeSeconds] = useState<number>(0);
    const [timeCounter, setTimeCounter] = useState<number>(0);
    const [deleteBasket, setDeleteBasket] = useState<boolean>(false);

    const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
        countStart: timeSeconds,
        intervalMs: 1000
    });
    const obj = useMemo(
        () => ({
            expireTime,
            cartItems,
            cartQuantity,
            clearCart,
            startCountdown,
            stopCountdown,
            resetCountdown,
            fetchingData
        }),
        [
            expireTime,
            cartItems,
            cartQuantity,
            clearCart,
            startCountdown,
            stopCountdown,
            resetCountdown,
            fetchingData
        ]
    );

    const getTimeDiffInSeconds = useCallback((expireTime: string) => {
        const dateNow = new Date();
        const utcDate = new Date(expireTime);
        const offset = new Date().getTimezoneOffset();
        const expireDate = new Date(utcDate.getTime() - offset * 60 * 1000);

        return Math.floor((expireDate.getTime() - dateNow.getTime()) / 1000);
    }, []);

    /**
     * Getting real time diff in seconds
     */
    useEffect(() => {
        const diff = getTimeDiffInSeconds(obj.expireTime);

        setTimeSeconds(() => diff);
        setTimerFire(() => false);
    }, [obj.expireTime, getTimeDiffInSeconds]);

    /**
     * Start timer
     */
    useEffect(() => {
        if (timeSeconds > 0 && !timerFire) {
            setDeleteBasket(false);
            setTimerFire(true);
            obj.resetCountdown();
            obj.startCountdown();
        }
    }, [timeSeconds, timerFire, obj]);

    /**
     * Stop timer &  highlight last 2 minutes
     */
    useEffect(() => {
        setWillExpire(count < 120);
        setTimeCounter(() => count);

        if (count === 0) {
            setDeleteBasket(true);
        }
    }, [count]);

    /**
     * Reset timer & clear baset if timer is expired
     */
    useEffect(() => {
        if (deleteBasket && timerFire) {
            obj.resetCountdown();
            setTimerFire(false);
            setDeleteBasket(false);
            obj.cartItems.length > 0 && obj.clearCart();
            setTimeSeconds(0);
        }
    }, [deleteBasket, timerFire]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {timerFire && cartQuantity > 0 && (
                <Text
                    as="span"
                    display={obj.fetchingData && cartQuantity === 1 ? 'none' : 'inline'}
                    color={willExpire ? 'red' : 'inherit'}
                >
                    {TimeFormatterUtil.convertToHMS(timeCounter)}
                </Text>
            )}
        </>
    );
}

export default BasketTime;
