import { CountryCodeValue } from '@/constants/country-codes';

type InputMaskFields = 'bankAccountNumber' | 'taxNumber' | 'zipCode';

const inputMasks: Record<CountryCodeValue, Record<InputMaskFields, string | string[] | null>> = {
    PL: {
        bankAccountNumber: '99 9999 9999 9999 9999 9999 9999',
        taxNumber: '999-999-99-99',
        zipCode: '99-999'
    },
    DE: {
        bankAccountNumber: '99 9999 9999 9999 9999 99',
        taxNumber: null,
        zipCode: '99999'
    }
};

export default inputMasks;
