import { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
    baseStyle: {
        field: {
            borderRadius: 21
        }
    },
    variants: {
        outline: {
            field: {
                borderRadius: 21
            }
        }
    }
};
