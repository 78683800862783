import { Flex, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { AppService } from '@/services';

type MyMoneyProps = {
    currencySymbol?: string | null;
    label: string;
    money?: number | null;
};

export const MoneyBox = ({ money, label }: MyMoneyProps) => {
    const currency = AppService.getInstanceCurrency();

    return (
        <Flex
            flexWrap="wrap"
            columnGap={2}
            justifyContent={{
                sm: 'center'
            }}
            textAlign="center"
        >
            <Text>{label} /</Text>
            <Flex>
                <Text fontWeight="600" mr={2}>
                    <FormattedPrice value={money || 0} currency={currency} />
                </Text>
                <Text color="#666C75" fontStyle="italic">
                    <FormattedMessage id="gross-with-vat" />
                </Text>
            </Flex>
        </Flex>
    );
};
