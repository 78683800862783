import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    DrawerBody,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    Flex,
    GridItem,
    SimpleGrid,
    Text
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classes from './shoppingCart.module.scss';

import BasketTimeCheckout from '@/components/BasketTime/BasketTimeCheckout';
import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { H4, H5 } from '@/components/Heading';
import { DownIcon, UpIcon } from '@/components/Icons';
import { AlertCartDisable } from '@/components/ShoppingCart/AlertCartDisable';
import FetchingProductsSpinner from '@/components/ShoppingCart/FetchingProductsSpinner';
import { ProductItem } from '@/components/ShoppingCart/ProductItem';
import NavLink from '@/components/UI/Links/NavLink';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { Product } from '@/models/api';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { DisabledReason, GlobalProductProps } from '@/models/props/ProductCardProps';
import { ProductListProps } from '@/models/props/ProductListProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { AppService } from '@/services';
import { ProductService } from '@/services/ProductService';

function ShoppingCart() {
    const { cartItems, currentBasket, fetchingData } = useShoppingCart();
    const currency = AppService.getInstanceCurrency();
    const intl = useIntl();
    const [products, setProducts] = useState<Product[]>([]);
    const [globalSettings] = useState<GlobalProductProps>();
    const [totalGrossCost, setTotalGrossCost] = useState<number>(0);
    const { controls } = currentBasket || {};
    const { disabledReason } = controls || {};
    const obj = useMemo(
        () => ({
            intl,
            products,
            globalSettings,
            currentBasket
        }),
        [intl, products, globalSettings, currentBasket]
    );

    const basketIsDisabled = disabledReason === DisabledReason.PendingTransferPayment;

    const getProducts = useCallback(async () => {
        if (cartItems.length) {
            const codes: ProductIdProps[] = cartItems.map((item) => item.code);
            const list: ProductListProps = await ProductService.getProductsList({
                productCodes: codes,
                pageSize: BasketConfig.pageSize,
                locale: obj.intl.locale as LocaleProps
            });

            setProducts(list.items);
        }
    }, [cartItems, obj]);

    useEffect(() => {
        if (cartItems) getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems]);

    useEffect(() => {
        const summary = currentBasket?.summary;
        if (summary) {
            const { totalGrossCost = '0', discountUsed = '0', refundMoneyUsed = '0' } = summary;
            const total =
                parseFloat(totalGrossCost.replace(',', '.')) +
                parseFloat(discountUsed.replace(',', '.')) +
                parseFloat(refundMoneyUsed.replace(',', '.'));

            setTotalGrossCost(total);
        }
    }, [currentBasket]);

    const displayItems = useMemo(
        () =>
            obj.products.map((item, index) => {
                const basketItem =
                    obj.currentBasket &&
                    obj.currentBasket.products.find((i) => i.code === item.code);

                if (index === cartItems.length - 1 && basketItem) {
                    return (
                        <Box key={index}>
                            <ProductItem key={item.code} product={item} basketItem={basketItem} />
                        </Box>
                    );
                }

                return (
                    basketItem && (
                        <Box key={index}>
                            <ProductItem key={item.code} product={item} basketItem={basketItem} />
                            <Box display="block" w="100%" my={5} h="1px" bgColor="blue.100" />
                        </Box>
                    )
                );
            }),
        [cartItems, obj]
    );

    return (
        <Flex
            flexDirection="column"
            overflow={{
                base: 'auto',
                lg: 'hidden'
            }}
            flex={1}
        >
            <DrawerHeader fontSize="base" p={0}>
                <Box minH={20} px={{ base: 4, md: 6 }} pt={5} pb={2}>
                    <Flex alignItems="center" justifyContent="space-between" mb={4} w="100%">
                        <H4 mb={0}>
                            <FormattedMessage id="basket" />
                        </H4>
                        <DrawerCloseButton
                            border="1px"
                            borderColor="blue.100"
                            rounded="full"
                            h="9"
                            w={9}
                            pos="static"
                        />
                    </Flex>
                    {!!cartItems.length && !basketIsDisabled && <BasketTimeCheckout />}
                    <AlertCartDisable disableReason={controls?.disabledReason} />
                </Box>
            </DrawerHeader>

            <DrawerBody
                px={0}
                pt={6}
                pb={{ base: 0, md: 6 }}
                overflow="hidden"
                pos="relative"
                flexShrink={{
                    base: 0,
                    lg: 1
                }}
                flexBasis="auto"
                pr={3}
            >
                <Box
                    className={classes.shoppingCartBody}
                    h="100%"
                    overflow="auto"
                    pb={{ base: 2, md: 10 }}
                >
                    <Box
                        h="100%"
                        pl={{ base: 4, md: 6 }}
                        pr={3}
                        pt={1}
                        pointerEvents={fetchingData ? 'none' : 'auto'}
                    >
                        {cartItems.length ? (
                            displayItems
                        ) : (
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                rounded="18px"
                                borderWidth="1px"
                                borderColor="blue.100"
                                h="100%"
                                textAlign="center"
                            >
                                <Text textColor="grey.600">
                                    <FormattedMessage id="basket-empty" />
                                </Text>
                            </Flex>
                        )}
                    </Box>
                </Box>
                {fetchingData && <FetchingProductsSpinner />}
            </DrawerBody>

            {!!currentBasket?.products.length && !!cartItems.length && (
                <DrawerFooter display="block" px={{ base: 4, md: 6 }}>
                    <Box
                        border="1px"
                        borderColor="blue.100"
                        borderRadius="21px"
                        overflow="hidden"
                        py={{ base: 4, md: 6 }}
                        px={{ base: 4, md: 7 }}
                    >
                        <Accordion pb={5} defaultIndex={[0]} allowMultiple>
                            <AccordionItem border="none">
                                {({ isExpanded }) => (
                                    <>
                                        <H5 mb={0}>
                                            <AccordionButton
                                                rounded="xl"
                                                px={0}
                                                pt={0}
                                                _hover={{
                                                    bg: 'none',
                                                    textDecoration: 'underline'
                                                }}
                                            >
                                                <Flex
                                                    as="span"
                                                    alignItems="center"
                                                    flex="1"
                                                    textAlign="left"
                                                    fontWeight="semibold"
                                                >
                                                    <Box as="span">
                                                        <FormattedMessage id="details" />
                                                    </Box>
                                                    <Flex
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        ml={5}
                                                        border="1px"
                                                        borderColor="blue.100"
                                                        h={6}
                                                        w={6}
                                                        rounded="full"
                                                    >
                                                        {isExpanded ? (
                                                            <DownIcon w="10px" />
                                                        ) : (
                                                            <UpIcon w="10px" />
                                                        )}
                                                    </Flex>
                                                </Flex>
                                            </AccordionButton>
                                        </H5>
                                        <AccordionPanel p={0}>
                                            <SimpleGrid columns={2} w="100%" py={1}>
                                                <GridItem>
                                                    <Text color="grey.600">
                                                        <FormattedMessage id="order.total-value.points" />
                                                        :
                                                    </Text>
                                                </GridItem>
                                                {currentBasket.summary && (
                                                    <GridItem textAlign="end">
                                                        <Text>
                                                            {intl.formatMessage(
                                                                { id: 'points' },
                                                                {
                                                                    count: currentBasket.summary
                                                                        .points
                                                                }
                                                            )}
                                                        </Text>
                                                    </GridItem>
                                                )}
                                            </SimpleGrid>
                                            <SimpleGrid columns={2} w="100%" py={1}>
                                                <GridItem>
                                                    <Text color="grey.600">
                                                        <FormattedMessage id="order.total-value.netto" />
                                                        :
                                                    </Text>
                                                </GridItem>
                                                {currentBasket.summary && (
                                                    <GridItem textAlign="end">
                                                        <Text>
                                                            <FormattedPrice
                                                                value={
                                                                    +currentBasket.summary.netVolume.replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                                }
                                                                currency={currency}
                                                            />
                                                        </Text>
                                                    </GridItem>
                                                )}
                                            </SimpleGrid>
                                            <SimpleGrid columns={2} w="100%" py={1}>
                                                <GridItem>
                                                    <Text color="grey.600">
                                                        <FormattedMessage id="order.total-value.brutto" />
                                                        :
                                                    </Text>
                                                </GridItem>
                                                {currentBasket.summary && (
                                                    <GridItem textAlign="end">
                                                        <Text>
                                                            <FormattedPrice
                                                                value={
                                                                    +currentBasket.summary.grossCost.replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                                }
                                                                currency={currency}
                                                            />
                                                        </Text>
                                                    </GridItem>
                                                )}
                                            </SimpleGrid>
                                            <SimpleGrid columns={2} w="100%" py={1}>
                                                <GridItem>
                                                    <Text color="grey.600">
                                                        <FormattedMessage id="order.presents-value.brutto" />
                                                        :
                                                    </Text>
                                                </GridItem>
                                                {currentBasket.summary && (
                                                    <GridItem textAlign="end">
                                                        <Text>
                                                            <FormattedPrice
                                                                value={
                                                                    +currentBasket.summary.presentsCost.replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                                }
                                                                currency={currency}
                                                            />
                                                        </Text>
                                                    </GridItem>
                                                )}
                                            </SimpleGrid>
                                            {currentBasket?.summary?.shippingProductCost && (
                                                <SimpleGrid columns={2} w="100%" py={1}>
                                                    <GridItem>
                                                        <Text color="grey.600">
                                                            <FormattedMessage id="order.shipping-product-value.brutto" />
                                                            :
                                                        </Text>
                                                    </GridItem>
                                                    {currentBasket.summary && (
                                                        <GridItem textAlign="end">
                                                            <Text>
                                                                <FormattedPrice
                                                                    value={
                                                                        +currentBasket.summary.shippingProductCost.replace(
                                                                            ',',
                                                                            '.'
                                                                        )
                                                                    }
                                                                    currency={currency}
                                                                />
                                                            </Text>
                                                        </GridItem>
                                                    )}
                                                </SimpleGrid>
                                            )}

                                            <SimpleGrid columns={2} w="100%" py={1}>
                                                <GridItem>
                                                    <Text color="grey.600">
                                                        <FormattedMessage id="shipping.title" />:
                                                    </Text>
                                                </GridItem>
                                                {currentBasket.summary && (
                                                    <GridItem textAlign="end">
                                                        <Text>
                                                            <FormattedPrice
                                                                value={
                                                                    +currentBasket.summary.shippingCost.replace(
                                                                        ',',
                                                                        '.'
                                                                    )
                                                                }
                                                                currency={currency}
                                                            />
                                                        </Text>
                                                    </GridItem>
                                                )}
                                            </SimpleGrid>
                                        </AccordionPanel>
                                    </>
                                )}
                            </AccordionItem>
                        </Accordion>
                        <Flex
                            pt={3}
                            alignItems="center"
                            flexWrap={{
                                base: 'wrap',
                                sm: 'nowrap',
                                xl: 'wrap'
                            }}
                            flexBasis="100%"
                            gap={4}
                        >
                            <Flex
                                alignItems={{
                                    md: 'center'
                                }}
                                fontWeight="bold"
                                gap={4}
                                ml="auto"
                                justifyContent="space-between"
                                flexGrow={{
                                    base: 1,
                                    sm: 0,
                                    xl: 1
                                }}
                            >
                                <Text>
                                    <FormattedMessage id="order.total-value.brutto" />:
                                </Text>
                                {!!currentBasket.products.length && currentBasket.summary && (
                                    <Text fontSize={{ base: 'lg', md: '27px' }} whiteSpace="nowrap">
                                        <FormattedPrice
                                            value={totalGrossCost}
                                            currency={currency}
                                        />
                                    </Text>
                                )}
                            </Flex>
                            <NavLink
                                href={RoutePath.Checkout}
                                variant="buttonBlue"
                                fontWeight="semibold"
                                textAlign="center"
                                rounded="full"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minH="52px"
                                order={{
                                    sm: -1,
                                    xl: 'unset'
                                }}
                                w={{
                                    base: 'full',
                                    sm: 'auto',
                                    xl: 'full'
                                }}
                            >
                                <FormattedMessage id="go-to-checkout" />
                            </NavLink>
                        </Flex>
                    </Box>
                </DrawerFooter>
            )}
        </Flex>
    );
}

export default ShoppingCart;
