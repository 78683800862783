import {
    Box,
    DrawerBody,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    Flex,
    Text
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import classes from './shoppingCart.module.scss';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { H4 } from '@/components/Heading';
import FetchingProductsSpinner from '@/components/ShoppingCart/FetchingProductsSpinner';
import { ProductItemUnlogged } from '@/components/ShoppingCart/ProductItemUnlogged';
import NavLink from '@/components/UI/Links/NavLink';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { Product } from '@/models/api';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { GlobalProductProps } from '@/models/props/ProductCardProps';
import { ProductListProps } from '@/models/props/ProductListProps';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';
import { AppService } from '@/services';
import { ProductService } from '@/services/ProductService';

function ShoppingCart() {
    const { cartItemsUnlogged, fetchingData, basketUnlogged } = useShoppingCart();
    const currency = AppService.getInstanceCurrency();
    const intl = useIntl();
    const [products, setProducts] = useState<Product[]>([]);
    const [globalSettings] = useState<GlobalProductProps>();
    const obj = useMemo(
        () => ({
            intl,
            products,
            globalSettings
        }),
        [intl, products, globalSettings]
    );

    const getProducts = useCallback(async () => {
        if (cartItemsUnlogged.length) {
            const codes: ProductIdProps[] = cartItemsUnlogged.map((item) => item.code);
            const list: ProductListProps = await ProductService.getProductsList({
                productCodes: codes,
                pageSize: BasketConfig.pageSize,
                locale: obj.intl.locale as LocaleProps
            });

            setProducts(list.items);
        }
    }, [cartItemsUnlogged, obj]);

    useEffect(() => {
        if (cartItemsUnlogged) getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItemsUnlogged]);

    const displayItems = useMemo(
        () =>
            obj.products.map((product, index) => {
                return (
                    <Box key={index}>
                        <ProductItemUnlogged
                            key={product.code}
                            product={product}
                            basket={cartItemsUnlogged}
                        />
                        <Box display="block" w="100%" my={5} h="1px" bgColor="blue.100" />
                    </Box>
                );
            }),
        [cartItemsUnlogged, obj]
    );

    return (
        <Flex
            flexDirection="column"
            overflow={{
                base: 'auto',
                lg: 'hidden'
            }}
            flex={1}
        >
            <DrawerHeader fontSize="base" p={0}>
                <Box minH={20} px={{ base: 4, md: 6 }} pt={5} pb={2}>
                    <Flex alignItems="center" justifyContent="space-between" mb={4} w="100%">
                        <H4 mb={0}>
                            <FormattedMessage id="basket" />
                        </H4>
                        <DrawerCloseButton
                            border="1px"
                            borderColor="blue.100"
                            rounded="full"
                            h="9"
                            w={9}
                            pos="static"
                        />
                    </Flex>
                </Box>
            </DrawerHeader>

            <DrawerBody
                px={0}
                pt={6}
                pb={{ base: 0, md: 6 }}
                overflow="hidden"
                pos="relative"
                flexShrink={{
                    base: 0,
                    lg: 1
                }}
                flexBasis="auto"
                pr={3}
            >
                <Box
                    className={classes.shoppingCartBody}
                    h="100%"
                    overflow="auto"
                    pb={{ base: 2, md: 10 }}
                >
                    <Box
                        h="100%"
                        pl={{ base: 4, md: 6 }}
                        pr={3}
                        pt={1}
                        pointerEvents={fetchingData ? 'none' : 'auto'}
                    >
                        {cartItemsUnlogged.length ? (
                            displayItems
                        ) : (
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                rounded="18px"
                                borderWidth="1px"
                                borderColor="blue.100"
                                h="100%"
                                textAlign="center"
                            >
                                <Text textColor="grey.600">
                                    <FormattedMessage id="basket-empty" />
                                </Text>
                            </Flex>
                        )}
                    </Box>
                </Box>
                {fetchingData && <FetchingProductsSpinner />}
            </DrawerBody>

            {!!cartItemsUnlogged.length && (
                <DrawerFooter display="block" px={{ base: 4, md: 6 }}>
                    <Box
                        border="1px"
                        borderColor="blue.100"
                        borderRadius="21px"
                        overflow="hidden"
                        py={{ base: 4, md: 6 }}
                        px={{ base: 4, md: 7 }}
                    >
                        <Flex
                            pt={3}
                            alignItems="center"
                            flexWrap={{
                                base: 'wrap',
                                sm: 'nowrap',
                                xl: 'wrap'
                            }}
                            flexBasis="100%"
                            gap={4}
                        >
                            <Flex
                                alignItems={{
                                    md: 'center'
                                }}
                                fontWeight="bold"
                                gap={4}
                                ml="auto"
                                justifyContent="space-between"
                                flexGrow={{
                                    base: 1,
                                    sm: 0,
                                    xl: 1
                                }}
                            >
                                <Text>
                                    <FormattedMessage id="order.total-value.brutto" />:
                                </Text>
                                {!!cartItemsUnlogged.length && basketUnlogged && (
                                    <Text fontSize={{ base: 'lg', md: '27px' }} whiteSpace="nowrap">
                                        <FormattedPrice
                                            value={basketUnlogged.total}
                                            currency={currency}
                                        />
                                    </Text>
                                )}
                            </Flex>
                            <NavLink
                                href={RoutePath.Checkout}
                                variant="buttonBlue"
                                fontWeight="semibold"
                                textAlign="center"
                                rounded="full"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                minH="52px"
                                order={{
                                    sm: -1,
                                    xl: 'unset'
                                }}
                                w={{
                                    base: 'full',
                                    sm: 'auto',
                                    xl: 'full'
                                }}
                            >
                                <FormattedMessage id="go-to-checkout" />
                            </NavLink>
                        </Flex>
                    </Box>
                </DrawerFooter>
            )}
        </Flex>
    );
}

export default ShoppingCart;
