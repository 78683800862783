import { IconProps } from '@chakra-ui/icons';
import { Icon, useToken } from '@chakra-ui/react';
import { useMemo } from 'react';

type UserProgressLevelIconProps = {
    level?: number;
    lastLevelIndicator?: boolean;
    iconProps?: IconProps;
    userLevel?: number;
};

export const UserLevelStandardIcon = (props: UserProgressLevelIconProps) => {
    const [grey300, grey400, grey450, goldDark, goldLight] = useToken('colors', [
        'grey.300',
        'grey.400',
        'grey.450',
        'myProgress.gold.dark',
        'myProgress.gold.light'
    ]);

    const { iconProps, lastLevelIndicator } = props;
    const level = props.level || 0;
    const userLevel = props.userLevel ?? (props.level as number);

    const levelActive = level <= userLevel;

    const defaultStarColor = 'url(#default-color)';

    const notAchievedLevelColor = grey300;
    const inactiveColorLight = grey400;

    const achievedLevelColor = defaultStarColor;
    const inactiveColorMedium = grey450;

    const paths = useMemo(() => {
        const items = [
            {
                d: 'M 43.222656,15.876756 C 31.44516,16.813046 23.175781,24.253227 19.476562,32.234375 l 3.699219,1.273437 c 3.327962,-6.859575 10.533409,-12.9148 20.046875,-13.742187 z'
            },
            {
                d: 'm 17.855469,36.621093 c -3.165491,11.22929 0.971979,21.764977 7.617114,28.156132 l 2.507922,-2.988942 C 22.606968,56.408279 19.127037,47.22808 21.527344,37.886719 Z'
            },
            {
                d: 'm 61.822125,67.528698 c -11.245571,8.018675 -24.686654,5.957466 -32.740094,0.22097 L 31.589844,64.75 c 6.375851,4.30809 17.197743,6.775666 27.730469,-0.204409 z'
            },
            {
                d: 'm 65.405158,64.52064 c 8.066644,-8.153373 9.817547,-18.832625 7.39669,-27.755656 l -3.691535,1.273535 c 2.060841,7.706031 0.320203,16.77466 -6.214569,23.498157 z'
            },
            {
                d: 'M 71.196191,32.378165 C 66.250744,21.843458 56.54195,16.64803 47.894681,15.921025 l 0.0029,3.892471 c 7.784883,0.796005 15.672385,5.589638 19.601471,13.838335 z'
            }
        ];

        return items.map((item, index) => {
            let fill = level - 1 > index ? achievedLevelColor : notAchievedLevelColor;

            if (!levelActive) {
                fill = level - 1 > index ? inactiveColorMedium : inactiveColorLight;
            }
            return <path key={index} d={item.d} fill={fill} />;
        });
    }, [
        achievedLevelColor,
        inactiveColorLight,
        inactiveColorMedium,
        level,
        levelActive,
        notAchievedLevelColor
    ]);

    return (
        <Icon viewBox="0 0 90.611 105.919" height="auto" {...iconProps}>
            <linearGradient
                id="default-color"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stopColor="#af8539" />
                <stop offset="0.419" stopColor="#fdcf5c" />
                <stop offset="1" stopColor="#a87a28" />
            </linearGradient>
            <g id="g2617">
                <path
                    id="Path_2219"
                    d="M 59.57,54.312001 40.786596,105.91898 31.860182,88.241908 13.29025,95.911128 32.073655,44.304149 Z"
                    fill={levelActive ? goldDark : inactiveColorMedium}
                />
                <path
                    id="Path_2220"
                    d="M 31.037715,54.312194 49.821119,105.91917 58.748473,88.241759 77.318404,95.910979 58.535,44.304 Z"
                    fill={levelActive ? goldLight : inactiveColorLight}
                />
                <circle
                    id="Ellipse_159"
                    cx="45.305"
                    cy="45.305"
                    r="45.305"
                    fill={levelActive ? goldLight : inactiveColorLight}
                />
                <circle id="Ellipse_158" cx="45.305" cy="45.305" r="42.305" fill="#ffffff" />
                <path
                    fill={levelActive ? defaultStarColor : inactiveColorMedium}
                    id="star-big"
                    d="m 47.22,52.699 c -0.478,-1.865 -1.445,-7.96 -2.906,-9.422 -1.461,-1.462 -7.556,-2.429 -9.423,-2.906 1.867,-0.478 7.962,-1.444 9.423,-2.906 1.461,-1.462 2.428,-7.555 2.906,-9.422 0.476,1.867 1.444,7.962 2.906,9.422 1.462,1.46 7.556,2.429 9.421,2.906 -1.865,0.477 -7.96,1.445 -9.421,2.906 -1.461,1.461 -2.43,7.556 -2.906,9.422"
                />
                <path
                    fill={levelActive ? defaultStarColor : inactiveColorMedium}
                    id="Path_780"
                    d="m 37.133,60.57 c -0.3,-1.193 -0.922,-5.081 -1.853,-6.014 -0.931,-0.933 -4.823,-1.551 -6.015,-1.856 1.192,-0.3 5.081,-0.921 6.015,-1.853 0.934,-0.932 1.549,-4.824 1.853,-6.014 0.3,1.19 0.922,5.08 1.854,6.014 0.932,0.934 4.823,1.549 6.015,1.853 -1.192,0.3 -5.081,0.922 -6.015,1.856 -0.934,0.934 -1.55,4.814 -1.854,6.014"
                />
                <path
                    fill={levelActive ? defaultStarColor : inactiveColorMedium}
                    id="star-small"
                    d="M 55.063,52.748 C 54.89,52.067 54.536,49.84 54.003,49.305 53.47,48.77 51.24,48.417 50.557,48.24 c 0.683,-0.172 2.91,-0.527 3.446,-1.061 0.536,-0.534 0.888,-2.762 1.06,-3.444 0.174,0.682 0.529,2.911 1.062,3.444 0.533,0.533 2.761,0.888 3.444,1.061 -0.683,0.176 -2.91,0.529 -3.444,1.065 -0.534,0.536 -0.888,2.762 -1.062,3.443"
                />
            </g>
            {lastLevelIndicator && (
                <g id="g1529">
                    <path
                        id="Path_2237"
                        data-name="Path 2237"
                        d="m 44.42666,75.30162 a 9.41,9.41 0 0 1 9.592,9.877 c -0.172,5.714 -4.184,9.769 -9.592,9.769 a 9.724,9.724 0 0 1 -9.992,-9.518 10.244,10.244 0 0 1 9.992,-10.128 z"
                        fill="#ffffff"
                    />
                    <circle
                        id="Ellipse_184"
                        data-name="Ellipse 184"
                        cx="-20.738707"
                        cy="-93.788864"
                        r="3.0003555"
                        transform="rotate(164.99109)"
                        fill={levelActive ? goldLight : grey450}
                    />
                </g>
            )}
            {...paths}
        </Icon>
    );
};
