import { ValidationKey } from '@/constants/validation-key';
import {
    CountryInstanceValidators,
    ValidationReturn
} from '@/validators/countryInstanceValidators/CountryInstanceValidators';

export class PolandInstanceValidators implements CountryInstanceValidators {
    validateBankAccountNumber(accountNumber: string): ValidationReturn {
        const pattern = new RegExp(/^([0-9]{2})\s?([0-9]{4}\s?){6}$/, 'g');
        const isValid = pattern.test(accountNumber);
        return !isValid ? ValidationKey.BankAccountNumber : undefined;
    }
    validatePhoneNumber(phoneNumber: string): ValidationReturn {
        const pattern = new RegExp(/^(\d( *)){9}$/, 'g');
        const isValid = pattern.test(phoneNumber);
        return !isValid ? ValidationKey.InvalidPhone : undefined;
    }
    validatePhoneNumberWithPrefix(phoneNumber: string): ValidationReturn {
        const pattern = new RegExp(/^(\+48)( )*(\d( *)){9}$/, 'g');
        const isValid = pattern.test(phoneNumber);
        return !isValid ? ValidationKey.InvalidPhone : undefined;
    }

    validateTaxIdentificationNumber(taxNumber: string): ValidationReturn {
        const pattern = new RegExp(/^\d{3}-\d{3}-\d{2}-\d{2}$/, 'g');
        const isValid = pattern.test(taxNumber);
        return !isValid ? ValidationKey.InvalidTaxNumber : undefined;
    }
    validateInternationalTaxIdentificationNumber(taxNumber?: string): ValidationReturn {
        if (!taxNumber) {
            return undefined;
        }
        const pattern = new RegExp(/^([pP][lL])\d{10}$/, 'g');
        const isValid = pattern.test(taxNumber);
        return !isValid ? ValidationKey.InvalidInternationalTaxNumber : undefined;
    }
    validateZipCode(zipCode: string): ValidationReturn {
        const pattern = new RegExp(/^\d{2}-\d{3}$/, 'g');
        const isValid = pattern.test(zipCode);
        return !isValid ? ValidationKey.InvalidZipCode : undefined;
    }
}
