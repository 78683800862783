import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useState } from 'react';

export type ShowPopupMessageOpts = {
    message: string;
    title?: string;
    onClose?: () => void;
};

export function useMessageModal() {
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [popupMessage, setPopupMessage] = useState('');

    const [popupTitle, setPopupTitle] = useState<string | undefined>();

    const [popupCloseCallback, setPopupCloseCallback] = useState<(() => void) | null>();

    const showPopupMessage = useCallback(
        (opts: ShowPopupMessageOpts) => {
            const { message, onClose, title } = opts;
            setPopupMessage(message);
            setPopupTitle(title);
            setPopupCloseCallback(() => onClose);
            onOpen();
        },
        [onOpen]
    );

    const hidePopupMessage = useCallback(() => {
        setPopupTitle('');
        setPopupMessage('');
        onClose();
        popupCloseCallback?.();
        setPopupCloseCallback(null);
    }, [onClose, popupCloseCallback]);

    return {
        isOpen,
        onClose,
        onOpen,
        showPopupMessage,
        hidePopupMessage,
        popupMessage,
        popupTitle
    };
}
