import axios, { RawAxiosRequestHeaders } from 'axios';
import https from 'https';
import { User } from 'next-auth';

import { OptionsWithServerSideRequest } from '@/models/api';

function configureAxios() {
    const agent = new https.Agent({
        rejectUnauthorized: false
    });

    axios.defaults.httpsAgent = agent;
    axios.defaults.withCredentials = true;
}

export function getAuthHeaders(options?: OptionsWithServerSideRequest): RawAxiosRequestHeaders {
    if (!options?.req && !options?.session) {
        return {};
    }
    const headers: RawAxiosRequestHeaders = {};
    if (options?.session) {
        const user = options.session.user as User & { sessionCookie: string };
        headers.cookie = user?.sessionCookie;
    } else if (options?.req) {
        headers.cookie = options.req.headers.cookie;
    }
    return headers;
}

export const httpsAgent = {
    configureAxios,
    getAuthHeaders
};
