import { ListItem, ListProps, UnorderedList } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { CategoriesMenu } from '@/components/CategoriesMenu/CategoriesMenu';
import NavLink from '@/components/UI/Links/NavLink';

import { CurrentOfferProps } from '@/models/props/CurrentOfferProps';

export const CurrentOffer = (props: CurrentOfferProps & ListProps) => {
    const { menu, ...rest } = props;

    const menuItems = menu.map((item, index) => (
        <ListItem key={index}>
            <NavLink href={item.href}>
                <FormattedMessage id={item.translationKey} />
            </NavLink>
        </ListItem>
    ));

    return (
        <>
            {menu.length && (
                <UnorderedList {...rest} pos="relative">
                    <CategoriesMenu />
                    {menuItems}
                </UnorderedList>
            )}
        </>
    );
};
