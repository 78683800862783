import { createContext, PropsWithChildren, ReactNode, useContext, useState } from 'react';

export type BreadcrumbsContextProps = {
    children: ReactNode;
};

export type CrumbItem = {
    href: string;
    label: string;
};

export type BreadcrumbsContext = {
    getTitle(): string;
    setTitle(title: string): void;
    hideBreadCrumbs(props: boolean): void;
    displayBreadcrumbs: boolean;
};

const BreadcrumbsContext = createContext({} as BreadcrumbsContext);

export function useBreadcrumbs() {
    return useContext(BreadcrumbsContext);
}

export function BreadCrumbsProvider(props: PropsWithChildren<BreadcrumbsContextProps>) {
    const { children } = props;
    const [lastCrumbTitle, setLastCrumbTitle] = useState<string>('');
    const [displayBreadcrumbs, setDisplayBreadcrumbs] = useState<boolean>(true);

    function getTitle(): string {
        return lastCrumbTitle;
    }

    function setTitle(title: string): void {
        setLastCrumbTitle(() => title);
    }

    function hideBreadCrumbs(props: boolean): void {
        if (props) {
            setDisplayBreadcrumbs(false);

            return;
        }

        setDisplayBreadcrumbs(true);
    }

    return (
        <BreadcrumbsContext.Provider
            value={{
                setTitle,
                getTitle,
                hideBreadCrumbs,
                displayBreadcrumbs
            }}
        >
            {children}
        </BreadcrumbsContext.Provider>
    );
}
