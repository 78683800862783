import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    stroke?: string;
};

export const TrackIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 17 12.876" {...props}>
            <path
                d="m12.01,11.14113l-0.9,0a1.033,1.033 0 0 1 -0.663,-0.263a0.985,0.985 0 0 1 -0.671,0.263l-3.208,0a2.083,2.083 0 0 1 -4.109,0l-1.417,0a1.083,1.083 0 0 1 -1.042,-1.042l0,-9.109a0.99,0.99 0 0 1 0.988,-0.988l8.334,0a1.869,1.869 0 0 1 0.857,0.277a0.982,0.982 0 0 1 0.585,0.792l0,1.456a0.961,0.961 0 0 1 0.348,-0.067l2.779,0a2.082,2.082 0 0 1 1.469,0.874l0.011,0.012l1.437,1.918a2.987,2.987 0 0 1 0.556,1.5l0,3.339a1.083,1.083 0 0 1 -1.042,1.042l-0.2,0a2.084,2.084 0 0 1 -4.109,0l-0.003,-0.004zm0.665,-0.349a1.389,1.389 0 1 0 1.388,-1.389a1.392,1.392 0 0 0 -1.388,1.389zm-9.55,0a1.389,1.389 0 1 0 1.39,-1.389a1.392,1.392 0 0 0 -1.39,1.389zm13.2,-0.346a0.392,0.392 0 0 0 0.346,-0.348l0,-2.777l-3.473,0a1.1,1.1 0 0 1 -1.042,-1.042l0,-1.39a1.069,1.069 0 0 1 1.042,-1.041l1.684,0l-0.059,-0.08a1.437,1.437 0 0 0 -0.929,-0.616l-2.779,0a0.394,0.394 0 0 0 -0.348,0.348l0,6.6a0.4,0.4 0 0 0 0.349,0.348l0.9,0a2.084,2.084 0 0 1 4.109,0l0.2,-0.002zm-6.545,0a0.3,0.3 0 0 0 0.294,-0.294l0,-9.068a0.778,0.778 0 0 0 -0.3,-0.239a1.172,1.172 0 0 0 -0.451,-0.149l-8.334,0a0.294,0.294 0 0 0 -0.294,0.292l0,9.109a0.393,0.393 0 0 0 0.348,0.348l1.417,0a2.084,2.084 0 0 1 4.109,0l3.211,0.001zm3.072,-5.557l0,1.39a0.4,0.4 0 0 0 0.346,0.346l3.464,0a2.616,2.616 0 0 0 -0.415,-0.957l-0.845,-1.127l-2.2,0a0.371,0.371 0 0 0 -0.354,0.348l0.004,0z"
                fill="#000000"
            />
        </Icon>
    );
};
