import { Box, Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { FormControlError } from '@/components/FormControlError/FormControlError';
import { Button } from '@/components/UI/Buttons/Button';
import NavLink from '@/components/UI/Links/NavLink';

import { AgreementData, CompanyData } from '@/models/api/ProfileInfo';
import { AppService } from '@/services';
import { RepresentativeService } from '@/services/RepresentativeService';

type AgreementsProps = {
    agreements: AgreementData;
    representativeService: RepresentativeService;
    company?: CompanyData;
    agreementsCallback?: (userAgreements: AgreementData | null) => void;
};

export const Agreements = (props: AgreementsProps) => {
    const { agreements, representativeService, agreementsCallback, company } = props;
    const {
        companyPolicyAccepted,
        privacyPolicyAccepted,
        rodoAccepted,
        rodoMarketingAccepted,
        sponsorAccepted
    } = agreements;
    const { formState, handleSubmit, register, setValue, getValues } = useForm<AgreementData>({
        defaultValues: Object.assign(
            {
                privacyPolicyAccepted,
                rodoAccepted,
                rodoMarketingAccepted,
                sponsorAccepted
            },
            AppService.isGermanInstance() && { companyPolicyAccepted }
        )
    });
    const { locale } = useRouter();
    const intl = useIntl();
    const { errorToast, infoToast } = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const clubRegulationsFileLink = AppService.getClubRegulationsFileLink();
    const clubPrivacyPolicyFileLink = AppService.getClubPrivacyPolicyFileLink();
    const companyClubRegulationsLink = AppService.getCompanyClubRegulationsFileLink();
    const contactEmail = AppService.getContactEmailAddress();
    const informationObligationFileLink = AppService.getInformationObligationFileLink();
    const [isAgreementRequired, setIsAgreementRequired] = useState<boolean | undefined>(undefined);
    const [checkedItems, setCheckedItems] = useState(() => {
        const defaultValues = [
            agreements.privacyPolicyAccepted,
            agreements.rodoAccepted,
            agreements.sponsorAccepted,
            agreements.rodoMarketingAccepted
        ];

        if (AppService.isGermanInstance()) {
            defaultValues.push(agreements.companyPolicyAccepted);
        }

        return defaultValues;
    });
    const obj = useMemo(
        () => ({
            intl,
            locale,
            representativeService,
            agreementsCallback
        }),
        [intl, locale, representativeService, agreementsCallback]
    );
    const privacyPolicyFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink color="brand" href={clubPrivacyPolicyFileLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [clubPrivacyPolicyFileLink]
    );
    const dataInformationObligationFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink color="brand" href={informationObligationFileLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [informationObligationFileLink]
    );
    const dataClubRulesFormattedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink color="brand" href={clubRegulationsFileLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [clubRegulationsFileLink]
    );
    const companyPolicyAcceptedMessage = useCallback(
        (messages: ReactNode[]) => {
            return (
                <NavLink color="brand" href={companyClubRegulationsLink} isExternal>
                    {messages}
                </NavLink>
            );
        },
        [companyClubRegulationsLink]
    );
    const formSubmitHandler = useCallback<SubmitHandler<AgreementData>>(
        async (data) => {
            setIsSubmitting(() => true);

            try {
                await obj.representativeService.updateAgreements(data);

                if (obj.agreementsCallback) {
                    obj.agreementsCallback(data);
                } else {
                    infoToast({
                        description: obj.intl.formatMessage({ id: 'agreements-update-success' })
                    });
                }
            } catch (error) {
                errorToast({
                    description: obj.intl.formatMessage({ id: 'agreements-update-fail' })
                });
            }
            setIsSubmitting(() => false);
        },
        [obj, errorToast, infoToast]
    );
    const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;

        setCheckedItems(Array(5).fill(isChecked));

        const requiredCheckboxes = ['privacyPolicyAccepted', 'rodoAccepted'];
        if (AppService.isGermanInstance() && company) {
            requiredCheckboxes.push('companyPolicyAccepted');
        }
        const otherCheckboxes = ['sponsorAccepted', 'rodoMarketingAccepted'];

        const values = getValues();

        if (isChecked) {
            [...requiredCheckboxes, ...otherCheckboxes].forEach((name) =>
                setValue(name as keyof AgreementData, true)
            );
        } else {
            requiredCheckboxes.forEach((name) =>
                setValue(name as keyof AgreementData, values[name as keyof AgreementData])
            );

            otherCheckboxes.forEach((name) => setValue(name as keyof AgreementData, false));
        }
    };
    const handleChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCheckedItems = [...checkedItems];

        updatedCheckedItems[index] = e.target.checked;
        setCheckedItems(updatedCheckedItems);
    };
    const formControls = useMemo(() => {
        const baseFormControls = [
            {
                name: 'privacyPolicyAccepted',
                labelId: 'agreement-privacy-policy-text',
                required: true,
                checked: isAgreementRequired,
                readOnly: isAgreementRequired,
                values: {
                    'data-club-rules': dataClubRulesFormattedMessage,
                    'data-information-obligation': dataInformationObligationFormattedMessage,
                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                }
            },
            {
                name: 'rodoAccepted',
                labelId: 'agreement-rodo-text',
                required: true,
                checked: isAgreementRequired,
                readOnly: isAgreementRequired,
                values: {
                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                }
            },
            {
                name: 'sponsorAccepted',
                labelId: 'page.register.agreement-sponsor',
                required: false,
                checked: undefined,
                readOnly: undefined,
                values: {}
            },
            {
                name: 'rodoMarketingAccepted',
                labelId: 'page.register.agreement-marketing',
                required: false,
                checked: undefined,
                readOnly: undefined,
                values: {
                    'data-contact-email': () => (
                        <NavLink
                            color="brand"
                            href={`mailto:${contactEmail}`}
                            isExternal
                            locale={false}
                        >
                            {contactEmail}
                        </NavLink>
                    ),
                    'data-privacy-policy-link': privacyPolicyFormattedMessage
                }
            }
        ];

        const companyPolicyAgreement = {
            name: 'companyPolicyAccepted',
            labelId: 'page.profile.tabs.company-policy',
            required: true,
            checked: isAgreementRequired,
            readOnly: isAgreementRequired,
            values: {
                'data-company-regulations-link': companyPolicyAcceptedMessage
            }
        };

        return AppService.isGermanInstance() && company
            ? [...baseFormControls, companyPolicyAgreement]
            : baseFormControls;
    }, [
        isAgreementRequired,
        contactEmail,
        dataClubRulesFormattedMessage,
        dataInformationObligationFormattedMessage,
        privacyPolicyFormattedMessage,
        companyPolicyAcceptedMessage,
        company
    ]);

    useEffect(() => {
        setIsAgreementRequired(agreementsCallback ? undefined : true);
    }, [agreementsCallback]);

    return (
        <Box as="form" mt={4} noValidate onSubmit={handleSubmit(formSubmitHandler)}>
            <FormControl display="flex" alignItems="flex-start" columnGap={2}>
                <Checkbox
                    mt={1}
                    isChecked={checkedItems.every(Boolean)}
                    isIndeterminate={checkedItems.some(Boolean) && !checkedItems.every(Boolean)}
                    onChange={handleSelectAllChange}
                />
                <Box>
                    <FormLabel>
                        <FormattedMessage id="page.register.check-all" />
                    </FormLabel>
                </Box>
            </FormControl>

            {formControls.map(({ name, labelId, required, checked, readOnly, values }, index) => {
                const isError = !!formState.errors[name as keyof AgreementData];
                const isChecked = checked || checkedItems[index];

                return (
                    <FormControl
                        key={name}
                        isInvalid={isError}
                        isRequired={required}
                        display="flex"
                        alignItems="flex-start"
                        columnGap={2}
                    >
                        <Checkbox
                            mt={1}
                            {...register(name as keyof AgreementData, {
                                required: { value: required, message: 'field-is-required' }
                            })}
                            isReadOnly={readOnly}
                            isChecked={isChecked}
                            onChange={handleChange(index)}
                        />
                        <Box>
                            <FormLabel>
                                <FormattedMessage id={labelId} values={values} />
                            </FormLabel>
                            <FormControlError
                                formState={formState}
                                control={register(name as keyof AgreementData)}
                            ></FormControlError>
                        </Box>
                    </FormControl>
                );
            })}

            <Flex justifyContent="center" mt={4}>
                <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                    <FormattedMessage id="save-data" />
                </Button>
            </Flex>
        </Box>
    );
};
