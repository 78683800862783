import { Container, Link, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

import { FooterProps } from '@/models/props/NavigationFooterProps';

import { FooterNavigation } from './FooterNavigation';
import { SocialMedia } from './SocialMedia';

export const Footer = (props: FooterProps) => {
    const { menu } = props;
    const odrUrl = 'https://ec.europa.eu/consumers/odr/';
    const url = (
        <Link href={odrUrl} isExternal={true} rel="noopener nofollow">
            {odrUrl}
        </Link>
    );

    return (
        <Container as="footer" maxW="full" backgroundColor="grey.100" className="page-footer">
            <Container maxW="7xl" py="7" justifyContent="center">
                <FooterNavigation menu={menu} />
                <SocialMedia />
                <Text textAlign="center" color="grey.600">
                    <FormattedMessage
                        id="footer-info-text"
                        values={{
                            address: url
                        }}
                    />
                </Text>
            </Container>
        </Container>
    );
};
