import { getCookie, setCookie } from 'cookies-next';

import { CookieConsent } from '@/components/CookieConsentSettingsPanel/CookieConsentSettingsPanel';

const cookieName = 'cookie-consent';
const cookieMaxAge = 365 * 24 * 3600;

function getUserCookieConsent(): CookieConsent | null {
    const cookieConsent = getCookie(cookieName) as string;
    if (!cookieConsent) {
        return null;
    }
    return JSON.parse(cookieConsent);
}

function saveUserCookieConsent(cookieConsent: CookieConsent): void {
    setCookie(cookieName, JSON.stringify(cookieConsent), {
        maxAge: cookieMaxAge
    });

    window.dataLayer?.push({
        event: 'ConsentUpdated',
        consents: cookieConsent
    });
}

export const CookieConsentService = {
    getUserCookieConsent,
    saveUserCookieConsent
};
