import { Flex, Spinner } from '@chakra-ui/react';

function FetchingProductsSpinner({ ...rest }) {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            pos="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            bgColor="rgba(255, 255, 255, 0.5)"
            w="100%"
            h="100%"
            zIndex={10}
            {...rest}
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.main"
                size="xl"
            />
        </Flex>
    );
}

export default FetchingProductsSpinner;
