const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
  <linearGradient id="g">
    <stop stop-color="#d5d5d5" offset="20%" />
    <stop stop-color="#ebebeb" offset="50%" />
    <stop stop-color="#d5d5d5" offset="70%" />
  </linearGradient>
</defs>
<rect width="${w}" height="${h}" fill="#d5d5d5" />
<rect id="r" width="${w}" height="${h}" fill="url(#g)" />
<animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export default shimmer;
