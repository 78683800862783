import { CloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Spinner,
    Tooltip
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';
import {
    createRef,
    FormEvent,
    PropsWithChildren,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useIntl } from 'react-intl';
import { throttle } from 'underscore';

import { getAxiosResponseErrorObject } from '@/lib';
import { useToast } from '@/hooks/useToast';

import { MagnifierIcon } from '@/components/Icons';

import BasketConfig from '@/constants/basket';
import RoutePath from '@/constants/route-path';
import { Product } from '@/models/api';
import { ErrorCode } from '@/models/api/Error';
import { SearchProps } from '@/models/props/SearchProps';

export const Search = (props: PropsWithChildren<SearchProps>) => {
    const {
        children,
        delayInputEmit,
        minLength,
        searchSubmit,
        selectedTitle,
        valueUpdate,
        onFocusCallback,
        destination,
        productItem,
        productQuantity,
        loading,
        ...rest
    } = props;
    const session = useSession();
    const intl = useIntl();
    const router = useRouter();
    const {
        searchedProduct,
        setSearchProduct,
        currentBasket,
        cartItems,
        cartQuantity,
        cartQuantityUnlogged,
        increaseItemQuantity,
        disableCartActions,
        cartItemsUnlogged,
        unloggedIncreaseItemQuantity
    } = useShoppingCart();
    const { errorToast, successToast } = useToast();
    const query = router.query as { title: string | undefined };
    const [searchValue, setSearchValue] = useState(query.title || '');
    const [enableEnter, setEnableEnter] = useState(false);
    const searchInputRef = createRef<HTMLInputElement>();
    const inputPlaceholder = props.placeholder || intl.formatMessage({ id: 'search' });
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(productItem);

    const obj = useMemo(
        () => ({
            searchedProduct,
            selectedTitle,
            setSearchProduct,
            onFocusCallback,
            currentBasket,
            destination,
            valueUpdate,
            searchSubmit,
            cartItems,
            cartQuantity,
            cartQuantityUnlogged,
            increaseItemQuantity,
            disableCartActions,
            productQuantity,
            cartItemsUnlogged,
            unloggedIncreaseItemQuantity
        }),
        [
            searchedProduct,
            selectedTitle,
            setSearchProduct,
            onFocusCallback,
            currentBasket,
            destination,
            valueUpdate,
            searchSubmit,
            cartItems,
            cartQuantity,
            cartQuantityUnlogged,
            increaseItemQuantity,
            disableCartActions,
            productQuantity,
            cartItemsUnlogged,
            unloggedIncreaseItemQuantity
        ]
    );

    const emitInputValue = useCallback(
        (value: string) => {
            obj.valueUpdate?.(value);
        },
        [obj]
    );

    const resetInput = useCallback(() => {
        setSearchValue(() => '');
        emitInputValue('');
        setSelectedProduct(undefined);
        obj.setSearchProduct(null);
    }, [emitInputValue, obj]);

    const clearSearchClick = () => {
        resetInput();
        (searchInputRef.current as HTMLInputElement).focus();
    };

    const onFocusInput = useCallback(() => {
        obj.setSearchProduct(null);

        if (obj.onFocusCallback) {
            obj.onFocusCallback(true);
        }
    }, [obj]);

    const onSearchInput = (event: SyntheticEvent<HTMLInputElement, InputEvent>) => {
        const element = event.target as HTMLInputElement;
        const { value } = element;

        setSearchValue(() => value);
    };

    const onSearchClick = useCallback(() => {
        obj.searchSubmit?.(searchValue);
    }, [searchValue, obj]);

    const getSearchResults = useMemo(
        () =>
            throttle(async (value: string) => {
                emitInputValue(value);
            }, 1000),
        [emitInputValue]
    );

    const searchFormSubmitHandler = useCallback(
        (event: FormEvent) => {
            event.preventDefault();

            obj.searchSubmit?.(searchValue);
        },
        [searchValue, obj]
    );

    const submitFormOnClickUnloggedHandler = useCallback(async () => {
        const quantity = obj.productQuantity || 1;

        if (!selectedProduct) {
            errorToast({
                title: intl.formatMessage({ id: 'add-to-cart.missing-product' })
            });
            return;
        }

        if (obj.cartItemsUnlogged.length < (BasketConfig.pageSize as number)) {
            await obj.unloggedIncreaseItemQuantity(
                selectedProduct.code,
                quantity,
                +selectedProduct.grossPrice.replace(',', '.')
            );

            setEnableEnter(false);
            successToast({
                title: intl.formatMessage({ id: 'added-to-cart' })
            });
        } else {
            errorToast({
                title: intl.formatMessage({ id: 'add-to-cart.max-page-size.title' }),
                description: intl.formatMessage({ id: 'add-to-cart.max-page-size.description' })
            });
        }
    }, [selectedProduct, errorToast, successToast, intl, obj]);

    const submitFormOnClickHandler = useCallback(async () => {
        const quantity = obj.productQuantity || 1;

        if (!selectedProduct) {
            errorToast({
                title: intl.formatMessage({ id: 'add-to-cart.missing-product' })
            });
            return;
        }

        setEnableEnter(false);
        obj.setSearchProduct(selectedProduct);
        setSearchValue(selectedProduct.title);

        if (obj.cartItems.length >= (BasketConfig.pageSize as number)) {
            return;
        }

        try {
            await obj.increaseItemQuantity(selectedProduct.code, quantity);

            successToast({
                title: intl.formatMessage({ id: 'added-to-cart' })
            });
        } catch (e) {
            const error = getAxiosResponseErrorObject(e);
            const title = intl.formatMessage({
                id: 'add-to-cart.update-product-quantity.fail'
            });
            const description =
                error.errors?.[0]?.code || intl.formatMessage({ id: error.errors?.[0]?.code });

            errorToast({
                title,
                description
            });

            if (error.errors?.[0]?.code === ErrorCode.disabled) {
                obj.disableCartActions(true);
            }

            if (error.errors?.[0]?.code === ErrorCode.unauthenticated) {
                signOut({
                    callbackUrl: RoutePath.Home
                });
            }
        }
    }, [selectedProduct, errorToast, successToast, intl, obj]);

    useEffect(() => {
        if (!delayInputEmit) {
            emitInputValue(searchValue);
            return;
        }
        if (!searchValue) {
            resetInput();
            getSearchResults.cancel();
            return;
        }

        if (minLength !== undefined && searchValue.length < minLength) {
            getSearchResults.cancel();
            return;
        }
        getSearchResults(searchValue);
    }, [
        searchValue,
        getSearchResults,
        delayInputEmit,
        minLength,
        resetInput,
        emitInputValue,
        selectedTitle
    ]);

    useEffect(() => {
        if (productItem) {
            setSelectedProduct(productItem);
            setEnableEnter(true);
        } else {
            setSelectedProduct(undefined);
            setEnableEnter(false);
        }
    }, [productItem]);

    useEffect(() => {
        setSearchValue(() => '');
        setSelectedProduct(undefined);
    }, [obj.cartQuantity, obj.cartQuantityUnlogged]);

    useEffect(() => {
        if (obj?.searchedProduct && obj.searchedProduct.title && obj.selectedTitle) {
            setSearchValue(obj.selectedTitle);
        }
    }, [obj.searchedProduct, obj.selectedTitle]);

    useEffect(() => {
        const handleRouteChangeComplete = () => {
            setSearchValue('');
        };

        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router]);

    return (
        <Flex alignItems="center" h={14} minW={70} width="100%" {...rest}>
            <Box as="form" position="relative" flex={1} onSubmit={searchFormSubmitHandler}>
                <InputGroup>
                    {obj.destination !== 'checkout' && (
                        <InputLeftElement
                            top="50%"
                            transform="translateY(-50%)"
                            h="auto"
                            w="auto"
                            left={4}
                        >
                            <Button onClick={onSearchClick} variant="shadow" p={0} minW="auto">
                                <MagnifierIcon h={6} w={6} />
                            </Button>
                        </InputLeftElement>
                    )}
                    <Input
                        name="search"
                        onInput={onSearchInput}
                        onFocus={onFocusInput}
                        placeholder={inputPlaceholder}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && obj.destination === 'checkout') {
                                e.preventDefault();

                                if (!enableEnter) return;

                                if (!loading && session.status === 'authenticated') {
                                    submitFormOnClickHandler();
                                } else if (!loading && session.status === 'unauthenticated') {
                                    submitFormOnClickUnloggedHandler();
                                }
                            }
                        }}
                        _placeholder={{
                            color: '#666C75',
                            fontStyle: 'italic'
                        }}
                        h={12}
                        value={searchValue}
                        ref={searchInputRef}
                        pl={obj.destination !== 'checkout' ? 12 : 4}
                        borderRadius={26}
                    />
                    {searchValue && !loading && (
                        <InputRightElement top="50%" transform="translateY(-50%)">
                            <Button variant="link" size="sm" onClick={clearSearchClick}>
                                <Tooltip
                                    hasArrow
                                    label={intl.formatMessage({ id: 'clear-search-field' })}
                                    marginInline={2}
                                >
                                    <CloseIcon w={4} />
                                </Tooltip>
                            </Button>
                        </InputRightElement>
                    )}
                    {loading && (
                        <Box
                            position="absolute"
                            pointerEvents="none"
                            top="50%"
                            transform="translateY(-50%)"
                            right={4}
                        >
                            <Spinner color="blue.main" size="sm" />
                        </Box>
                    )}
                </InputGroup>
                {children}
            </Box>
        </Flex>
    );
};
