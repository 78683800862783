import { Box, useMediaQuery } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { BasketIcon } from '@/components/Icons';

import breakpoints from '@/../themes/betterstyle-theme/breakpoints';

function CartQuantity(props: { quantity: number }) {
    const { quantity } = props;
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const [desktop, setDesktop]: [
        boolean | undefined,
        Dispatch<SetStateAction<boolean | undefined>>
    ] = useState();

    useEffect(() => {
        setDesktop(isDesktop);
    }, [isDesktop]);

    return (
        <>
            {quantity > 0 && (
                <Box as="span" pos="relative" pointerEvents="none">
                    <BasketIcon w={{ base: 4, md: 6 }} h={{ base: 4, md: 6 }} />
                    <Box
                        as="span"
                        color="white"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="absolute"
                        h={17}
                        minWidth={17}
                        top={desktop ? -2 : -1.5}
                        right={desktop ? -2.5 : -2}
                        fontWeight="semibold"
                        fontSize="13px"
                        bgColor="blue.main"
                        borderRadius="8px"
                        p="0 1px 1px 1px"
                    >
                        {quantity}
                    </Box>
                </Box>
            )}
        </>
    );
}

export default CartQuantity;
