import { AxiosError } from 'axios';
import { NextApiResponse } from 'next';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import { Logger } from 'winston';

import { ApiErrorMessage } from '@/components/ApiErrors/ApiErrors';

export type ErrorObject = {
    message?: string;
    errors: ApiErrorMessage[];
    request?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    stack?: string;
};

export function handleClientError(error: AxiosError, session: Session | null) {
    if (session && error?.response?.status === 401) {
        signOut({
            redirect: false
        }).then(() => location.reload());
    }
    return Promise.reject(error);
}

export function handleError(res: NextApiResponse, error: unknown, logger: Logger) {
    let statusCode;
    const errorObject: ErrorObject = {
        message: 'Internal Server Error',
        errors: []
    };
    if (error instanceof AxiosError) {
        if (error.response?.data?.errors) {
            errorObject.errors = error.response.data.errors;
        }
        statusCode = error.response?.status;
        errorObject.data = error.response?.data;
    }
    if (error instanceof Error) {
        errorObject.message = error.message;
        errorObject.stack = error.stack;
    }
    statusCode = statusCode || 500;
    logger.error(`[${statusCode}] ${res.req.url} | message: ${errorObject.message}`);
    logger.error(`[${statusCode}] ${res.req.url} | stack: ${errorObject.stack}`);
    res.status(statusCode).json(errorObject);
}

export function getAxiosResponseErrorObject(error: unknown): ErrorObject {
    let errorObject = {} as ErrorObject;
    if (error instanceof AxiosError) {
        errorObject = error.response?.data;
    }
    return errorObject;
}
