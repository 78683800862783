import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const DownloadIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 17.749 15.024" {...props}>
            <path
                d="M 0.65039062,11.951172 A 0.64999998,0.64999998 0 0 0 0,12.601563 v 2.421875 h 17.748047 v -2.421875 a 0.64999998,0.64999998 0 0 0 -0.648438,-0.650391 0.64999998,0.64999998 0 0 0 -0.65039,0.650391 v 1.123046 H 1.3007812 V 12.601563 A 0.64999998,0.64999998 0 0 0 0.65039062,11.951172 Z"
                id="Path_816"
            />
            <path
                d="M 8.8730469,0 A 0.64999998,0.64999998 0 0 0 8.2246094,0.65039062 V 11.265625 a 0.64999998,0.64999998 0 0 0 0.6484375,0.650391 0.64999998,0.64999998 0 0 0 0.6503906,-0.650391 V 0.65039062 A 0.64999998,0.64999998 0 0 0 8.8730469,0 Z"
                id="Path_817"
            />
            <path
                d="M 5.6445312,8.0722656 A 0.64999998,0.64999998 0 0 0 5.6171875,8.9921875 L 8.9042969,12.470703 12.193359,8.9921875 A 0.64999998,0.64999998 0 0 0 12.166019,8.0722656 0.64999998,0.64999998 0 0 0 11.24805,8.0976566 L 8.9042969,10.578125 6.5625,8.0976563 A 0.64999998,0.64999998 0 0 0 5.6445312,8.0722656 Z"
                id="Path_818"
            />
        </Icon>
    );
};

export default DownloadIcon;
