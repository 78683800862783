import { Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { FormattedMessage } from 'react-intl';

import BasketTime from '@/components/BasketTime';
import CartQuantity from '@/components/ShoppingCart/CartQuantity';

function BasketTimeCheckout() {
    const { cartQuantity } = useShoppingCart();

    return (
        <Flex
            alignItems="center"
            bgColor="grey.100"
            borderRadius={26}
            lineHeight={1}
            maxW="fit-content"
            px={{ base: 2, md: 4 }}
            h={{ base: 10, md: 12 }}
        >
            <CartQuantity quantity={cartQuantity} />
            <Text fontSize="sm" pointerEvents="none" ml={5}>
                <FormattedMessage id="expire" /> <BasketTime />
            </Text>
        </Flex>
    );
}

export default BasketTimeCheckout;
