const fontSizes = {
    h1: '2.375rem',
    h2: '2rem',
    h3: '1.5rem',
    h4: '1.25rem',
    h5: '1rem',
    h6: '.75rem'
};

export default fontSizes;
