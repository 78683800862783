function getApiURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_API_BASE_URL);
}

function getAppStaging(): string {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_APP_STAGING);
}

function getCanonicalURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_CANNONICAL_URL);
}

function getCmsInternalApiUrl() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_CANNONICAL_URL).concat('/api/cms');
}

function getContactEmailAddress() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_CONTACT_EMAIL_ADDRESS);
}

function getGoogleTagManager() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_TAG_MANAGER);
}

function getGoogleReCaptchaPublicKey() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY);
}

function getGoogleReCaptchaSecretKey() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY);
}

function getI18nAdditionalLanguages() {
    return process.env.NEXT_PUBLIC_APP_AVAILABLE_LANGUAGES as string;
}

function getI18nDefaultLanguage() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_APP_MAIN_LANGUAGE);
}

function getInstanceCountryCode() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_INSTANCE_COUNTRY_CODE);
}

function getPublicApiURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_API_URL);
}

function getPublicBaseURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_BASE_URL);
}

function getPublicSpApiURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_SP_API_URL);
}

function getStrapiURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_STRAPI_API_URL);
}

function getStrapiImageURL() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_STRAPI_IMAGE_URL);
}

function getStrapiToken() {
    return getEnvironmentVariable(process.env.NEXT_PUBLIC_STRAPI_TOKEN);
}

function getEnvironmentVariable(value: string | undefined): string {
    if (!value) {
        throw new Error('Environment variable is not set. Check environment configuration file');
    }
    return value;
}

export const EnvironmentService = {
    getApiURL,
    getAppStaging,
    getCanonicalURL,
    getCmsInternalApiUrl,
    getContactEmailAddress,
    getGoogleTagManager,
    getGoogleReCaptchaPublicKey,
    getGoogleReCaptchaSecretKey,
    getI18nAdditionalLanguages,
    getI18nDefaultLanguage,
    getInstanceCountryCode,
    getPublicApiURL,
    getPublicBaseURL,
    getPublicSpApiURL,
    getStrapiImageURL,
    getStrapiToken,
    getStrapiURL
};
