import { Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { FacebookIcon, InstagramIcon, YouTubeIcon } from '@/components/Icons';
import NavLink from '@/components/UI/Links/NavLink';

type SocialMediaLink = {
    icon: ReactNode;
    url: string | undefined;
};

export const SocialMedia = () => {
    const socialMediaLinks: SocialMediaLink[] = [
        {
            url: process.env.NEXT_PUBLIC_FACEBOOK_URL,
            icon: <FacebookIcon />
        },
        {
            url: process.env.NEXT_PUBLIC_INSTAGRAM_URL,
            icon: <InstagramIcon />
        },
        {
            url: process.env.NEXT_PUBLIC_YOUTUBE_URL,
            icon: <YouTubeIcon />
        }
    ];
    const socialMediaLinkNodes = socialMediaLinks
        .filter((item) => item.url)
        .map((item, index) => (
            <NavLink
                href={item.url as string}
                target="_blank"
                rel="noopener noreferrer"
                p={2}
                bgColor="white"
                borderRadius={6}
                key={index}
            >
                {item.icon}
            </NavLink>
        ));

    return (
        <>
            {!!socialMediaLinkNodes.length && (
                <>
                    <Text textAlign="center" mb={2} color="grey.600">
                        <FormattedMessage id="find-us" />:
                    </Text>
                    <Flex columnGap={2.5} justifyContent="center" mb={6} lineHeight={1}>
                        {socialMediaLinkNodes}
                    </Flex>
                </>
            )}
        </>
    );
};
