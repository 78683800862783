import { Box, Flex, GridItem, List, ListItem, MenuItem, SimpleGrid } from '@chakra-ui/react';
import {
    ForwardedRef,
    forwardRef,
    PropsWithRef,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';

import classes from './categoriesList.module.scss';

import { ArrowLeftIcon } from '@/components/Icons';
import NavLink from '@/components/UI/Links/NavLink';

import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';

import { CategoriesListItem, ExpandableCategory } from './CategoriesListItem';

interface CategoriesListProps {
    items: ProductCategoryProps[];
    isActive: boolean;
    depth?: number;
    onSubCategorySelect?: (data: boolean) => void;
}

export const CategoriesList = forwardRef(
    (props: PropsWithRef<CategoriesListProps>, ref: ForwardedRef<HTMLDivElement>) => {
        const { items, isActive } = props;
        const depth = props.depth || 0;
        const isFirstLevel = depth === 1;
        const [subCategory, setSubCategory] = useState<ExpandableCategory | null>(null);

        const obj = useMemo(
            () => ({
                subCategory,
                isActive
            }),
            [subCategory, isActive]
        );

        const subCategoryFunc: (data: ExpandableCategory) => void = useCallback(
            (data: ExpandableCategory) => {
                setSubCategory(data);
            },
            []
        );

        useEffect(() => {
            !obj.isActive && setSubCategory(null);
        }, [obj]);

        const categoryElements = items.map((category, index) => (
            <CategoriesListItem
                key={index}
                categoryItem={category}
                depth={depth + 1}
                isActive={isActive}
                onSubCategorySelect={subCategoryFunc}
                fontWeight={subCategory?.id === category.id ? 'semibold' : 'normal'}
            />
        ));

        return (
            <>
                <Flex width="100%" height="max-content" mt={4}>
                    <Box
                        borderLeftRadius="18px"
                        borderRightRadius={subCategory?.expanded ? 0 : '18px'}
                        borderWidth="1px"
                        borderColor="blue.100"
                        overflow="hidden"
                        height="100%"
                        minWidth="250px"
                        position="relative"
                        bg="white"
                        px={5}
                        pt={7}
                        pb={6}
                        ref={ref}
                    >
                        <List
                            alignItems="start"
                            data-depth={depth}
                            textAlign="start"
                            height="100%"
                            display="flex"
                            width="100%"
                            minW={275}
                            flexDir="column"
                            gridAutoRows={isFirstLevel ? 'min-content' : undefined}
                            gridTemplateColumns={{
                                xl: isFirstLevel ? 'repeat(3, 1fr)' : undefined
                            }}
                            pl={2}
                            ml={-2}
                            zIndex={10}
                            pos="relative"
                            pointerEvents="auto"
                            maxHeight="calc(100vh - 150px)"
                            overflow="auto"
                            className={classes.categoriesList}
                        >
                            {categoryElements}
                        </List>
                        {subCategory?.expanded && (
                            <ArrowLeftIcon
                                pointerEvents="none"
                                pos="absolute"
                                top="50%"
                                right={-1}
                                transform="translateY(-50%)"
                            />
                        )}
                    </Box>
                    <Box
                        pos="relative"
                        bgColor="white"
                        borderRightRadius="18px"
                        borderWidth="1px"
                        borderColor="blue.100"
                        py={8}
                        pr={8}
                        ml="-1px"
                        textAlign="left"
                        display={subCategory?.expanded ? 'block' : 'none'}
                        pointerEvents={subCategory?.expanded ? 'auto' : 'none'}
                        overflow="hidden"
                    >
                        {subCategory && subCategory.subs && (
                            <SimpleGrid
                                px={12}
                                rowGap={12}
                                columnGap={14}
                                columns={
                                    subCategory.subs.length >= 2
                                        ? 2
                                        : subCategory.subs.length >= 5
                                          ? 3
                                          : 1
                                }
                            >
                                {subCategory.subs.map((item, index) => (
                                    <GridItem as={List} key={index} minW={150}>
                                        <MenuItem
                                            as={ListItem}
                                            display="flex"
                                            flexDir="column"
                                            alignItems="start"
                                            justifyContent="start"
                                            p={0}
                                            maxWidth="max-content"
                                            textAlign="left"
                                            closeOnSelect={true}
                                            _hover={{
                                                bgColor: 'transparent'
                                            }}
                                            _focus={{
                                                bgColor: 'transparent'
                                            }}
                                        >
                                            <NavLink
                                                href={`${process.env.NEXT_PUBLIC_CANNONICAL_URL}/${subCategory.slug}/${item.slug}`}
                                                color="blue.main"
                                            >
                                                {item.name}
                                            </NavLink>
                                            {item.subs && item.subs.length > 0 && (
                                                <List pt={5} fontSize="sm">
                                                    {item.subs.map((subItem, subIndex) => (
                                                        <MenuItem
                                                            as={ListItem}
                                                            key={subIndex}
                                                            _hover={{
                                                                bgColor: 'transparent'
                                                            }}
                                                            _focus={{
                                                                bgColor: 'transparent'
                                                            }}
                                                            px={0}
                                                            pt={0}
                                                            pb={
                                                                subIndex <
                                                                (
                                                                    item.subs as ProductCategoryProps[]
                                                                )?.length -
                                                                    1
                                                                    ? 2
                                                                    : 0
                                                            }
                                                        >
                                                            <NavLink
                                                                href={`${process.env.NEXT_PUBLIC_CANNONICAL_URL}/${subCategory.slug}/${item.slug}/${subItem.slug}`}
                                                            >
                                                                {subItem.name}
                                                            </NavLink>
                                                        </MenuItem>
                                                    ))}
                                                </List>
                                            )}
                                        </MenuItem>
                                    </GridItem>
                                ))}
                            </SimpleGrid>
                        )}
                    </Box>
                </Flex>
            </>
        );
    }
);
