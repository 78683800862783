import { ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    DrawerBody,
    DrawerCloseButton,
    DrawerFooter,
    DrawerHeader,
    Flex,
    ListItem,
    UnorderedList,
    useDisclosure
} from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classes from './mobileMenu.module.scss';

import {
    loggedUserShoppingMenu,
    topBarLoggedUserLinks,
    topBarUnLoggedUserLinks,
    unLoggedUserShoppingMenu
} from '@/data/menu';

import LanguageSelector from '@/components/Language/LanguageSelector';
import { ProductCategoryDrawer } from '@/components/MobileMenu/ProductCategoryDrawer';
import { BrandLink } from '@/components/UI/Links/BrandLink';
import NavLink from '@/components/UI/Links/NavLink';

import { ProductCategoryProps } from '@/models/props/ProductCategoryProps';
import { APP_STAGING } from '@/pages/_app';

import { ProductRootDrawer } from './ProductRootDrawer';

export type MobileMenuProps = {
    categories: ProductCategoryProps[];
};

function MobileMenu(props: MobileMenuProps) {
    const { data } = useSession();
    const usefulLinks = data?.user ? topBarLoggedUserLinks : topBarUnLoggedUserLinks;
    const shoppingMenu = data?.user ? loggedUserShoppingMenu : unLoggedUserShoppingMenu;
    const listItems = useMemo(() => [...usefulLinks], [usefulLinks]);
    const {
        isOpen: isProductsListOpen,
        onClose: onProductsListClose,
        onOpen: onProductsListOpen
    } = useDisclosure();
    const [selectedCategory, setSelectedCategory] = useState<ProductCategoryProps | null>(null);

    const usefulLinksElements = listItems.map((item, index) => (
        <ListItem
            key={index}
            borderBottomColor="grey.400"
            flexGrow={1}
            textAlign="center"
            fontWeight="semibold"
            flex={1}
        >
            <NavLink
                href={`${process.env.NEXT_PUBLIC_CANNONICAL_URL}/${item.href}/`}
                variant="buttonBlue"
                borderRadius={28}
                px={4}
                py={3}
            >
                <FormattedMessage id={item.translationKey} />
            </NavLink>
        </ListItem>
    ));

    const shoppingMenuListElements = shoppingMenu.map((item, index) => (
        <ListItem
            key={index}
            borderBottomWidth={1}
            borderBottomColor="grey.400"
            _last={{
                borderBottomWidth: 0
            }}
        >
            <NavLink href={item.href} py={6}>
                <FormattedMessage id={item.translationKey} />
            </NavLink>
        </ListItem>
    ));

    const categoryDrawerCloseHandler = useCallback(() => {
        setSelectedCategory(() => null);
    }, []);

    const categorySelectHandler = useCallback((category: ProductCategoryProps) => {
        setSelectedCategory(() => category);
    }, []);

    return (
        <>
            <DrawerHeader>
                <Flex alignItems="center" justifyContent="space-between">
                    <LanguageSelector />
                    <DrawerCloseButton />
                </Flex>
            </DrawerHeader>

            <DrawerBody px={0}>
                <Box px={7} my={4} display="flex" justifyContent="center">
                    <BrandLink />
                </Box>
                <Box px={7} borderTop="1px" borderTopColor="blue.100">
                    <Box as="nav">
                        <UnorderedList
                            marginLeft={0}
                            className={classes.listMenu}
                            flexDirection="row"
                            gap={4}
                            my={4}
                        >
                            {usefulLinksElements}
                        </UnorderedList>
                        {APP_STAGING > 1 && (
                            <UnorderedList marginLeft={0} className={classes.listMenu}>
                                <ListItem borderBottomWidth={0}>
                                    <Button
                                        display="flex"
                                        justifyContent="space-between"
                                        variant="ghost"
                                        borderRadius={28}
                                        w="100%"
                                        h="auto"
                                        textAlign="left"
                                        color="inherit"
                                        onClick={onProductsListOpen}
                                        borderWidth={1}
                                        borderColor="grey.400"
                                        p={4}
                                        my={4}
                                    >
                                        <FormattedMessage id="products" />
                                        <ChevronRightIcon />
                                    </Button>
                                </ListItem>
                                {shoppingMenuListElements}
                            </UnorderedList>
                        )}
                    </Box>
                </Box>
            </DrawerBody>

            <DrawerFooter display="block"></DrawerFooter>
            <ProductRootDrawer
                categories={props.categories}
                open={isProductsListOpen}
                drawerClose={onProductsListClose}
                selectCategory={categorySelectHandler}
            />
            {selectedCategory && (
                <ProductCategoryDrawer
                    category={selectedCategory}
                    categoryClose={categoryDrawerCloseHandler}
                />
            )}
        </>
    );
}

export default MobileMenu;
