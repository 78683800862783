import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { CookieConsent } from '@/components/CookieConsentSettingsPanel/CookieConsentSettingsPanel';
import { CookieConsentService } from '@/services/CookieConsentService';

type CookieConsentContextType = {
    isCookieConsentSettingsPanelOpened: boolean;
    closeCookieConsentSettingsPanel(): void;
    getCookieConsent(): CookieConsent;
    openCookieConsentSettingsPanel(): void;
    setAllCookieConsent(): void;
    setCookieConsentPanelOpenState(opened: boolean): void;
    setCustomCookieConsent(cookieConsent: CookieConsent): void;
    setEssentialCookieConsent(): void;
};

const CookieConsentContext = createContext<CookieConsentContextType>(
    {} as CookieConsentContextType
);

export function useCookieConsent() {
    return useContext(CookieConsentContext);
}

export function CookieConsentProvider({ children }: PropsWithChildren) {
    const [cookieConsent, setCookieConsent] = useState<CookieConsent>(
        CookieConsentService.getUserCookieConsent() || {
            essential: true,
            analytic: false,
            marketing: false
        }
    );
    const [isCookieConsentSettingsPanelOpened, setIsCookieConsentSettingsPanelOpened] =
        useState(false);

    const closeCookieConsentSettingsPanel = () => {
        setCookieConsentPanelOpenState(false);
    };

    const openCookieConsentSettingsPanel = () => {
        setCookieConsentPanelOpenState(true);
    };

    const setCookieConsentPanelOpenState = (opened: boolean): void => {
        setIsCookieConsentSettingsPanelOpened(() => opened);
    };

    const setAllCookieConsent = () => {
        const cookieConsent: CookieConsent = {
            marketing: true,
            analytic: true,
            essential: true
        };
        CookieConsentService.saveUserCookieConsent(cookieConsent);
        setCookieConsent(() => cookieConsent);
    };

    const setEssentialCookieConsent = () => {
        const cookieConsent: CookieConsent = {
            marketing: false,
            analytic: false,
            essential: true
        };
        CookieConsentService.saveUserCookieConsent(cookieConsent);
        setCookieConsent(() => cookieConsent);
    };

    const setCustomCookieConsent = (consent: CookieConsent) => {
        const cookieConsent = {
            ...consent
        };
        CookieConsentService.saveUserCookieConsent(cookieConsent);
        setCookieConsent(() => cookieConsent);
    };

    const getCookieConsent = () => cookieConsent;

    return (
        <CookieConsentContext.Provider
            value={{
                isCookieConsentSettingsPanelOpened,
                closeCookieConsentSettingsPanel,
                getCookieConsent,
                openCookieConsentSettingsPanel,
                setCookieConsentPanelOpenState,
                setAllCookieConsent,
                setEssentialCookieConsent,
                setCustomCookieConsent
            }}
        >
            {children}
        </CookieConsentContext.Provider>
    );
}
