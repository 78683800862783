import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import useLocalStorage from '@/hooks/useLocalStorage';

import { H4, H5 } from '@/components/Heading';

import RoutePath from '@/constants/route-path';
import { CartError } from '@/models/props/ShoppingCartContextProps';

function AddToCartModal() {
    const router = useRouter();
    const intl = useIntl();
    const { getItem, removeItem } = useLocalStorage();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        basketLocked,
        cartItemsUnlogged,
        clearBasketUnlogged,
        cartError,
        updateCartError,
        currentBasket,
        updateCartItems
    } = useShoppingCart();
    const [error, setError] = useState<CartError | null>(null);

    useEffect(() => {
        if (router.route === RoutePath.AddToCart || router.route === RoutePath.SignIn) return;

        if (!basketLocked && cartItemsUnlogged.length > 0) {
            if (cartError) {
                setError(cartError);
            }
            onOpen();
        }
    }, [basketLocked, onOpen, cartItemsUnlogged, router, getItem, removeItem, cartError]);

    const closeModal = useCallback(() => {
        clearBasketUnlogged();
        onClose();
        updateCartError(null);

        if (currentBasket?.products.length === 0) {
            updateCartItems([]);
        }
    }, [clearBasketUnlogged, onClose, updateCartError, currentBasket, updateCartItems]);

    return (
        <Modal isOpen={isOpen} onClose={closeModal} isCentered>
            <ModalOverlay />
            <ModalContent maxWidth={{ base: 'calc(100% - 1rem)', md: 'xl' }}>
                <ModalCloseButton />
                <ModalBody pt={{ base: 10, sm: 14 }} pb={10} textAlign="center">
                    <H4
                        whiteSpace="pre-line"
                        mb={0}
                        dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                                id: 'basket.modal.add-to-cart.unlogged'
                            })
                        }}
                    />
                    {error && (
                        <Box
                            mt={5}
                            p={2}
                            rounded="md"
                            border="1px"
                            borderColor="red.main"
                            boxShadow="0 0 0 5px rgba(233, 94, 95, 0.5)"
                        >
                            <H5 mb={1}>{error.title}</H5>
                            <Text fontSize="sm">{error.description}</Text>
                        </Box>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default AddToCartModal;
