import { Icon } from '@chakra-ui/icon';
import { IconProps } from '@chakra-ui/icons';

export const MagnifierIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 24 24" {...props}>
            <path
                d="M 10.9,1.66 C 5.44,1.66 1,6.101 1,11.561 c 0,5.459 4.44,9.9 9.9,9.9 5.46,0 9.901,-4.441 9.901,-9.9 0,-5.46 -4.441,-9.901 -9.901,-9.901 z m 0,1.301 c 4.758,0 8.6,3.842 8.6,8.6 0,4.757 -3.842,8.599 -8.6,8.599 -4.757,0 -8.599,-3.842 -8.599,-8.599 0,-4.758 3.842,-8.6 8.599,-8.6 z"
                id="path1144"
            />
            <path
                d="m 18.059,17.73 a 0.64999998,0.64999998 0 0 0 -0.459,0.192 0.64999998,0.64999998 0 0 0 0,0.918 l 4.398,4.398 a 0.64999998,0.64999998 0 0 0 0.918,0 0.64999998,0.64999998 0 0 0 0,-0.918 L 18.518,17.922 A 0.64999998,0.64999998 0 0 0 18.059,17.73 Z"
                id="path1146"
            />
        </Icon>
    );
};
