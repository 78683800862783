import { ComponentStyleConfig } from '@chakra-ui/react';

export const Form: ComponentStyleConfig = {
    baseStyle: {
        container: {
            marginBottom: 2.5
        }
    }
};

export default Form;
