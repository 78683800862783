import axios from 'axios';

import { AdditionalContent, StrapiResponse } from '@/models/cms';
import { LocaleProps } from '@/models/props/LocalizationProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';
import { StrapiUtilsService } from '@/services/StrapiUtilsService';

const ADDITIONAL_CONTENTS_URL = EnvironmentService.getCmsInternalApiUrl() + '/additional-contents';

export class AdditionalContentService {
    static async getStandardUserRegulationChangesContent(locale: LocaleProps) {
        const url = new URL(ADDITIONAL_CONTENTS_URL);
        const urlSearchParams = url.searchParams;

        urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
        urlSearchParams.set(
            'filters[contentId][$containsi]',
            StrapiUtilsService.getPrefixedName('standard-user-regulations-changed-content')
        );
        urlSearchParams.set('locale', locale);

        return axios
            .get<StrapiResponse<AdditionalContent[]>>(decodeURIComponent(url.toString()))
            .then((response) => response.data)
            .then((data) => data.data[0]);
    }

    static async getCompanyUserRegulationChangesContent(locale: LocaleProps) {
        const url = new URL(ADDITIONAL_CONTENTS_URL);
        const urlSearchParams = url.searchParams;

        urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
        urlSearchParams.set(
            'filters[contentId][$containsi]',
            StrapiUtilsService.getPrefixedName('company-user-regulations-changed-content')
        );
        urlSearchParams.set('locale', locale);

        return axios
            .get<StrapiResponse<AdditionalContent[]>>(decodeURIComponent(url.toString()))
            .then((response) => response.data)
            .then((data) => data.data[0]);
    }
}
