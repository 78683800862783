import axios, { AxiosResponse } from 'axios';

import { RegistrationResendInfo, RegistrationSponsorInfo, RegistrationUser } from '@/models/api';
import { RegistrationResult } from '@/models/api/Registration';

const BASE_ENDPOINT = `${process.env.NEXT_PUBLIC_API_URL}/registration`;

function verify(token: string) {
    const url = new URL(`${BASE_ENDPOINT}/verify/${token}`);
    const payload = {
        token
    };
    return axios.post<RegistrationResult>(url.toString(), payload).then((data) => data?.data);
}

async function getSponsorInfo(sponsorNumber: string) {
    const url = new URL(`${BASE_ENDPOINT}/sponsor`);
    url.searchParams.set('sponsorNo', sponsorNumber);
    return axios.get<RegistrationSponsorInfo>(url.toString()).then((data) => data?.data);
}

async function register(user: RegistrationUser) {
    return axios
        .post<
            RegistrationResendInfo,
            AxiosResponse<RegistrationResendInfo>,
            RegistrationUser
        >(BASE_ENDPOINT, user)
        .then((data) => data.data);
}

async function resendActivationLink(email: string) {
    const url = `${BASE_ENDPOINT}/resend-token`;
    const payload = {
        email
    };
    return axios.post<string, string>(url, payload).then((data) => data);
}

export const RegistrationService = {
    getSponsorInfo,
    register,
    resendActivationLink,
    verify
};
