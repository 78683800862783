import { Box, ListItem, UnorderedList } from '@chakra-ui/react';

import { FooterProps } from '@/models/props/NavigationFooterProps';

import NavLink from '../UI/Links/NavLink';

export const FooterNavigation = (props: FooterProps) => {
    const navListElements = props?.menu?.map((item, index) => {
        return (
            <ListItem key={index} textAlign="center" mb={{ base: 2, md: 0 }}>
                <NavLink href={`/${item.url}`}>{item.label}</NavLink>
            </ListItem>
        );
    });

    return (
        <>
            {!!props?.menu?.length && (
                <Box as="nav" mb={6}>
                    <UnorderedList
                        styleType="none"
                        margin={0}
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection={{ base: 'column', sm: 'row' }}
                        columnGap={4}
                    >
                        {navListElements}
                    </UnorderedList>
                </Box>
            )}
        </>
    );
};
