import { Button } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';

import { TrashIcon } from '@/components/Icons';

import { Product } from '@/models/api';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';

type RemoveItemProps = {
    isDisabled?: boolean;
} & Pick<Product, 'code'>;

function RemoveItemUnlogged({ code, isDisabled }: RemoveItemProps) {
    const { removeFromCartUnlogged } = useShoppingCart();
    const removeItemHandler = (code: ProductIdProps) => {
        removeFromCartUnlogged(code);
    };

    return (
        <Button
            variant="ghost"
            p={2}
            h={8}
            w={8}
            minW="0"
            isDisabled={isDisabled}
            onClick={() => removeItemHandler(code)}
        >
            <TrashIcon pointerEvents="none" />
        </Button>
    );
}

export default RemoveItemUnlogged;
