import {
    Box,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { useCurrentUser } from 'context/currentUserContext';
import { useSessionRegulations } from 'context/serviceRegulationsContext';
import htmlParser, { attributesToProps, DOMNode, domToReact } from 'html-react-parser';
import { ComponentPropsWithoutRef, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { H4 } from '@/components/Heading';
import { Agreements } from '@/components/Profile/Agreements/Agreements';
import NavLink from '@/components/UI/Links/NavLink';

import { AgreementData } from '@/models/api/ProfileInfo';
import { representativeService } from '@/services/RepresentativeService';
import { HtmlParserUtil } from '@/utils/HtmlParserUtil';

type RegulationsConsentPopupProps = {
    content: string;
};

export function RegulationsConsentPopup({ content }: RegulationsConsentPopupProps) {
    const intl = useIntl();
    const { accepted, acceptRegulations } = useSessionRegulations();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { errorToast, infoToast } = useToast();
    const { currentUser } = useCurrentUser();
    const { agreements, company } = currentUser || {};
    const obj = useMemo(
        () => ({
            accepted,
            acceptRegulations,
            isOpen,
            onClose,
            onOpen,
            intl,
            infoToast,
            errorToast
        }),
        [accepted, acceptRegulations, isOpen, onClose, onOpen, intl, infoToast, errorToast]
    );
    const acceptPrivacyPolicy = useCallback(
        async (userAgreements: AgreementData | null) => {
            if (userAgreements) {
                obj.acceptRegulations(userAgreements);
                obj.onClose();
            }
        },
        [obj]
    );

    useEffect(() => {
        if (obj.accepted) {
            obj.onClose();
        } else {
            obj.onOpen();
        }
    }, [obj]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size="5xl" isCentered>
            <ModalOverlay />
            <ModalContent mx={4}>
                <ModalHeader px={6} pt={6} borderBottom="1px" borderColor="blue.100">
                    <H4 mb={0} whiteSpace="pre-line">
                        <FormattedMessage id="privacy-policy.update.header" />
                    </H4>
                </ModalHeader>
                <ModalBody>
                    <Box py={6}>
                        <Box fontSize="lg" whiteSpace="pre-line">
                            {htmlParser(content || '', {
                                transform: (reactNode, domNode, index) => {
                                    if (!HtmlParserUtil.domNodeIsElement(domNode)) {
                                        return <Fragment key={index}>{reactNode}</Fragment>;
                                    }
                                    if (domNode.name === 'a') {
                                        const { href, ...restProps } = attributesToProps(
                                            domNode.attribs
                                        ) as ComponentPropsWithoutRef<'a'>;
                                        return (
                                            <NavLink
                                                key={index}
                                                href={href || ''}
                                                color="blue.main"
                                                isExternal
                                                {...restProps}
                                            >
                                                {domToReact(domNode.children as DOMNode[])}
                                            </NavLink>
                                        );
                                    }
                                    return <Fragment key={index}>{reactNode}</Fragment>;
                                }
                            })}
                        </Box>
                    </Box>
                </ModalBody>
                <ModalFooter
                    flexDirection="column"
                    alignItems="start"
                    borderTop="1px"
                    borderColor="blue.100"
                    py={6}
                >
                    <Box>
                        <Text fontSize="lg">
                            <FormattedMessage id="privacy-policy.update.footer" />
                        </Text>
                    </Box>
                    {agreements && (
                        <Agreements
                            company={company}
                            agreements={agreements}
                            representativeService={representativeService}
                            agreementsCallback={acceptPrivacyPolicy}
                        />
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default RegulationsConsentPopup;
