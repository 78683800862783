import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

export const CookieIcon = (props: IconProps) => {
    return (
        <Icon viewBox="0 0 35 35" {...props}>
            <path
                d="m 17.828125,0.99804688 c -8.7302671,0 -15.8300781,7.09786422 -15.8300781,15.82812512 0,8.730261 7.099811,15.830078 15.8300781,15.830078 h 0.0039 c 8.622559,-0.0027 15.674451,-6.933464 15.826172,-15.554688 a 1.0001,1.0001 0 0 0 -1.556641,-0.849609 c -1.782871,1.190955 -4.163615,0.674079 -5.292968,-1.148437 A 1.0001,1.0001 0 0 0 25.732422,14.65625 c -0.331298,0.07667 -0.669724,0.11406 -1.009766,0.111328 a 1.0001,1.0001 0 0 0 -0.0078,0 c -3.49921,-0.0032 -5.812777,-3.507273 -4.441407,-6.7265624 A 1.0001,1.0001 0 0 0 19.943359,6.8417969 C 19.24255,6.3294716 18.829036,5.5165446 18.828125,4.6484375 a 1.0001,1.0001 0 0 0 0,-0.00195 c -0.0024,-0.7129837 0.279172,-1.3980584 0.783203,-1.9023438 a 1.0001,1.0001 0 0 0 -0.626953,-1.703125 c -0.407201,-0.032489 -0.785311,-0.0429687 -1.15625,-0.0429687 z m -0.6875,2.07031252 c -0.181913,0.5069154 -0.314341,1.0352771 -0.3125,1.5839843 0.0019,1.2738104 0.60563,2.4155013 1.501953,3.2949219 -1.30899,4.2830974 1.770063,8.8147334 6.380859,8.8203124 6.74e-4,1e-6 0.0013,0 0.002,0 0.296234,0.002 0.587728,-0.0583 0.880859,-0.09766 1.443593,1.78251 3.725533,2.516647 5.882812,1.947265 -0.888946,6.801374 -6.622311,12.036836 -13.644531,12.039063 h -0.0039 c -7.649389,0 -13.8300781,-6.180695 -13.8300781,-13.830078 0,-7.413818 5.8208224,-13.3932562 13.1425781,-13.7578126 z"
                id="path1344"
            />
            <path
                d="m 13.591,8.355 a 2.118,2.118 0 1 1 -2.118,2.118 2.118,2.118 0 0 1 2.118,-2.118 z"
                id="path1346"
            />
            <path
                d="m 10.414,15.768 a 2.118,2.118 0 1 1 -2.118,2.118 2.118,2.118 0 0 1 2.118,-2.118 z"
                id="path1348"
            />
            <path
                d="M 18.886,23.182 A 2.118,2.118 0 1 1 16.768,25.3 2.118,2.118 0 0 1 18.886,23.182 Z"
                id="path1350"
            />
            <path
                d="m 18.886,17.886 a 1.059,1.059 0 1 1 -1.059,1.059 1.059,1.059 0 0 1 1.059,-1.059 z"
                id="path1352"
            />
            <path
                d="M 26.3,21.063 A 1.059,1.059 0 1 1 25.241,22.122 1.059,1.059 0 0 1 26.3,21.063 Z"
                id="path1354"
            />
            <path
                d="m 13.591,23.182 a 1.059,1.059 0 1 1 -1.059,1.059 1.059,1.059 0 0 1 1.059,-1.059 z"
                id="path1356"
            />
        </Icon>
    );
};
