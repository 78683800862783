import { useDisclosure } from '@chakra-ui/react';
import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { ShowPopupMessageOpts, useMessageModal } from '@/components/Modals/hooks/useMessageModal';

import { LockType, LockTypeKeys } from '@/constants/lock-type';
import { Locks } from '@/models/api/Locks';
import { SystemLockService } from '@/services';

export type AppContext = {
    lockType: LockTypeKeys | null;
    locks: Locks;
    loginAllowed: boolean;
    orderingAllowed: boolean;
    updateLocks: () => Promise<Locks>;
    isDisabledLoginPopupOpen: boolean;
    showDisabledLoginPopup: () => void;
    showDisabledOrderingPopup: () => void;
    hideDisabledLoginPopup: () => void;
    hideDisabledOrderingPopup: () => void;
    isPopupMessageOpen: boolean;
    onPopupMessageClose: () => void;
    showPopupMessage: (opts: ShowPopupMessageOpts) => void;
    hidePopupMessage: () => void;
    popupMessage: string;
    popupTitle?: string;
    disableBasket: () => void;
};

const AppContext = createContext({} as AppContext);

export function useApp() {
    return useContext(AppContext);
}

export function AppProvider(props: PropsWithChildren<Partial<AppContext>>) {
    const {
        isOpen: isPopupMessageOpen,
        onClose: onPopupMessageClose,
        hidePopupMessage,
        popupMessage,
        popupTitle,
        showPopupMessage
    } = useMessageModal();

    const [locks, setLocks] = useState<Locks>(props.locks || ({} as Locks));
    const [loginAllowed, setLoginAllowed] = useState(props.loginAllowed ?? true);
    const [orderingAllowed, setOrderingAllowed] = useState(props.orderingAllowed ?? true);

    const [lockType, setLockType] = useState<LockTypeKeys | null>(null);

    const { isOpen: isDisabledLoginPopupOpen, onClose, onOpen } = useDisclosure();

    const updateLocks = useCallback(async () => {
        try {
            const locks = await SystemLockService.getSystemLocks();
            setLocks(locks);
            setLoginAllowed(locks.loginAllowed);
            setOrderingAllowed(locks.orderingAllowed);
            return locks;
        } catch {
            return {} as Locks;
        }
    }, []);

    const showDisabledLoginPopup = useCallback(() => {
        setLockType(LockType.LoginLock);
        onOpen();
    }, [onOpen]);

    const hideDisabledLoginPopup = useCallback(() => {
        onClose();
        setLockType(null);
    }, [onClose]);

    const showDisabledOrderingPopup = useCallback(() => {
        setLockType(LockType.OrderingLock);
        onOpen();
    }, [onOpen]);

    const hideDisabledOrderingPopup = useCallback(() => {
        onClose();
        setLockType(null);
    }, [onClose]);

    const disableBasket = useCallback(() => {
        const orderingDisabled = { orderingAllowed: false };
        setLocks((locks) => ({ ...locks, ...orderingDisabled }));
    }, []);

    return (
        <AppContext.Provider
            value={{
                lockType,
                locks,
                loginAllowed,
                orderingAllowed,
                updateLocks,
                showDisabledLoginPopup,
                showDisabledOrderingPopup,
                hideDisabledLoginPopup,
                hideDisabledOrderingPopup,
                isDisabledLoginPopupOpen,
                isPopupMessageOpen,
                onPopupMessageClose,
                hidePopupMessage,
                popupMessage,
                popupTitle,
                showPopupMessage,
                disableBasket
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}
