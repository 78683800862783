import { User } from '../models/api';

const users: User[] = [
    {
        id: '1',
        name: 'test',
        memberName: 'test',
        email: 'test@test.com',
        firstName: 'test',
        lastName: 'test',
        password: 'test',
        memberNumber: '123456'
    },
    {
        id: 'DE-139901',
        name: 'test',
        memberName: 'test',
        email: 'test@test.com',
        firstName: 'test',
        lastName: 'test',
        password: 'test',
        memberNumber: '123456'
    }
];

function addUser(user: User): void {
    users.push(user);
}

function getUserById(id: string): User | undefined {
    return users.find((user) => user.id === id);
}

function getUserByMemberNumber(memberNumber: string): Promise<User | undefined> {
    return new Promise((resolve, reject) => {
        const user = users.find((user) => user.memberNumber === memberNumber);
        if (user) {
            return resolve(user);
        }
        return reject();
    });
}

function getUserByUsername(username: string): User | undefined {
    return users.find((user) => user.memberName === username);
}

function getUsers() {
    return users;
}

export const UserService = {
    addUser,
    getUserById,
    getUserByMemberNumber,
    getUserByUsername,
    getUsers
};
