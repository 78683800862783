import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useToast } from '@/hooks/useToast';

import { CatalogBox } from '@/components/InfoBar/CatalogBox';
import { MoneyBox } from '@/components/InfoBar/MoneyBox';

import { useShoppingCart } from '@/../context/shoppingCartContext';

import { useCurrentUser } from '../../../context/currentUserContext';

type InfoBarProps = {
    scrolledSm?: boolean;
};

export const InfoBar = ({ scrolledSm }: InfoBarProps) => {
    const intl = useIntl();
    const { currentBasket } = useShoppingCart();
    const { errorToast } = useToast();
    const [loading, setLoading] = useState(true);
    const { currentUser, moneySummary, fetchUser, updateMoneySummary } = useCurrentUser();

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    const fetchMoneySummary = useCallback(async () => {
        try {
            setLoading(() => true);
            await updateMoneySummary();
        } catch {
            errorToast({
                description: intl.formatMessage({ id: 'my-money.fetch-money.description' })
            });
        }
    }, [updateMoneySummary, errorToast, intl]);

    useEffect(() => {
        if (currentUser) {
            updateMoneySummary();
        }
    }, [currentUser, fetchMoneySummary, updateMoneySummary]);

    useEffect(() => {
        if (loading && moneySummary) {
            setLoading(() => false);
        }
    }, [loading, moneySummary]);

    return (
        <Flex
            justifyContent={{
                sm: 'center'
            }}
        >
            <Flex
                flexDirection={['column', null, 'row']}
                alignItems="flex-start"
                justifyContent="center"
                columnGap={8}
                px={4}
                py={scrolledSm ? 2 : 4}
                bg="grey.100"
                color="grey.main"
                borderRadius={28}
                fontSize={14}
                flexGrow={{
                    base: 1,
                    sm: 0
                }}
                position="relative"
            >
                {loading && (
                    <Box
                        position="absolute"
                        inset={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="rgba(255, 255, 255, .75)"
                    >
                        <Spinner />
                    </Box>
                )}
                {currentUser && !currentUser?.company && (
                    <MoneyBox
                        money={moneySummary?.myMoney}
                        currencySymbol={currentBasket?.summary.currency}
                        label={intl.formatMessage({ id: 'my-money' })}
                    />
                )}
                <MoneyBox
                    money={moneySummary?.refundMoney}
                    currencySymbol={currentBasket?.summary?.currency}
                    label={intl.formatMessage({ id: 'my-money-from-complaints' })}
                />
                <CatalogBox endDate={moneySummary?.catalogEndDate} />
            </Flex>
        </Flex>
    );
};
