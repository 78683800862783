import { Box, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';
import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import ProductQuantityHandlerUnlogged from '@/components/ShoppingCart/ProductQuantityHandlerUnlogged';
import RemoveItemUnlogged from '@/components/ShoppingCart/RemoveItemUnlogged';
import NavLink from '@/components/UI/Links/NavLink';

import { Product } from '@/models/api/Product';
import { ItemsPropsUnlogged } from '@/models/props/ProductCardProps';
import { AppService } from '@/services';
import { ProductUtil } from '@/utils';

type ProductItemUnloggedProps = {
    product: Product;
    basket: ItemsPropsUnlogged[];
};

export function ProductItemUnlogged(props: ProductItemUnloggedProps) {
    const { product, basket } = props;
    const currency = AppService.getInstanceCurrency();
    const intl = useIntl();
    const { cartItemsUnlogged, fetchingData, editable } = useShoppingCart();
    const [basketItem, setBasketItem] = useState<ItemsPropsUnlogged>();
    const [currQuantity, setCurrQuantity] = useState<number>();
    const productUrl = ProductUtil.getProductUrl(product);
    const [price, setPrice] = useState<string>();
    const obj = useMemo(
        () => ({ product, intl, cartItemsUnlogged }),
        [product, intl, cartItemsUnlogged]
    );
    const funcPullQuantity = (q: number) => {
        setCurrQuantity(q);
    };

    useEffect(() => {
        if (basketItem) {
            setPrice((Math.round(basketItem?.grossPrice * 100) / 100).toFixed(2));
        }
    }, [basketItem]);

    useEffect(() => {
        const productItem = obj.cartItemsUnlogged?.find((i) => i.code === product.code);

        if (productItem) {
            setCurrQuantity(productItem.quantity);
            setBasketItem(productItem);
        }
    }, [basket, product, obj]);

    return (
        <>
            {product && (
                <Flex gap={3}>
                    <Box
                        position="relative"
                        borderRadius="base"
                        h={{ base: '92px', md: '97px' }}
                        w={32}
                    >
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            position="relative"
                            h="100%"
                            w="100%"
                        >
                            <ProductImage mainImage={product.mainImage} title={product.title} />
                        </Flex>
                    </Box>
                    <Box width="100%">
                        <Flex alignItems="center" pb={1} fontSize="sm" color="grey.800">
                            <Text>
                                <FormattedMessage id="product-code" />: {product.code}
                            </Text>
                            <Box ml="auto">
                                <RemoveItemUnlogged code={product.code} isDisabled={!editable} />
                            </Box>
                        </Flex>
                        <NavLink href={productUrl}>
                            <H3 size="h5" fontWeight="normal" mb={0}>
                                {obj.product.title}
                            </H3>
                        </NavLink>
                        <Box pt={3}>
                            {currQuantity !== undefined && (
                                <Flex
                                    direction="column"
                                    justifyContent="start"
                                    alignItems="start"
                                    columnGap={4}
                                    w="100%"
                                >
                                    <Flex alignItems="center">
                                        <Box
                                            width="min-content"
                                            minW="140px"
                                            flexGrow={0}
                                            flexShrink={0}
                                        >
                                            <ProductQuantityHandlerUnlogged
                                                setQuantity={funcPullQuantity}
                                                code={product.code}
                                                grossPrice={product.grossPrice}
                                                quantity={currQuantity}
                                                maxOrder={30}
                                                variant="sm"
                                                fetchingData={fetchingData}
                                            />
                                        </Box>
                                        {product.grossPrice && (
                                            <Text as="span" ml="2">
                                                &times;{' '}
                                                <FormattedPrice
                                                    value={product.grossPrice}
                                                    currency={currency}
                                                />
                                            </Text>
                                        )}
                                    </Flex>
                                    <Flex
                                        fontWeight="bold"
                                        whiteSpace="nowrap"
                                        ml={0}
                                        fontSize={{ base: 'xl', md: '27px' }}
                                    >
                                        <Text>
                                            <FormattedPrice value={price} currency={currency} />
                                        </Text>
                                    </Flex>
                                </Flex>
                            )}
                        </Box>
                    </Box>
                </Flex>
            )}
        </>
    );
}
