import axios from 'axios';

import { LocaleProps } from '@/models/props/LocalizationProps';
import { AppService } from '@/services/AppService';
import { EnvironmentService } from '@/services/EnvironmentService';

const BANNERS_URL = EnvironmentService.getCmsInternalApiUrl() + '/banners';

async function getBannersLogged(
    locale: LocaleProps,
    logged: 'showToNonLoggedInUser' | 'showToLoggedInUser'
) {
    const url = new URL(BANNERS_URL);
    const urlSearchParams = url.searchParams;

    urlSearchParams.set('locale', locale);
    urlSearchParams.set('filters[region][$containsi]', AppService.getInstanceRegion());
    urlSearchParams.set(`filters[${logged}][$eq]`, 'true');
    urlSearchParams.set('sort[0]', 'orderNumber');
    urlSearchParams.set('pagination[pageSize]', '4');
    urlSearchParams.append('populate', 'image');

    const { data } = await axios.get(url.toString()).catch((error) => {
        throw new Error(error || 'BannerService: Something went wrong!');
    });

    return data?.data;
}

export const BannerService = {
    getBannersLogged
};
