import { IconProps } from '@chakra-ui/icons';
import { Icon } from '@chakra-ui/react';

type AdditionalProps = {
    stroke?: string;
};

export const EditIcon = (props: IconProps & AdditionalProps) => {
    return (
        <Icon viewBox="0 0 13 13" {...props}>
            <path
                d="m3.067,12.328l-3.067,0l0,-3.067l7.425,-7.425l0,0l1.505,-1.5a1.127,1.127 0 0 1 1.594,0l1.476,1.468a1.129,1.129 0 0 1 0,1.594l-1.474,1.471l0,0l-7.459,7.459zm-2.067,-1l1.652,0l6.459,-6.459l-1.652,-1.653l-6.459,6.459l0,1.653zm7.839,-9.492l1.652,1.652l0.8,-0.8a0.126,0.126 0 0 0 0,-0.18l-1.474,-1.47a0.127,0.127 0 0 0 -0.18,0l-0.798,0.798z"
                fill="#0085ca"
            />
        </Icon>
    );
};
