import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    Text,
    useDisclosure,
    useMediaQuery
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CookieIcon } from '@/components/Icons';
import NavLink from '@/components/UI/Links/NavLink';

import { useCookieConsent } from '@/../context/cookieConsentContext';
import breakpoints from '@/../themes/betterstyle-theme/breakpoints';
import RoutePath from '@/constants/route-path';
import { CookieConsentService } from '@/services/CookieConsentService';

export const CookieConsentPopup = () => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.xl})`, {
        ssr: true,
        fallback: true
    });
    const {
        isCookieConsentSettingsPanelOpened,
        openCookieConsentSettingsPanel,
        setAllCookieConsent,
        setEssentialCookieConsent
    } = useCookieConsent();
    const cookieAccepted = CookieConsentService.getUserCookieConsent();
    const obj = useMemo(
        () => ({
            isOpen,
            onClose,
            onOpen,
            cookieAccepted,
            openCookieConsentSettingsPanel,
            setAllCookieConsent,
            setEssentialCookieConsent
        }),
        [
            isOpen,
            onClose,
            onOpen,
            cookieAccepted,
            openCookieConsentSettingsPanel,
            setAllCookieConsent,
            setEssentialCookieConsent
        ]
    );
    const displayModal = useCallback(() => {
        if (!obj.cookieAccepted) {
            obj.onOpen();
            return;
        }

        window.dataLayer?.push({
            event: 'ConsentUpdated',
            consents: obj.cookieAccepted
        });
    }, [obj]);

    useEffect(() => {
        displayModal();
    }, [displayModal]);

    const cookiePopupCloseHandler = useCallback(() => {
        obj.onClose();
    }, [obj]);

    useEffect(() => {
        if (obj.cookieAccepted) {
            return;
        }
        isCookieConsentSettingsPanelOpened ? obj.onClose() : obj.onOpen();
    }, [isCookieConsentSettingsPanelOpened, obj]);

    const acceptAllCookiesClickHandler = useCallback(() => {
        obj.onClose();
        obj.setAllCookieConsent();
    }, [obj]);

    const acceptEssentialCookiesClickHandler = useCallback(() => {
        obj.onClose();
        obj.setEssentialCookieConsent();
    }, [obj]);

    const openCookieSettingsPanelClickHandler = useCallback(() => {
        obj.openCookieConsentSettingsPanel();
        obj.onClose();
    }, [obj]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={cookiePopupCloseHandler}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            blockScrollOnMount={false}
            size="5xl"
            trapFocus={false}
        >
            <ModalContent
                mx={4}
                mb={{ base: 0, md: 4 }}
                containerProps={{
                    alignItems: 'flex-end',
                    bottom: 0,
                    top: 'auto',
                    height: 'auto',
                    w: '100%'
                }}
                bgColor="grey.100"
                borderRadius="29px"
            >
                <ModalBody
                    py={{ base: 4, sm: 10 }}
                    pl={{ base: 6, sm: 20 }}
                    pr={{ base: 6, sm: 12 }}
                >
                    <Box position="relative">
                        {isDesktop && (
                            <CookieIcon position="absolute" left={-43} top={1.5} fontSize={28} />
                        )}
                        <Text fontSize={{ base: 'lg', sm: 'h3' }} fontWeight="semibold" mb={3}>
                            <FormattedMessage id="cookies.title-text" />
                        </Text>
                        <Text fontSize={{ base: 'sm', lg: 'initial' }} whiteSpace="pre-line">
                            <FormattedMessage
                                id="cookies.content-text"
                                values={{
                                    'data-privacy-policy-link': (content) => {
                                        return (
                                            <NavLink
                                                href={RoutePath.PrivacyPolicy}
                                                isExternal
                                                variant="boldedBlue"
                                            >
                                                {content}
                                            </NavLink>
                                        );
                                    },
                                    'data-club-regulations-link': (content) => {
                                        return (
                                            <NavLink
                                                href={RoutePath.ClubRegulations}
                                                isExternal
                                                variant="boldedBlue"
                                            >
                                                {content}
                                            </NavLink>
                                        );
                                    }
                                }}
                            />
                        </Text>
                        <ButtonGroup
                            display="flex"
                            flexDirection={{
                                base: 'column-reverse',
                                lg: 'row'
                            }}
                            flexWrap="wrap"
                            spacing={0}
                            justifyContent="center"
                            mt={{ base: 4, sm: 6 }}
                            gap={{ base: 1, sm: 2.5 }}
                        >
                            <Flex
                                gap={2}
                                columnGap={{
                                    sm: 6
                                }}
                                flexDirection={{
                                    base: 'column',
                                    lg: 'row'
                                }}
                            >
                                <Button
                                    colorScheme="blue"
                                    variant="outline"
                                    borderRadius="26px"
                                    mx={0}
                                    onClick={openCookieSettingsPanelClickHandler}
                                >
                                    <FormattedMessage id="cookies.open-cookies-settings-panel" />
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    borderRadius="26px"
                                    mx={0}
                                    onClick={acceptEssentialCookiesClickHandler}
                                >
                                    <FormattedMessage id="cookies.accept-essential" />
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    borderRadius="26px"
                                    mx={0}
                                    ml={{ md: 4 }}
                                    onClick={acceptAllCookiesClickHandler}
                                    style={{
                                        marginInlineStart: 0
                                    }}
                                >
                                    <FormattedMessage id="cookies.accept-all" />
                                </Button>
                            </Flex>
                        </ButtonGroup>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
