import { DOMNode, Element } from 'html-react-parser';
import { ElementType } from 'react';

export class HtmlParserUtil {
    static domNodeIsElement(domNode: DOMNode): domNode is Element {
        return domNode.type === 'tag';
    }

    static isTagElement(domNode: DOMNode, name: ElementType): domNode is Element {
        return HtmlParserUtil.domNodeIsElement(domNode) && domNode.name === name;
    }
}
