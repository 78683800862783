import { useRouter } from 'next/router';

import { LogoIcon, LogoIconProps } from '@/components/Icons/LogoIcon';

import NavLink from './NavLink';

export const BrandLink = (props: LogoIconProps) => {
    const router = useRouter();
    const { locale } = router;
    const href = props.href || '/';

    return (
        <NavLink href={href} locale={locale} display="flex" justifyContent="center">
            <LogoIcon
                fontSize="10rem"
                h="auto"
                color="brand"
                pointerEvents="none"
                maxW="100%"
                {...props}
            />
        </NavLink>
    );
};
